import { FC, Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import PaginationProps from './PaginationProps';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Pagination: FC<PaginationProps> = ({ page = 0, setPage, count }) => {
  return (
    <Fragment>
      <div className="mt-8 mb-4 hidden gap-4 md:flex">
        <div className="ml-auto">
          <ReactPaginate
            breakLabel="..."
            onPageChange={(selectedItem) => setPage(selectedItem.selected + 1)}
            pageRangeDisplayed={2}
            pageCount={count}
            renderOnZeroPageCount={() => null}
            className="flex items-center gap-2"
            pageClassName="flex h-6 w-6 items-center  justify-center rounded-md font-gRegular text-xs"
            activeClassName="flex h-6 w-6 items-center  justify-center rounded-md font-gRegular text-xs bg-gradient text-white"
            previousLabel={
              <div className="bg-sitrep-gray-100 cursor-pointer rounded-full p-2">
                <MdChevronLeft />
              </div>
            }
            nextLabel={
              <div className="bg-sitrep-gray-100 cursor-pointer rounded-full p-2">
                <MdChevronRight />
              </div>
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Pagination;
