import { FC } from 'react';
import Heading from '../../../lib/Heading';
import ContainedLoader from '../../../lib/Loader/ContainedLoader';
import Text from '../../../lib/Text';
import FeedbackListCard from './FeedbackListCard';
import FeedbackListProps from './FeedbackListProps';

const FeedbackList: FC<FeedbackListProps> = ({
  feedback,
  setSelectedIndex,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <ContainedLoader />
      ) : feedback.length === 0 ? (
        <div className="flex min-h-[200px] w-full flex-col items-center justify-center gap-1 text-center">
          <Heading type="h3">Nothing to see here!</Heading>
          <Text>You have not received any feedback.</Text>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          {feedback.map((item, index) => (
            <FeedbackListCard
              key={index}
              {...item}
              variant={index % 2 === 0 ? 'dark' : 'light'}
              onClick={() => setSelectedIndex(index)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default FeedbackList;
