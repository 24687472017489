import { FormEvent, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import PaymentInput from '../../components/app/DonateForm/PaymentInput';
import PageTitle from '../../components/containers/PageTitle';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Button from '../../components/lib/Button';
import Heading from '../../components/lib/Heading';
import SimpleModal from '../../components/lib/Modal/SimpleModal';
import Text from '../../components/lib/Text';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Service } from '../../types/Service';
import { getRequest, postRequest } from '../../utils/api/calls';
import queryKeys from '../../utils/api/queryKeys';
import { CREATE_DONATION, GET_PAYMENT_SERVICES } from '../../utils/api/urls';
import { cardCurrencies, cryptoCurrencies } from '../../utils/constants';
import { isEmpty, validateDonationInputs } from '../../utils/validators';

const initialState = {
  name: '',
  email: '',
  phone_number: '',
  amount: '',
  currency: '',
};

const DonateForm = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [payload, setPayload] = useState({ ...initialState, currency: 'NGN' });
  const [errors, setErrors] = useState(initialState);
  const [services, setServices] = useState<Service[]>([]);
  const handler = useErrorHandler();
  const [open, setOpen] = useState(false);
  const [donationInit, setDonationInit] = useState<{
    id: string;
    reference: string;
    address: string;
    coin: string;
    cryptoAmount: number;
    currency: string;
    network: string;
  } | null>(null);

  const getCurrencies = () => {
    const paymentID =
      services.find((service) => service.id === Number(paymentMethod))?.name ||
      '';

    switch (paymentID) {
      case 'Paystack':
        return cardCurrencies;

      case 'LazerPay':
        return cryptoCurrencies;

      default:
        return [];
    }
  };

  useQuery(
    [queryKeys.getRequestReasons],
    () => getRequest({ url: GET_PAYMENT_SERVICES }),
    {
      onSuccess(response) {
        setServices(response?.data?.services || []);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess(response) {
      if (response?.data?.init?.authorization_url) {
        window.location.href = response?.data?.init?.authorization_url;
        return;
      }

      if (response?.data?.init?.address) {
        setDonationInit(response?.data?.init);
        setOpen(true);
        return;
      }
    },
    onError(error) {
      handler(error);
    },
  });

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } = validateDonationInputs(payload);

    if (valid) {
      mutate({
        url: CREATE_DONATION,
        data: {
          ...payload,
          amount: Number(payload.amount),
          phone_number: payload.phone_number.split('+')[1],
          redirect_url: `${
            window.location.href.split(location.pathname)[0]
          }/donate/complete`,
          service_id: String(paymentMethod),
        },
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <PageTitle title="Dontation">
      <div
        className={`max mx-auto flex flex-col gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] max-w-[300px] self-center text-center md:mb-10 md:w-auto`}
        >
          Donate to JDI
        </Heading>

        <div className="mx-auto w-full max-w-[900px] lg:w-[60vw]">
          <div className="mt-0 flex w-full flex-col gap-3 lg:gap-5">
            <Input
              label="Full Name"
              placeholder="Enter your full name"
              name="name"
              value={payload.name}
              error={!isEmpty(errors.name)}
              helperText={errors.name}
              onChange={handleChange}
            />

            <Input
              label="Email Address"
              placeholder="Enter your email address"
              name="email"
              type="email"
              value={payload.email}
              error={!isEmpty(errors.email)}
              helperText={errors.email}
              onChange={handleChange}
            />

            <Input
              label="Phone Number"
              helperLabel="(+234 format)"
              placeholder="Enter your phone number"
              name="phone_number"
              type="tel"
              value={payload.phone_number}
              error={!isEmpty(errors.phone_number)}
              helperText={errors.phone_number}
              onChange={handleChange}
            />

            <Select
              label="Choose Payment Method"
              placeholder="Select Method"
              value={paymentMethod}
              options={[
                { key: 'Select Payment Method', value: '' },
                ...services.map((service) => ({
                  key: service.name === 'LazerPay' ? 'Crypto' : 'Cash',
                  value: service.id,
                })),
              ]}
              onChange={(event) => setPaymentMethod(event.currentTarget.value)}
            />

            {!isEmpty(paymentMethod) && (
              <>
                <PaymentInput
                  amountValue={payload.amount}
                  currencyValue={payload.currency}
                  currencies={getCurrencies()}
                  onAmountChange={handleChange}
                  onCurrencyChange={handleChange}
                  error={!isEmpty(errors.currency) || !isEmpty(errors.amount)}
                  helperText={
                    !isEmpty(errors.amount) ? errors.amount : errors.currency
                  }
                />

                <Button
                  size="medium"
                  variant="solid"
                  className="mx-auto mt-10 w-full max-w-[500px]"
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  Proceed
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <SimpleModal
        open={open}
        title="Pay with crypto"
        onClose={() => setOpen(false)}
        className="max-w-[600px] !py-20"
      >
        <Text>
          Kindly transfer{' '}
          <span className="font-soraBold">{`${donationInit?.cryptoAmount}${donationInit?.coin}`}</span>{' '}
          to the following address:
        </Text>
        {donationInit?.network && <Text>Network: {donationInit?.network}</Text>}
        <Heading type="h3" className="break mt-5 break-words">
          {donationInit?.address}
        </Heading>
      </SimpleModal>
    </PageTitle>
  );
};

export default DonateForm;
