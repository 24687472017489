import { useContext } from 'react';
import { IoNotifications } from 'react-icons/io5';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
import Text from '../../../components/lib/Text';
import { NotificationContext } from '../../../contexts/notification';
import useMediaQuery from '../../../hooks/useMediaQuery';

const Notifications = () => {
  const { notifications, removeNotification, clearAllNotifications } =
    useContext(NotificationContext);
  const smallScreen = useMediaQuery('(max-width: 1204px)');

  return (
    <PageTitle
      title="Notifications"
      leftComponent={
        notifications.length > 0 ? (
          <button onClick={clearAllNotifications}>
            <Text
              className="text-xs underline hover:text-primary-main"
              type="caption"
            >
              Read all
            </Text>
          </button>
        ) : (
          <div />
        )
      }
    >
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card
          title="Notifications"
          mobile={smallScreen}
          titleComponent={
            notifications.length > 0 ? (
              <button onClick={clearAllNotifications}>
                <Text
                  className="text-xs underline hover:text-primary-main"
                  type="caption"
                >
                  Mark all as read
                </Text>
              </button>
            ) : (
              <></>
            )
          }
        >
          <div className="flex w-full flex-col gap-5">
            {notifications.length === 0 ? (
              <div className="flex min-h-[100px] items-center justify-center">
                <Text type="caption" className="font-soraSemibold">
                  You do not have any notification
                </Text>
              </div>
            ) : (
              notifications.map((notification, index) => (
                <div
                  className="flex w-full items-start justify-between gap-5 border-b py-3"
                  key={index}
                >
                  <IoNotifications className="text-2xl text-primary-main" />

                  <div className="flex flex-1 flex-col">
                    <Text className="!font-soraSemibold" type="caption">
                      {notification.title}
                    </Text>
                    <Text type="caption">{notification.body}</Text>
                  </div>

                  <button onClick={() => removeNotification(notification.id)}>
                    <Text
                      className="text-xs underline hover:text-primary-main"
                      type="caption"
                    >
                      Read
                    </Text>
                  </button>
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
    </PageTitle>
  );
};

export default Notifications;
