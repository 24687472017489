// import FeaturedRecipient from '../../assets/images/FeaturedRecipient.jpg';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import PageTitle from '../../components/containers/PageTitle';
import useMediaQuery from '../../hooks/useMediaQuery';

const RecipientStory = () => {
  const smallScreen = useMediaQuery('(max-width: 1023px)');

  return (
    <PageTitle title="Recipient story">
      <div className="max mx-auto flex flex-col gap-5 p-5 py-10 md:p-10 lg:gap-10 lg:px-20 lg:py-16">
        <Heading
          underlined
          className="w-[60vw] self-center text-center md:mb-10 md:w-auto lg:max-w-[60vw]"
        >
          {smallScreen ? 'David Ochoga, JBM Donor' : 'Featured recipient story'}
        </Heading>

        {/* <div className="flex w-full flex-col items-center justify-between gap-5 md:mb-10 lg:flex-row lg:gap-10">
          <figure className="aspect-[10/8] max-w-[500px] overflow-hidden md:aspect-[10/6] lg:w-[40vw]">
            <img
              src={FeaturedRecipient}
              alt=""
              className="h-full w-full object-cover"
            />
          </figure>

          <div className="flex flex-1 flex-col gap-5 text-center md:text-left lg:w-[40vw]">
            <Heading type="h2" className={smallScreen ? 'hidden' : ''}>
              David Ochoga, JBM Donor
            </Heading>
            <Text
              type="subheading"
              className="border-b border-b-[#8B888780] pb-5 text-left text-jblood-gray lg:border-none lg:p-0"
            >
              “They didn’t just give us units of blood, they gave us a life. We
              owe them everything that we have and everything that we are,
              because their donation is priceless. They saved a wife, a mom, a
              daughter, a sister, a friend, and a pediatric nurse.”
            </Text>
          </div>
        </div> */}

        <Text>
          At about 10:14 pm on Friday the 4th of December 2020, I was notified
          by the J Blood Match Bot that my assistance was required by Mr. Paul
          at Garki Hospital Abuja, for voluntary blood donation. The reason?
          Sickle cell disorder. I accepted the request with excitement.
        </Text>
        <Text>
          The next day at 11:02 am, I was at the hospital and Mr. Paul was
          waiting for me outside. He led me to the bleeding room where a sample
          of my blood was taken to be tested. I asked Mr. Paul if he was the one
          in need of my blood, but he told me that it was actually for his
          11-year-old son who is a sickle cell warrior. He took me to the
          pediatric ward and I met David, his son who was recently diagnosed
          with gall stones that clogged his bladder, causing him serious pain,
          and was scheduled for surgery quickly.
        </Text>
        <Text>
          With excitement, I went to the laboratory for my blood to be drawn,
          but I was told to eat something before going through the process. Mr.
          Paul took me to the hospital canteen and bought me food. We returned
          to the laboratory and a pint of my blood was drawn.
        </Text>
        <Text>
          Mr. Paul and I went back to the ward to see his son who sat down
          watching cartoons. I was shocked to see a child looking so healthy to
          go through such enormous pain. I spoke a little with David and
          encouraged him not to worry.
        </Text>
        <Text>
          I was ready to leave in a cab, but Mr. Paul insisted he would drop me
          at my house. He said it was the little he could do to thank me.
        </Text>
        <Text>
          While in the car, I asked him how he heard of J Blood Match and he
          told me that he was invited by a friend to attend the grand launch at
          Transcorp Hilton Hotel, and he spoke about how promising the bot is.
        </Text>
        <Text>
          After donating I feel so fulfilled. I encourage us all to donate blood
          if we can, because freely we were given the blood, freely we should
          give.
        </Text>

        <Heading type="h3" className="self-end">
          David Ochoga, JBM Donor
        </Heading>
      </div>
    </PageTitle>
  );
};

export default RecipientStory;
