import Card from '../../../../lib/Card';
import Text from '../../../../lib/Text';
import { FC } from 'react';
import AchievementCardProps from './AchievementProps';

const AchievementCard: FC<AchievementCardProps> = ({
  title,
  titleComponent,
  subtitle,
  caption,
  icon,
  className,
  incomplete,
  small = false,
}) => {
  return (
    <Card
      title={title}
      className={`relative mb-10 overflow-hidden md:mb-5 ${
        className ? className : ''
      }`}
      titleComponent={titleComponent}
      small={small}
    >
      <div className="mt-10 flex flex-col items-center justify-center gap-10 text-center">
        <Text className="!font-interSemibold" type="caption">
          {subtitle}
        </Text>

        <figure
          className={`flex items-center justify-center ${
            small ? 'h-24 w-24' : 'h-32 w-32'
          } `}
        >
          <img src={icon} alt="Medal" className="h-full w-full object-cover" />
        </figure>

        <Text className="max-w-[32ch] text-jblood-gray" type="caption">
          {caption}
        </Text>
      </div>

      {incomplete && (
        <div className="absolute top-0 left-0 h-full w-full bg-[#F2F1F6B2]" />
      )}
    </Card>
  );
};

export default AchievementCard;
