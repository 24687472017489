import { FC, PropsWithChildren } from 'react';
import TextAreaProps from './TextAreaProps';

const TextArea: FC<PropsWithChildren<TextAreaProps>> = ({
  label,
  error,
  helperText,
  helperLabel,
  ...rest
}) => {
  return (
    <div>
      <div className="">
        {label && (
          <label className="font-interSemibold text-xs md:text-sm">
            {label}{' '}
            <span className="font-interRegular text-xs text-jblood-gray">
              {helperLabel}
            </span>
          </label>
        )}
        <div className="relative flex w-full items-center">
          <textarea
            className={`min-w-80 mt-1 flex-1 resize-none border border-jblood-gray px-5 py-4 font-interRegular text-sm focus:border-primary-main focus:text-gray-900 focus:outline-none lg:mt-2 lg:px-10 ${
              error ? 'border !border-primary-dark' : ''
            }`}
            rows={6}
            {...rest}
          />
        </div>
      </div>

      {helperText && (
        <span
          className={`text-xs ${
            error ? 'text-primary-dark' : 'text-jblood-black'
          }`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default TextArea;
