import { FormEvent, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import toaster from 'react-hot-toast';
import PageTitle from '../../../components/containers/PageTitle';
import Input from '../../../components/form/Input';
import TextArea from '../../../components/form/TextArea';
import Button from '../../../components/lib/Button';
import Card from '../../../components/lib/Card';
import Text from '../../../components/lib/Text';
import { RoleContext } from '../../../contexts/role';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { postRequest } from '../../../utils/api/calls';
import { isEmpty, validateReportInputs } from '../../../utils/validators';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { REPORT_ABUSE } from '../../../utils/api/urls';

const initialState = {
  name: '',
  description: '',
};

const ReportAbuse = () => {
  const { role } = useContext(RoleContext);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const handler = useErrorHandler();

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      toaster.success('Report submitted successfully.');
      setPayload(initialState);
    },
    onError(error) {
      handler(error);
    },
  });

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } = validateReportInputs(payload);

    if (valid) {
      mutate({
        url: REPORT_ABUSE,
        data: payload,
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <PageTitle title="Report Abuse">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Report Abuse" mobile={smallScreen}>
          <div className="flex w-full flex-col gap-5 lg:w-[600px]">
            <Text className="!font-interLight !leading-loose">
              In order to maintain the integrity of J Blood Match and to keep
              delivering this service, we need you to tell us if anything goes
              wrong. if you see something that should not be, tell us! We assure
              you that we will take prompt action.
            </Text>

            <Input
              label={role === 'donor' ? "Recipient's name" : "Donor's name"}
              placeholder={
                role === 'donor'
                  ? "Enter Recipient's name"
                  : "Enter Donor's name"
              }
              name="name"
              value={payload.name}
              onChange={handleChange}
              error={!isEmpty(errors.name)}
              helperText={errors.name}
            />

            <TextArea
              label="What do you want to report?"
              placeholder="Enter description"
              name="description"
              value={payload.description}
              onChange={handleChange}
              error={!isEmpty(errors.description)}
              helperText={errors.description}
            />

            <Button
              size="medium"
              className="mx-auto my-10"
              onClick={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
            >
              Send
            </Button>
          </div>
        </Card>
      </div>
    </PageTitle>
  );
};

export default ReportAbuse;
