import { useState } from 'react';
import Pages from '../../components/access/Welcome/Pages';
import FormContainer from '../../components/containers/FormContainer';
import Text from '../../components/lib/Text';

const Welcome = () => {
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  return (
    <FormContainer title="Welcome to J Blood Match!">
      <div className="flex w-full max-w-[950px] flex-col items-center gap-3 lg:gap-5">
        <Text className="mb-5 text-center font-interBold lg:mb-10">
          Important things to note before you donate blood.
        </Text>

        <Pages {...{ activeStep, handleNext }} />
      </div>
    </FormContainer>
  );
};

export default Welcome;
