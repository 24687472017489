export const numbers = [
  {
    title: 'Registered donors',
    number: '952',
  },
  {
    title: 'Donation Requests',
    number: '224',
  },
  {
    title: 'Successful matches',
    number: '107',
  },
  {
    title: 'JBM Advocates',
    number: '21',
  },
];
