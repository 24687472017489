import { FC, PropsWithChildren, useState } from 'react';
import Modal from '.';
import Heading from '../Heading';
import Text from '../Text';
import { InfoModaProps } from './ModalProps';
import InfoIcon from '../../../assets/images/Info.png';

const InfoModal: FC<PropsWithChildren<InfoModaProps>> = ({
  open,
  onClose,
  children,
  title,
  icon,
}) => {
  const [name] = useState('Gloria Oluwatobi');

  return (
    <Modal
      {...{ open, onClose }}
      className="relative flex w-[90vw] max-w-[600px] flex-col items-center gap-3 rounded-lg bg-[#FCF9F8] lg:p-20"
    >
      <figure className="absolute left-[50%] -top-[7%] hidden h-20 w-20 -translate-x-[50%] overflow-hidden rounded-full border-8 border-white lg:block">
        <img
          src={icon || InfoIcon}
          alt="icon"
          className="h-full w-full object-cover"
        />
      </figure>

      <Heading type="h3" className="hidden capitalize lg:block">
        {name}
      </Heading>

      {title && (
        <Text type="caption" className="hidden text-jblood-gray lg:block">
          {title}
        </Text>
      )}

      <div className="mt-5 w-full rounded-lg bg-white py-5 px-10 shadow-primary-light/50 lg:px-10 lg:shadow">
        {children}
      </div>
    </Modal>
  );
};

export default InfoModal;
