import { FC } from 'react';
import ChipProps from './ChipProps';

const Chip: FC<ChipProps> = ({ className, label }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full border border-jblood-gray bg-jblood-gray/10 px-2 py-1 font-interSemibold capitalize text-jblood-gray lg:px-3  ${
        className ? className : ''
      }`}
    >
      <span className="text-xxs lg:text-xs">{label}</span>
    </div>
  );
};

export default Chip;
