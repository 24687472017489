import { FC, PropsWithChildren } from 'react';
import SelectProps from './SelectProps';

const Select: FC<PropsWithChildren<SelectProps>> = ({
  startIcon,
  endIcon,
  label,
  error,
  type,
  helperText,
  options,
  className,
  ...rest
}) => {
  return (
    <div>
      <div className="">
        {label && (
          <label className="font-interSemibold text-xs md:text-sm">
            {label}
          </label>
        )}
        <div className="relative flex w-full items-center">
          {startIcon && startIcon}
          <select
            className={`min-w-80 mt-1 h-[54px] min-w-full flex-1 border border-jblood-gray bg-transparent px-5 py-4 font-interRegular text-sm focus:border-primary-main focus:text-gray-900 focus:outline-none lg:mt-2 lg:px-10 ${
              error ? 'border !border-red-500' : ''
            } ${className ? className : ''}`}
            {...rest}
          >
            {options &&
              options.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.key}
                </option>
              ))}
          </select>
          {endIcon && endIcon}
        </div>
      </div>

      {helperText && (
        <span
          className={`text-xs ${error ? 'text-red-500' : 'text-jblood-black'}`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default Select;
