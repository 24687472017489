import { RiArrowRightLine } from 'react-icons/ri';
import PartnerImage1 from '../../../assets/images/PartnerImage1.jpg';
import PartnerImage2 from '../../../assets/images/PartnerImage2.jpg';
import FloatingCircleGradient from '../../../assets/icons/FloatingCircleGradient.svg';
import Button from '../../lib/Button';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import { Link } from 'react-router-dom';

const Partner = () => {
  return (
    <section className="relative bg-secondary-light px-5 py-12 md:px-10 lg:py-20 lg:px-10">
      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute -top-[5%] right-[10%] h-12 w-12 md:-top-[5%] md:right-[5%]"
      />
      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[2%] right-[25%] h-4 w-4 md:top-[5%] md:right-[10%]"
      />

      <div className="max z-20 mx-auto flex w-full flex-col items-center justify-between gap-10 lg:flex-row">
        <div className="flex flex-col gap-5 text-center md:gap-7 md:text-left lg:w-[50vw] lg:max-w-[600px]">
          <Heading type="h2">Partner with us to assist donors</Heading>
          <Text type="body1">
            After blood donation, a donor will typically need some refreshments
            (iron-rich food, vegetables, liquids) to quicken their immediate
            recovery and enable them return to their daily activities. You can
            assist donors by providing them with free meals or meal coupons and
            discounts.
          </Text>

          <Link to="/partner">
            <Button variant="outline" size="medium">
              <div className="flex items-center justify-center gap-2">
                <span>Read More</span>
                <RiArrowRightLine size={20} />
              </div>
            </Button>
          </Link>
        </div>

        <figure className="relative flex aspect-square w-5/6 max-w-[450px] items-center justify-start xl:justify-end">
          <img
            src={PartnerImage1}
            alt=""
            className="-ml-5 h-5/6 w-5/6 object-cover lg:ml-0"
          />

          <img
            src={PartnerImage2}
            alt=""
            className="absolute top-[50%] left-[60%] h-4/6 w-4/6 -translate-x-[25%] -translate-y-[50%] object-cover lg:left-[50%] xl:left-0"
          />
        </figure>
      </div>
    </section>
  );
};

export default Partner;
