import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { NavLinkProps } from './NavbarProps';

const NavLink: FC<PropsWithChildren<NavLinkProps>> = ({
  url,
  onClose,
  children,
}) => {
  return (
    <Link to={url}>
      <div
        className="cursor-pointer font-interSemibold text-sm hover:text-primary-dark"
        onClick={() => {
          onClose && onClose();
        }}
      >
        {children}
      </div>
    </Link>
  );
};

export default NavLink;
