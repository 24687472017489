/* eslint-disable no-useless-escape */
export const isEmpty = (value?: string | number) =>
  typeof value === 'undefined' || String(value).trim() === '';

export const isEmail = (string: string) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (string.match(regex)) {
    return true;
  } else {
    return false;
  }
};

const isPhoneNumber = (value: string) => {
  return /^\d{13,}$/.test(value.replace(/[\s()+\-\.]|ext/gi, ''));
};

export const notificationIsSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

export const validateLoginInputs = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  let valid = true;
  const errors = {
    email: '',
    password: '',
  };

  if (isEmpty(email)) errors.email = 'Email cannot be empty';
  else if (!isEmail(email)) errors.email = 'Invalid email address';

  if (isEmpty(password)) errors.password = 'Password cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateRegisterInputs = ({
  first_name,
  last_name,
  email,
  phone_number,
  password,
}: {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
}) => {
  let valid = true;
  const errors = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
  };

  if (isEmpty(first_name)) errors.first_name = 'First Name cannot be empty';
  if (isEmpty(last_name)) errors.last_name = 'Last Name cannot be empty';
  if (isEmpty(phone_number))
    errors.phone_number = 'Phone number cannot be empty';
  else if (!isPhoneNumber(phone_number))
    errors.phone_number = 'Invalid phone number, use +234xxxxxxxxxx format';

  if (isEmpty(email)) errors.email = 'Email cannot be empty';
  else if (!isEmail(email)) errors.email = 'Invalid email address';

  if (isEmpty(password)) errors.password = 'Password cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateForm2Inputs = ({
  gender,
  dob,
  blood_group,
}: {
  gender: string;
  dob: string;
  blood_group: string;
}) => {
  let valid = true;
  const errors = {
    gender: '',
    dob: '',
    blood_group: '',
  };

  if (isEmpty(gender)) errors.gender = 'Gender cannot be empty';
  if (isEmpty(dob)) errors.dob = 'Date of birth cannot be empty';
  if (isEmpty(blood_group)) errors.blood_group = 'Blood group cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateForm3Inputs = ({
  state_id,
  longitude,
  latitude,
}: {
  state_id: string;
  longitude: string | null;
  latitude: string | null;
}) => {
  let valid = true;
  const errors = {
    state_id: '',
    longitude: '',
    latitude: '',
  };

  if (isEmpty(state_id)) errors.state_id = 'State cannot be empty';
  if (!longitude) errors.longitude = 'Longitude cannot be empty';
  if (!latitude) errors.latitude = 'Latitude cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateForm4Inputs = ({
  month,
  year,
}: {
  month: string;
  year: string;
}) => {
  let valid = true;
  const errors = {
    month: '',
    year: '',
  };

  if (isEmpty(month)) errors.month = 'Month cannot be empty';
  if (isEmpty(year)) errors.year = 'Year cannot be empty';
  else if (year.length < 4) errors.year = 'Enter a valid year';
  else if (Number(year) < Number(new Date().getFullYear()) - 100)
    errors.year = `Year must be greater than ${
      Number(new Date().getFullYear()) - 100
    }`;
  else if (Number(year) > Number(new Date().getFullYear()))
    errors.year = `Year cannot be greater than ${Number(
      new Date().getFullYear()
    )}`;

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateRequestDonorForm1Inputs = ({
  gender,
  blood_group,
  first_name,
  last_name,
}: {
  gender: string;
  blood_group: string;
  first_name: string;
  last_name: string;
}) => {
  let valid = true;
  const errors = {
    gender: '',
    blood_group: '',
    first_name: '',
    last_name: '',
  };

  if (isEmpty(gender)) errors.gender = 'Gender cannot be empty';

  if (isEmpty(first_name))
    errors.first_name = 'Recipient first name cannot be empty';
  if (isEmpty(last_name))
    errors.last_name = 'Recipient last name cannot be empty';
  if (isEmpty(blood_group)) errors.blood_group = 'Blood group cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateRequestDonorForm2Inputs = (
  {
    reason_id,
    units,
    phone_number,
    date,
  }: {
    reason_id: string;
    units: string;
    phone_number: string;
    date: string;
  },
  recipient: string
) => {
  let valid = true;
  const errors = {
    reason_id: '',
    units: '',
    phone_number: '',
    date: '',
  };

  if (isEmpty(reason_id)) errors.reason_id = 'Reason cannot be empty';
  if (isEmpty(units)) errors.units = 'Units cannot be empty';
  else if (Number(units) > 4)
    errors.units = 'You can only request for a maximum of 4 units of blood';

  if (recipient !== 'self') {
    if (isEmpty(phone_number))
      errors.phone_number = 'Phone number cannot be empty';
    else if (!isPhoneNumber(phone_number))
      errors.phone_number = 'Invalid phone number; use +234XXXXXXXXXX format';
  }

  if (isEmpty(date)) errors.date = 'Date cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateRequestDonorForm3Inputs = ({
  hospital,
  longitude,
  latitude,
  physician,
  state_id,
}: {
  hospital: string;
  longitude: number | null;
  latitude: number | null;
  physician: string;
  state_id: string;
}) => {
  let valid = true;
  const errors = {
    hospital: '',
    longitude: '',
    latitude: '',
    physician: '',
    state_id: '',
  };

  if (isEmpty(hospital)) errors.hospital = 'Hospital Name cannot be empty';
  if (isEmpty(physician)) errors.physician = 'Physician  cannot be empty';
  if (isEmpty(state_id)) errors.state_id = 'State be empty';

  if (!latitude) errors.latitude = 'Latitude cannot be empty';
  if (!longitude) errors.longitude = 'Longitude cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateUpdateProfileInputs = ({
  gender,
  dob,
  blood_group,
  state_id,
}: {
  gender: string;
  dob: string;
  blood_group: string;
  state_id: string;
}) => {
  let valid = true;
  const errors = {
    gender: '',
    dob: '',
    blood_group: '',
    state_id: '',
  };

  if (isEmpty(gender)) errors.gender = 'Gender cannot be empty';
  if (isEmpty(dob)) errors.dob = 'Date of birth cannot be empty';
  if (isEmpty(blood_group)) errors.blood_group = 'Blood group cannot be empty';
  if (isEmpty(state_id)) errors.state_id = 'State cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateUpdateVitalsInput = ({
  height,
  weight,
  blood_pressure,
  heart_rate,
}: {
  height: string;
  weight: string;
  blood_pressure: string;
  heart_rate: string;
}) => {
  let valid = true;
  const errors = {
    height: '',
    weight: '',
    blood_pressure: '',
    heart_rate: '',
  };

  if (!isEmpty(height) && !(Number(height) > 0))
    errors.height = 'Height  must be greater than 0';
  if (!isEmpty(weight) && !(Number(weight) > 0))
    errors.weight = 'Weight must be greater than 0';
  if (!isEmpty(heart_rate) && !(Number(heart_rate) > 0))
    errors.heart_rate = 'Heart rate must be greater than 0';

  if (!isEmpty(blood_pressure)) {
    const regex = /^\d{1,3}\/\d{1,3}$/;

    if (!regex.test(blood_pressure)) {
      errors.blood_pressure = 'Must be in the "XXX/XXX" format';
    }
  }

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateReportInputs = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  let valid = true;
  const errors = {
    name: '',
    description: '',
  };

  if (isEmpty(name)) errors.name = 'name cannot be empty';

  if (isEmpty(description)) errors.description = 'Password cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateDonationInputs = ({
  name,
  email,
  phone_number,
  amount,
  currency,
}: {
  name: string;
  email: string;
  phone_number: string;
  amount: string;
  currency: string;
}) => {
  let valid = true;
  const errors = {
    name: '',
    email: '',
    phone_number: '',
    amount: '',
    currency: '',
  };

  if (isEmpty(name)) errors.name = 'Name cannot be empty';

  if (isEmpty(email)) errors.email = 'Email cannot be empty';
  else if (!isEmail(email)) errors.email = 'Invalid Email';

  if (isEmpty(phone_number))
    errors.phone_number = 'Phone number cannot be empty';
  else if (!isPhoneNumber(phone_number))
    errors.phone_number = 'Invalid phone number; use +234XXXXXXXXXX format';

  if (isEmpty(amount)) errors.amount = 'Amount cannot be empty';
  else if (Number(amount) <= 0) errors.amount = 'Amount must be greater than 0';

  if (isEmpty(currency)) errors.currency = 'Currency must not be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateAdvocateInputs = ({
  name,
  email,
  phone_number,
  state,
  age,
  passport,
  cv,
  education_level,
  bio,
  jbm_description,
  advocate_reason,
}: {
  name: string;
  email: string;
  phone_number: string;
  age: string;
  state: string;
  passport: File | null;
  cv: File | null;
  education_level: string;
  bio: string;
  jbm_description: string;
  advocate_reason: string;
}) => {
  let valid = true;
  const errors = {
    name: '',
    email: '',
    phone_number: '',
    state: '',
    age: '',
    education_level: '',
    bio: '',
    jbm_description: '',
    advocate_reason: '',
    passport: '',
    cv: '',
  };

  if (isEmpty(name)) errors.name = 'Name cannot be empty';

  if (isEmpty(email)) errors.email = 'Email cannot be empty';
  else if (!isEmail(email)) errors.email = 'Invalid Email';

  if (isEmpty(age)) errors.age = 'Age cannot be empty';
  else if (Number(age) < 18) errors.age = 'Must be 18 years or older';

  if (isEmpty(phone_number))
    errors.phone_number = 'Phone number cannot be empty';
  else if (!isPhoneNumber(phone_number))
    errors.phone_number = 'Invalid phone number; use +234XXXXXXXXXX format';

  if (isEmpty(state)) errors.state = 'State must not be empty';

  if (isEmpty(bio)) errors.bio = 'Bio must not be empty';
  if (isEmpty(jbm_description))
    errors.jbm_description = 'Description must not be empty';
  if (isEmpty(advocate_reason))
    errors.advocate_reason = 'Reason must not be empty';
  if (isEmpty(education_level))
    errors.education_level = 'Education level must not be empty';

  if (!passport) errors.passport = 'Please select an image';
  if (!cv) errors.cv = 'Please select a documet';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validatePartnershipInput = ({
  name,
  email,
  description,
}: {
  name: string;
  email: string;
  description: string;
  is_organization: boolean;
}) => {
  let valid = true;
  const errors = {
    name: '',
    email: '',
    description: '',
    is_organization: '',
  };

  if (isEmpty(name)) errors.name = 'Name cannot be empty';

  if (isEmpty(email)) errors.email = 'Email cannot be empty';
  else if (!isEmail(email)) errors.email = 'Invalid Email';

  if (isEmpty(description)) errors.description = 'Please select an option';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateForgotPaswordInputs = ({ email }: { email: string }) => {
  let valid = true;
  const errors = {
    email: '',
  };

  if (isEmpty(email)) errors.email = 'Email cannot be empty';
  else if (!isEmail(email)) errors.email = 'Invalid Email';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateResetPasswordInputs = ({
  password,
  confirmPassword,
}: {
  password: string;
  confirmPassword: string;
}) => {
  let valid = true;
  const errors = {
    password: '',
    confirmPassword: '',
  };

  if (isEmpty(password)) errors.password = 'Password cannot be empty';
  else if (confirmPassword !== password)
    errors.confirmPassword = 'Passwords must match';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};

export const validateShareTestimonialInputs = ({
  state,
  summary,
  aftermath,
  reason,
  lesson,
  type,
  conclusion,
}: {
  name: string;
  state: string;
  summary: string;
  challenges: string;
  aftermath: string;
  conclusion: string;
  reason: string;
  lesson: string;
  type: string;
}) => {
  let valid = true;
  const errors = {
    name: '',
    state: '',
    summary: '',
    challenges: '',
    aftermath: '',
    conclusion: '',
    reason: '',
    lesson: '',
    type: '',
  };

  if (isEmpty(state)) errors.state = 'Location cannot be empty';
  if (isEmpty(summary)) errors.summary = 'Cannot be empty';
  if (isEmpty(aftermath)) errors.aftermath = 'Cannot be empty';
  if (type === 'donor' && isEmpty(reason)) errors.reason = 'cannote be empty';
  if (type === 'donor' && isEmpty(lesson)) errors.lesson = 'Cannot be empty';
  if (type === 'recipient' && isEmpty(conclusion))
    errors.conclusion = 'Cannot be empty';

  for (const item of Object.values(errors)) {
    if (item.trim() !== '') {
      valid = false;
    }
  }

  return {
    valid,
    errors,
  };
};
