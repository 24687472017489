import { useState } from 'react';
import Heading from '../../../components/lib/Heading';
import Pages from '../../../components/auth/Recipient/FindDonorComplete/Pages';
import PageTitle from '../../../components/containers/PageTitle';
import useMediaQuery from '../../../hooks/useMediaQuery';

const FindDonorComplete = () => {
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  return (
    <PageTitle title="">
      {smallScreen ? (
        <Heading
          type="h1"
          underlined
          className="mx-auto max-w-[60vw] text-center"
        >
          Thank you for choosing us!
        </Heading>
      ) : (
        <Heading>Thank you for choosing J Blood Match!</Heading>
      )}
      <div className="flex w-full max-w-[950px] flex-col items-center gap-3 lg:gap-5">
        <Heading type="h3" className="mb-3 w-full text-center lg:text-left">
          Important things to note after you have been matched with a donor.
        </Heading>
        <Pages {...{ activeStep, handleNext }} />
      </div>
    </PageTitle>
  );
};

export default FindDonorComplete;
