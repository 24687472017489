import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import PageTitle from '../../components/containers/PageTitle';
import Heading from '../../components/lib/Heading';
import ContainedLoader from '../../components/lib/Loader/ContainedLoader';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Article as IArticle } from '../../types/Article';
import { getRequest } from '../../utils/api/calls';
import queryKeys from '../../utils/api/queryKeys';
import { GET_ARTICLE } from '../../utils/api/urls';
import DefaultBlogImage from '../../assets/images/DefaultBlogImage.png';

const Article = () => {
  const params = useParams();
  const [article, setArticle] = useState<IArticle | null>(null);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getArticle, params?.id],
    () => getRequest({ url: GET_ARTICLE(Number(params?.id as string)) }),
    {
      onSuccess(response) {
        setArticle(response?.data || null);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  return (
    <PageTitle title="Article">
      {isLoading ? (
        <ContainedLoader />
      ) : !article ? (
        <></>
      ) : (
        <div className="mx-auto mt-10 flex w-full max-w-[900px] flex-col items-center gap-10">
          <figure className={`aspect-video w-full overflow-hidden `}>
            <img
              src={article?.image || DefaultBlogImage}
              alt={article?.title}
              className="h-full w-full object-cover"
            />
          </figure>

          <Heading
            underlined
            className="w-[80%] max-w-[700px] text-center capitalize"
          >
            {article?.title}
          </Heading>

          <div className="main-blog flex w-full flex-col gap-5 p-5 md:p-10">
            {parse(article?.content)}
          </div>
        </div>
      )}
    </PageTitle>
  );
};

export default Article;
