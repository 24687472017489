export const faqs = [
  {
    title: 'How does J Blood Match work?',
    description:
      'J Blood Match is an artificial intelligence that connects voluntary blood donors to recipients using their blood type and location. Therefore, it is a community where a registered blood donor can request for another blood donor to come to the aid of a recipient in the hospital.\n It requires you to register/create a profile on the website, after which you will begin to receive requests, have the opportunity to accept them and donate blood at a registered hospital. ',
  },
  {
    title:
      'What are the requirements and registration process for J Blood Match?',
    description:
      'No, you do not need any special requirements to register/create a profile on J Blood Match. It is open to everyone to register as a blood donor.',
  },
  {
    title: 'Is J Blood Match for Abuja residents only?',
    description:
      'No. Jela’s Development Initiatives, the organization behind J Blood Match is currently headquartered in Abuja but this solution is live across all 36 states of Nigeria, and the FCT. Anyone with internet access can use J Blood Match in Nigeria.',
  },
  {
    title: 'Do I get paid for donating blood on the platform?',
    description:
      'No. JBM encourages non-remunerated blood donation, according to World Health Organization (WHO) standards because we believe that there is no price tag that can be placed on life. However, a blood donor may accept transportation reimbursement from the recipient if he/she so wishes or has a need for it.',
  },
  {
    title: 'How do I know if I can donate blood?',
    description:
      'J Blood Match’s role in the blood donation process is to connect the voluntary blood donor to the recipient. As such, you will have to go to the hospital first and undergo the procedural screening tests to confirm if you are medically fit for blood donation.',
  },
];
