import { FC } from 'react';
import Loader from '.';
import LoaderProps from './LoaderProps';

const ContainedLoader: FC<LoaderProps> = ({ color = '#333' }) => {
  return (
    <div className="flex min-h-[200px] w-full items-center justify-center">
      <Loader color={color} />
    </div>
  );
};

export default ContainedLoader;
