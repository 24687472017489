import { useState } from 'react';
import { useQuery } from 'react-query';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { Request } from '../../../../../types/Request';
import { getRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { GET_DONOR_REQUEST_HISTORY } from '../../../../../utils/api/urls';
import Card from '../../../../lib/Card';
import ContainedLoader from '../../../../lib/Loader/ContainedLoader';
import NoContent from '../../../../lib/NoContent';
import DonationHistoryCard from './DonationHistoryCard';

const DonationHistory = () => {
  const [history, setHistory] = useState<Request[]>([]);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getHistory],
    () => getRequest({ url: GET_DONOR_REQUEST_HISTORY('?limit=5') }),
    {
      onSuccess(response) {
        setHistory(response?.data?.donations || []);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  return (
    <Card title="Donation History" className="break-inside mb-10 md:mb-5">
      {isLoading ? (
        <ContainedLoader />
      ) : history.length === 0 ? (
        <NoContent />
      ) : (
        <div className="flex flex-col gap-5">
          {history.map((item, index) => (
            <DonationHistoryCard
              key={index}
              {...{
                ...item,
                variant: index % 2 === 0 ? 'light' : 'dark',
              }}
            />
          ))}
        </div>
      )}
    </Card>
  );
};

export default DonationHistory;
