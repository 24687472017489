import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

// Contexts
import { RoleContext } from '../contexts/role';

// Layouts
import Applayout from '../layouts/app/AppLayout';
import MainAuthLayout from '../layouts/auth/MainAuthLayout';

//// Screens
import About from '../screens/App/About';
import Articles from '../screens/App/Articles';
import BecomeAnAdvocate from '../screens/App/BecomeAnAdvocate';
import BecomeAnAdvocateForm from '../screens/App/BecomeAnAdvocateForm';
import BloodMatchChart from '../screens/App/BloodMatchChart';
import DonationProcess from '../screens/App/DonationProcess';
import FAQs from '../screens/App/FAQs';
import LandingPage from '../screens/App/LandingPage';
import Partner from '../screens/App/Partner';
import RecipientStory from '../screens/App/RecipientStory';
import TermsOfUse from '../screens/App/TermsOfUse';
import Donate from '../screens/App/Donate';
import PrivacyPolicy from '../screens/App/PrivacyPolicy';

// App Access Screens
import Login from '../screens/Access/Login';
import Signup from '../screens/Access/Signup';
import Welcome from '../screens/Access/Welcome';
import VerifyEmail from '../screens/Access/VerifyEmail';
import ForgotPassword from '../screens/Access/ForgotPassword';
import PasswordReset from '../screens/Access/PasswordReset';

//// Auth Shared Screens
import MainApp from '../screens/Auth/MainApp';
import EditProfile from '../screens/Auth/Shared/EditProfile';
import Feedback from '../screens/Auth/Shared/Feedback';
import ReportAbuse from '../screens/Auth/Shared/ReportAbuse';
import GiveFeedback from '../screens/Auth/Shared/GiveFeedback';
import Notifications from '../screens/Auth/Shared/Notifications';

// Donor Screens
import DonorDashboard from '../screens/Auth/Donor/Dashboard';
import DonationRequests from '../screens/Auth/Donor/DonationRequests';
import DonationHistory from '../screens/Auth/Donor/DonationHistory';
import Reminders from '../screens/Auth/Donor/Reminders';
import Achievements from '../screens/Auth/Donor/Achievements';

// Recipient Screens
import RecipientDashboard from '../screens/Auth/Recipient/Dashboard';
import FindDonor from '../screens/Auth/Recipient/FindDonor';
import FindDonorComplete from '../screens/Auth/Recipient/FindDonorComplete';
import Requests from '../screens/Auth/Recipient/Requests';
import DonateForm from '../screens/App/DonateForm';
import DonationComplete from '../screens/App/DonationComplete';
import Article from '../screens/App/Article';
import ShareTestimonial from '../screens/Auth/Shared/ShareTestimonial';

const ApplicationNavigation = () => {
  const { role } = useContext(RoleContext);

  return (
    <Routes>
      <Route path="/" element={<Applayout />}>
        <Route index element={<LandingPage />} />
        <Route path="/recipient-story" element={<RecipientStory />} />
        <Route path="/chart" element={<BloodMatchChart />} />
        <Route path="/about" element={<About />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:id" element={<Article />} />
        <Route path="/donation-process" element={<DonationProcess />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/donate/form" element={<DonateForm />} />
        <Route path="/become-an-advocate" element={<BecomeAnAdvocate />} />
      </Route>
      <Route path="/donate/complete" element={<DonationComplete />} />
      <Route
        path="/become-an-advocate/form"
        element={<BecomeAnAdvocateForm />}
      />

      {/* App Access Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password-reset" element={<PasswordReset />} />

      {/* Auth Routes */}
      <Route path="/app" element={<MainAuthLayout />}>
        <Route index element={<MainApp />} />
        <Route path="/app/profile" element={<EditProfile />} />
        <Route path="/app/feedback" element={<Feedback />} />
        <Route path="/app/feedback/:request_id" element={<GiveFeedback />} />
        <Route path="/app/share-testimonial" element={<ShareTestimonial />} />
        <Route path="/app/report" element={<ReportAbuse />} />
        <Route path="/app/chart" element={<BloodMatchChart />} />
        <Route path="/app/about" element={<About />} />
        <Route path="/app/partner" element={<Partner />} />
        <Route path="/app/articles" element={<Articles />} />
        <Route path="/app/terms-of-use" element={<TermsOfUse />} />
        <Route path="/app/faqs" element={<FAQs />} />
        <Route path="/app/donate" element={<Donate />} />
        <Route path="/app/donate/form" element={<DonateForm />} />
        <Route path="/app/become-an-advocate" element={<BecomeAnAdvocate />} />
        <Route path="/app/notifications" element={<Notifications />} />

        {role === 'donor' ? (
          <>
            <Route path="/app/dashboard" element={<DonorDashboard />} />
            <Route path="/app/requests" element={<DonationRequests />} />
            <Route path="/app/history" element={<DonationHistory />} />
            <Route path="/app/reminders" element={<Reminders />} />
            <Route path="/app/achievements" element={<Achievements />} />
          </>
        ) : role === 'recipient' ? (
          <>
            <Route path="/app/dashboard" element={<RecipientDashboard />} />
            <Route path="/app/dashboard/find-donor" element={<FindDonor />} />
            <Route
              path="/app/dashboard/find-donor/complete"
              element={<FindDonorComplete />}
            />
            <Route path="/app/your-requests" element={<Requests />} />
          </>
        ) : (
          <></>
        )}
      </Route>

      <Route path="*" element={<MainApp />} />
    </Routes>
  );
};

export default ApplicationNavigation;
