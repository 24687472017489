const navLinks = [
  {
    title: 'Advocacy',
    url: '/become-an-advocate',
  },
  {
    title: 'Articles',
    url: '/articles',
  },
  {
    title: 'About JBM',
    url: '/about',
  },
  {
    title: 'Donate',
    url: '/donate',
  },
];

const mobileNavLinks = [
  {
    title: 'Advocacy',
    url: '/become-an-advocate',
  },
  {
    title: 'Blood Match Chart',
    url: '/chart',
  },
  {
    title: 'Articles',
    url: '/articles',
  },
  {
    title: 'About JBM',
    url: '/about',
  },
  {
    title: 'Donate',
    url: '/donate',
  },
  {
    title: 'Share Link',
    url: '/',
  },
];

export { navLinks, mobileNavLinks };
