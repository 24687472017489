import { FC, PropsWithChildren } from 'react';
import RadioProps from './RadioProps';

const Radio: FC<PropsWithChildren<RadioProps>> = ({
  id,
  helperText,
  error,
  label,
  containerClass,
  ...rest
}) => {
  return (
    <div
      className={`flex items-center ${containerClass ? containerClass : ''}`}
    >
      <input
        id={id}
        type="radio"
        className="h-4 w-4 border-gray-300 bg-gray-100 text-primary-main focus:ring-2 focus:ring-primary-light dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-main"
        {...rest}
      />
      {label && (
        <label
          htmlFor={id}
          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {label}
        </label>
      )}

      {helperText && (
        <span
          className={`text-xs ${error ? 'text-red-500' : 'text-jblood-black'}`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default Radio;
