import { useContext, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdClose, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageContext } from '../../../contexts/page';
import { UserContext } from '../../../contexts/user';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../lib/Button';
import Heading from '../../lib/Heading';
import Logo from './Logo';
import MobileNav from './MobileNav';
import NavLinks from './NavLinks';

const Footer = () => {
  const { title } = useContext(PageContext);
  const { user } = useContext(UserContext);
  const largeScreen = useMediaQuery('(min-width: 1024px)');
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLandingPage = pathname === '/';

  const closeMovileNav = () => [setShowMobileNav(false)];

  return (
    <nav className="flex w-full items-center justify-center border-b border-[#D9D9D9] px-3 py-2 md:mt-3 md:px-5 md:pb-2">
      <div
        className={`max flex w-full items-center justify-between ${
          largeScreen || isLandingPage ? '' : 'p-2'
        }`}
      >
        {isLandingPage || largeScreen ? (
          <Logo />
        ) : (
          <MdOutlineKeyboardArrowLeft size={30} onClick={() => navigate(-1)} />
        )}

        {!isLandingPage && !largeScreen && <Heading type="h3">{title}</Heading>}

        <div className="flex items-center gap-16">
          {largeScreen && <NavLinks />}

          <div className="flex items-center gap-16">
            <div className="flex items-center gap-3">
              {user ? (
                <Link to="/app/dashboard">
                  <Button
                    size="small"
                    variant="solid"
                    className="hidden lg:block"
                  >
                    Go to dashboard
                  </Button>
                </Link>
              ) : (
                <>
                  {(isLandingPage || largeScreen) && (
                    <Link to="/login">
                      <Button
                        size="small"
                        variant={largeScreen ? 'outline' : 'solid'}
                      >
                        Log in
                      </Button>
                    </Link>
                  )}

                  <Link to="/signup">
                    <Button
                      size="small"
                      variant="solid"
                      className="hidden lg:block"
                    >
                      Sign up
                    </Button>
                  </Link>
                </>
              )}

              {!largeScreen && showMobileNav ? (
                <MdClose
                  size={25}
                  className="z-50"
                  onClick={() => setShowMobileNav((prevState) => !prevState)}
                />
              ) : (
                <AiOutlineMenu
                  className="lg:hidden"
                  size={25}
                  onClick={() => setShowMobileNav((prevState) => !prevState)}
                />
              )}
            </div>
          </div>
        </div>

        {!largeScreen && showMobileNav && (
          <MobileNav onClose={closeMovileNav} />
        )}
      </div>
    </nav>
  );
};

export default Footer;
