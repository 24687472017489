import FormContainer from '../../components/containers/FormContainer';
import PaymentComplete from '../../assets/images/PaymentComplete.png';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import Button from '../../components/lib/Button';
import { Link } from 'react-router-dom';

const DonationComplete = () => {
  return (
    <FormContainer showBackArrow={false}>
      <div className="flex flex-col items-center lg:-mt-32">
        <figure className="aspect-square w-[90vw] max-w-[400px] overflow-hidden">
          <img
            src={PaymentComplete}
            alt="Payment Complete"
            className="h-full w-full object-contain"
          />
        </figure>

        <div className="flex flex-col items-center gap-3 text-center">
          <Heading>Donation Received</Heading>
          <Text className="max-w-[50ch]">
            Thank you for your donation to Jela’s Development Initiatives. Your
            donation will enable us achieve our objectives of promoting health
            and wellness through blood donation.
          </Text>

          <Link to="/*">
            <Button
              className="mt-10 w-[80vw] max-w-[350px]"
              variant="gradient"
              size="medium"
            >
              Done
            </Button>
          </Link>
        </div>
      </div>
    </FormContainer>
  );
};

export default DonationComplete;
