import { Link } from 'react-router-dom';
import UserAvatar from '../../../assets/images/avatars/user.png';
import FindDonorCard from '../../../components/auth/Recipient/Dashboard/FindDonorCard';
import Card from '../../../components/lib/Card';

const Dashboard = () => {
  return (
    <div className="w-full max-w-[900px] lg:w-[60vw]">
      <Card title="Find A Donor">
        <div className="flex w-full flex-col gap-5">
          <Link to="/app/dashboard/find-donor?recipient=self">
            <FindDonorCard title="For Yourself" />
          </Link>
          <Link to="/app/dashboard/find-donor?recipient=other">
            <FindDonorCard image={UserAvatar} title="For Another Recipient" />
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;
