export const timelines = [
  {
    batch: 'Batch 1',
    sleectionPeriod: '23rd Feb. - 11th Mar., 2023',
    trainingDates: '16th Mar. - 18th Mar., 2023',
    duration: 'March-May, 2023',
  },
  {
    batch: 'Batch 2',
    sleectionPeriod: '10th Jul. - 28th Jul., 2023',
    trainingDates: '3rd Aug. - 5th Aug., 2023',
    duration: 'August-October, 2023',
  },
];
