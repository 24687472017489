import { FC } from 'react';
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import Avatar from '../../../lib/Avatar';
import Heading from '../../../lib/Heading';
import Modal from '../../../lib/Modal';
import Text from '../../../lib/Text';
import FeddbackModalProps from './FeedbackModalProps';

const FeedbackModal: FC<FeddbackModalProps> = ({
  open,
  onClose,
  selectedIndex,
  setSelectedIndex,
  feedback,
}) => {
  const data = feedback[selectedIndex];

  const handleNext = () => {
    if (selectedIndex + 1 > feedback.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex < 1) {
      setSelectedIndex(feedback.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return (
    <>
      <Modal
        {...{ open, onClose }}
        className="relative max-h-[75vh] w-[95vw] max-w-[600px] rounded-lg bg-[#FCF9F8] lg:max-h-max"
      >
        <figure className="absolute left-[50%] -top-[7%] z-20 h-16 w-16 -translate-x-[50%] overflow-hidden rounded-full border-4 border-white md:h-20 md:w-20 md:border-8">
          <Avatar className="!h-full !w-full" name={data?.name} />
        </figure>

        <MdClose
          onClick={onClose}
          className="absolute top-5 right-5 z-20 cursor-pointer text-2xl"
        />

        <MdKeyboardArrowLeft
          onClick={handlePrevious}
          className="absolute top-[50%] left-[2%] z-20 cursor-pointer text-2xl"
        />

        <MdKeyboardArrowRight
          onClick={handleNext}
          className="absolute top-[50%] right-[2%] z-20 cursor-pointer text-2xl"
        />
        <div className="relative flex max-h-[75vh] w-[95vw] max-w-[600px] flex-col items-center gap-3 overflow-y-auto bg-transparent px-10 py-10 lg:max-h-max lg:py-14 lg:px-20">
          <Heading type="h3" className="capitalize">
            {data?.name}
          </Heading>

          <div className="mt-5 flex min-h-[40vh] w-full flex-col items-center justify-center gap-5 rounded-lg bg-white py-5 px-5 shadow shadow-primary-light/50 lg:px-10">
            <Text className="max-w-[35ch] text-center">
              {data?.description}
            </Text>
          </div>

          <div className="mx-auto mt-3 flex w-full items-center justify-center gap-2">
            {feedback.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === selectedIndex
                    ? 'bg-primary-light'
                    : 'bg-primary-light/30'
                }`}
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackModal;
