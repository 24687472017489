import { FC, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import toaster from 'react-hot-toast';
import { UserContext } from '../../../../../contexts/user';
import { postRequest } from '../../../../../utils/api/calls';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import FormsProps, { FormPayload } from './FormsProps';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { CREATE_DONATION_REQUEST } from '../../../../../utils/api/urls';
import { isEmpty } from '../../../../../utils/validators';

const initialState = {
  gender: '',
  blood_group: '',
  longitude: null,
  latitude: null,
  recipient: '',
  reason_id: '',
  reason: '',
  units: '',
  date: '',
  hospital: '',
  physician: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  state_id: '',
};

const Forms: FC<FormsProps> = ({
  activeStep,
  handleNext,
  setActiveStep,
  recipient,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const self = recipient === 'self';
  const [payload, setPayload] = useState<FormPayload>({
    ...initialState,
    gender: self ? user?.gender || '' : '',
    blood_group: self ? user?.blood_group || '' : '',
    recipient: self ? 'self' : 'others',
    first_name: self ? `${user?.first_name || ''}` : '',
    last_name: self ? `${user?.last_name || ''}` : '',
  });
  const handler = useErrorHandler();

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      toaster.success('Request submitted successfully');
      navigate('/app/dashboard/find-donor/complete');
    },
    onError(error) {
      setActiveStep(1);
      handler(error);
    },
  });

  const handleSubmit = (formPayload: FormPayload) => {
    const data = { ...formPayload };

    if (isEmpty(data?.phone_number)) {
      delete data.phone_number;
    }

    mutate({
      url: CREATE_DONATION_REQUEST,
      data: {
        ...data,
        longitude: String(data.longitude),
        latitude: String(data.latitude),
      },
    });
  };

  switch (recipient) {
    case 'self':
      switch (activeStep) {
        case 1:
          return <Form2 {...{ handleNext, payload, setPayload, recipient }} />;

        case 2:
          return (
            <Form3
              {...{ handleNext, payload, setPayload, handleSubmit, isLoading }}
            />
          );

        default:
          return <></>;
      }

    case 'other':
      switch (activeStep) {
        case 1:
          return <Form1 {...{ handleNext, payload, setPayload }} />;

        case 2:
          return <Form2 {...{ handleNext, payload, setPayload }} />;

        case 3:
          return (
            <Form3
              {...{ handleNext, payload, setPayload, handleSubmit, isLoading }}
            />
          );

        default:
          return <></>;
      }

    default:
      return <></>;
  }
};

export default Forms;
