import { FC } from 'react';
import match from '../../../utils/match';
import Loader from '../Loader';
import ButtonProps from './ButtonProps';

const Button: FC<ButtonProps> = ({
  variant = 'solid',
  size = 'small',
  children,
  disabled,
  className,
  loading,
  ...rest
}) => {
  const buttonSize = match(size, {
    small:
      'min-w-[90px] min-h-[30px] lg:min-w-[100px] lg:min-h-[40px] px-3 py-2 text-sm',
    medium:
      'min-w-[120px] min-h-[35px] lg:min-w-[170px] lg:min-h-[43px] px-5 py-3 md:px-5 md:py-3',
    large:
      'min-w-[250px] min-h-[45px] lg:min-w-[350px] lg:min-h-[50px] px-3 py-2',
    default: '',
  });

  const buttonVariant = match(variant, {
    solid:
      'bg-primary-main hover:bg-primary-dark text-white font-interSemibold disabled:bg-gray-200 disabled:text-gray-500',
    gradient:
      'bg-gradient hover:bg-gradient-reverse text-white font-interSemibold disabled:bg-none disabled:bg-gray-200 disabled:text-gray-500',
    outline:
      'border-2 border-primary-main hover:border-primary-dark text-primary-main hover:text-primary-dark font-interSemibold disabled:border-gray-200 disabled:text-gray-500',
    default: '',
  });

  return (
    <button
      disabled={loading || disabled}
      className={`rounded-full ${buttonSize} ${buttonVariant} ${
        className || ''
      }`}
      {...rest}
    >
      {loading ? (
        <Loader color={variant === 'outline' ? '#E35D5B' : '#fff'} />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
