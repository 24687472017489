import { FC, FormEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRequest } from '../../../../utils/api/calls';
import queryKeys from '../../../../utils/api/queryKeys';
import { GET_STATES } from '../../../../utils/api/urls';
// import Checkbox from '../../../form/Checkbox';
import Select from '../../../form/Select';
import Button from '../../../lib/Button';
import Heading from '../../../lib/Heading';
import Text from '../../../lib/Text';
import { FormProps } from './FormsProps';
import { isEmpty, validateForm3Inputs } from '../../../../utils/validators';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { State } from '../../../../types/State';
import LocationAutoComplete from '../../../lib/LocationAutoComplete';
import { LocationData } from '../../../lib/LocationAutoComplete/LocationAutoCompleteProps';
import { toast } from 'react-hot-toast';

const initialState: {
  state_id: string;
  longitude: string | null;
  latitude: string | null;
  address: string;
} = {
  state_id: '',
  longitude: null,
  latitude: null,
  address: '',
};

const initialErrors = {
  state_id: '',
  longitude: '',
  latitude: '',
};

const Form3: FC<FormProps> = ({
  handleNext,
  payload: generalPayload,
  setPayload: setGeneralPayload,
}) => {
  const [payload, setPayload] = useState(initialState);
  const [states, setStates] = useState<State[]>([]);
  const [errors, setErrors] = useState({ ...initialErrors, location: '' });
  const [checked] = useState(false);
  const name = localStorage.getItem('user_name') || '';
  const handler = useErrorHandler();

  useQuery([queryKeys.getStates], () => getRequest({ url: GET_STATES }), {
    onSuccess(response) {
      setStates(response?.data?.states || []);
    },
    onError(error: any) {
      handler(error);
    },
  });

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors({ ...initialErrors, location: '' });

    const { valid, errors: validationErrors } = validateForm3Inputs(payload);

    if (valid && isEmpty(errors.location)) {
      setGeneralPayload({ ...generalPayload, ...payload });
      handleNext();
    } else {
      setErrors({ ...validationErrors, location: errors.location });
    }
  };

  const handleLocationChange = (location: LocationData) => {
    const { geo, state, country, fullAddress } = location;

    if (country !== 'Nigeria') {
      toast.error('You can only select a location in Nigeria.');
      setErrors({
        ...errors,
        location: 'Location must be in Nigeria',
      });
      return;
    }

    const state_id = states.find((item) => item.name === state)?.id;

    setPayload({
      address: fullAddress || '',
      state_id: String(state_id || ''),
      longitude: String(geo.longitude),
      latitude: String(geo.latitude),
    });

    setErrors({
      ...errors,
      location: '',
    });
  };

  useEffect(() => {
    if (checked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setPayload({
              ...payload,
              longitude: String(position.coords.longitude),
              latitude: String(position.coords.latitude),
            });
          },
          (error) => {
            console.log(error);
            alert('There was a problem getting you location');
          }
        );
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    } else {
      setPayload({
        ...payload,
        longitude: null,
        latitude: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <>
      {name && (
        <>{name.trim() !== '' && <Heading type="h3">Hello, {name}</Heading>}</>
      )}
      <Text>
        Help save lives: Enter in full, your nearby address or a popular
        landmark close to you for a perfect blood donor match.
      </Text>

      {!checked ? (
        <LocationAutoComplete
          label="Search and Enter Near address"
          placeholder="Search for addres or landmark"
          error={
            !isEmpty(errors.latitude) ||
            !isEmpty(errors.longitude) ||
            !isEmpty(errors.location)
          }
          helperText={
            !isEmpty(errors.location)
              ? errors.location
              : !isEmpty(errors.latitude) || !isEmpty(errors.longitude)
              ? 'Location must not be empty'
              : ''
          }
          onChange={handleLocationChange}
        />
      ) : (
        <Select
          label="State"
          placeholder="Select State"
          name="state_id"
          value={payload.state_id}
          options={[
            { key: 'Select State', value: '' },
            ...states.map((state) => ({
              key: state.name,
              value: state.id,
            })),
          ]}
          onChange={handleChange}
          error={!isEmpty(errors.state_id)}
          helperText={errors.state_id}
        />
      )}

      {/* <div className="mt-32 flex flex-col items-center gap-1 text-center lg:mt-5">
        <Checkbox
          checked={checked}
          onChange={() => setChecked((prevState) => !prevState)}
          label="Please check this option if you do not mind that we use your live location."
        />
        <Text>
          This will only be used to match you with recipients in need of blood
          based on your real-time location.
        </Text>
      </div> */}
      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full max-w-[500px]"
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </>
  );
};

export default Form3;
