import { FC, FormEvent, useState } from 'react';
import { bloodGroups, genders } from '../../../../utils/constants';
import { isEmpty, validateForm2Inputs } from '../../../../utils/validators';
import Input from '../../../form/Input';
import Select from '../../../form/Select';
import Button from '../../../lib/Button';
import { FormProps } from './FormsProps';

const initialState = {
  gender: '',
  dob: '',
  blood_group: '',
};

const Form2: FC<FormProps> = ({
  handleNext,
  payload: generalPayload,
  setPayload: setGeneralPayload,
}) => {
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } = validateForm2Inputs(payload);

    if (valid) {
      setGeneralPayload({ ...generalPayload, ...payload });
      handleNext();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <Select
        label="Gender"
        placeholder="Enter your full name"
        name="gender"
        value={payload.gender}
        options={[{ key: 'Select Gender', value: '' }, ...genders]}
        onChange={handleChange}
        error={!isEmpty(errors.gender)}
        helperText={errors.gender}
      />
      <Input
        label="Date of Birth"
        type="date"
        placeholder=""
        name="dob"
        max={new Date().toISOString().split('T')[0]}
        value={payload.dob}
        onChange={handleChange}
        error={!isEmpty(errors.dob)}
        helperText={errors.dob}
      />
      <Select
        label="Blood Group"
        placeholder="Select Blood Group"
        name="blood_group"
        value={payload.blood_group}
        options={[{ key: 'Select Blood Group', value: '' }, ...bloodGroups]}
        onChange={handleChange}
        error={!isEmpty(errors.blood_group)}
        helperText={errors.blood_group}
      />

      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full max-w-[500px]"
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </>
  );
};

export default Form2;
