import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import PageTitle from '../../components/containers/PageTitle';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="Privacy Policy">
      <div
        className={`max mx-auto flex flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          Data Protections and Privacy Policy
        </Heading>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Text>
            Jela’s Development Initiatives (JDI) is committed to protecting your
            data and your right to privacy as a user of J Blood Match.
          </Text>
          <Text>
            The information you provide to us will be held on J Blood Match
            servers based in Nigeria and we will not transfer it.
          </Text>
          <Text>
            We collect information about our users in order to help us
            continually improve the platform and develop blood donation reports
            based on the behaviour of our J Blood Match donors.
          </Text>
          <Text>
            We do not sell any of the data gathered through J Blood Match nor
            use it for any nefarious purposes.
          </Text>
          <Text>
            This Data Protection and Privacy policy only relates to J Blood
            Match web app and does not extend to your use of the internet
            outside of the J Blood Match web app.
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">About Us</Heading>
          <Text>
            Jela’s Development Initiatives is a Nigerian non-profit organisation
            registered in 2019 to advocate for and provide opportunities for any
            individual- irrespective of beliefs, gender, socio-economic
            background- to access potable water as well as sanitation and
            hygiene facilities, voluntary blood donors and mental healthcare
            while providing platforms for youth empowerment through
            volunteering.
          </Text>
          <Text>Our registration number is 129399.</Text>
          <Text>
            For data protection queries, please contact us by email at{' '}
            <a
              className="text-primary-main underline"
              href="mailto:jbmqueries@gmail.com"
            >
              jbmqueries@gmail.com
            </a>
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">What data do we collect?</Heading>
          <Text>
            We collect only data that will aid the connection of voluntary blood
            donors to recipients, improve the J Blood Match service and promote
            our goal of increasing the Nigerian blood donor pool from less than
            1% to 3%. The following make up the personal data that we collect at
            the point of registration:
          </Text>
          <ul className="ml-5 grid list-disc gap-5">
            <li>
              <Text>Name</Text>
            </li>
            <li>
              <Text>Email address</Text>
            </li>
            <li>
              <Text>Phone Number</Text>
            </li>
            <li>
              <Text>State of residence</Text>
            </li>
            <li>
              <Text>LGA/Town within State</Text>
            </li>
            <li>
              <Text>Blood type</Text>
            </li>
            <li>
              <Text>Age</Text>
            </li>
            <li>
              <Text>Gender</Text>
            </li>
          </ul>
          <Text>
            The following make up the information that we collect at the point
            of request for donor:
          </Text>
          <ul className="ml-5 grid list-disc gap-5">
            <li>
              <Text>Health reason for request</Text>
            </li>
            <li>
              <Text>Units of blood required</Text>
            </li>
            <li>
              <Text>Blood type required</Text>
            </li>
            <li>
              <Text>Date when donor is needed</Text>
            </li>
            <li>
              <Text>State of where hospital is located</Text>
            </li>
            <li>
              <Text>Hospital where recipient is located</Text>
            </li>
            <li>
              <Text>
                Name of Doctor/Medical personnel in charge of the recipient
              </Text>
            </li>
          </ul>
          <Text>
            Upon donating or receiving blood, we also collect your feedback on
            the process, the use of web app and testimonials that may be used on
            social media and other communication materials are purely for
            improving our services.
          </Text>
          <Text>
            Financial details inputted through J Blood Match app for donations
            to our work are NOT retained in our servers. Rather, payments are
            processed through a third-party, reputable digital financial
            service.
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">What do we use your data for?</Heading>
          <Text>
            We use the information we collect in various ways, including to:
          </Text>
          <Text>
            All personal data that are collected on the platform are used SOLELY
            by the artificial intelligence on J Blood Match web app for
            connecting matching blood donors on our platform to recipients who
            are also registered as voluntary blood donors.
          </Text>
          <Text>
            At the point of connection between two parties (donor and
            recipient), J Blood Match web app shares ONLY the donors phone
            number with the recipient to aid the blood donation process at a
            registered hospital.
          </Text>
          <Text>
            J Blood Match web app also collects email addresses to send
            notifications of blood donation requests to its registered donors.
          </Text>
          <Text>
            For improving our services, our use of your phone number may also
            include occasional calls to gather feedback and testimonials.
          </Text>
          <Text>
            Feedback that is collected through J Blood Match web app may also
            form blood donation reports generated to inform the wider public on
            blood donation matters as a public health concern. It is imperative
            to add that reports of this nature will keep the specific identity
            of J Blood Match web app community members anonymous and will only
            present general statistics and data deductions.
          </Text>
          <Text>
            Our processing of your personal data is necessary to provide the J
            Blood Match service to you and to continually improve it.
          </Text>
          <Text>
            We do not use your personal data for any reasons not stated in this
            Data Protection and Privacy Policy and never use it in a manner that
            is not aimed at improving our service or in a way that harms you.
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">How long do we keep your data?</Heading>
          <Text>
            The personal data that you provide when registering with J Blood
            Match web app is retained as long as you remain a J Blood Match
            donor or you ask us to delete your account by sending us an email on{' '}
            <a
              className="text-primary-main underline"
              href="mailto:jbmqueries@gmail.com"
            >
              jbmqueries@gmail.com
            </a>
          </Text>
          <Text>
            Personal data contained within emails sent to us will be retained
            for as long as we reasonably require it and this may include
            questions relating to our content along with our answers to those
            questions. We also retain certain communications to aid in the
            conduct of potential future legal claims.
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">Do we share your personal data?</Heading>
          <Text>
            No. Your personal data remains in our servers and as stated earlier,
            only aids the artificial intelligence on J Blood Match web app in
            making a connection between blood donors and recipients.
          </Text>
          <Text>
            On no occasion will we share your personal data with any third
            parties.
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">
            What are your rights as a user of the J Blood Match App?
          </Heading>
          <ul className="ml-5 grid list-disc gap-5">
            <li>
              <Text>
                The right to be informed about our collection and use of your
                personal data (as described in this Data Protection and Privacy
                Policy).
              </Text>
            </li>
            <li>
              <Text>
                The right to access or change your personal data by clicking on
                ‘Edit Profile’ on the J Blood Match web app.
              </Text>
            </li>
            <li>
              <Text>
                The right to erasure. You can exercise this right by contacting
                us through{' '}
                <a
                  className="text-primary-main underline"
                  href="mailto:jbmqueries@gmail.com"
                >
                  jbmqueries@gmail.com
                </a>{' '}
                at which point, we delete your account completely.
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">How can you access your personal data?</Heading>

          <Text>
            All personal data you provided during your registration on J Blood
            Match web app can be accessed through ‘Edit Profile’.
          </Text>

          <Text>
            Testimonials or feedback shared with us that are used within reports
            which will be publicly available on Jela’s Development Initiatives’
            website through{' '}
            <a
              href="https://www.jdi.org.ng/our-publications/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary-main underline"
            >
              https://www.jdi.org.ng/our-publications
            </a>
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">How to contact us</Heading>
          <Text>
            To contact Jela’s Development Initiatives about anything to do with
            your personal data and data protection, please email us through{' '}
            <a
              className="text-primary-main underline"
              href="mailto:jbmqueries@gmail.com"
            >
              jbmqueries@gmail.com
            </a>
          </Text>
          <Text>
            For urgent enquiries or concerns, you may call us between 9am and
            5pm, Mondays to Fridays through:{' '}
            <a
              className="text-primary-main underline"
              href="tel:+2349043328986"
            >
              +2349043328986
            </a>
          </Text>
        </div>

        <div className="mb-10 flex w-full flex-col gap-5 lg:mb-5 lg:p-5">
          <Heading type="h3">
            Changes to this data protection and privacy policy
          </Heading>
          <Text>
            We may change our Data Protection and Privacy Policy from time to
            time. This may be necessary if we change any major aspect of our
            operations in a way that affects your personal data protection. You
            will be notified of such changes the first time you login to J Blood
            Match app after the changes have been made.
          </Text>
        </div>
      </div>
    </PageTitle>
  );
};

export default PrivacyPolicy;
