import { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { postRequest } from '../../utils/api/calls';
import { FORGOT_PASSWORD } from '../../utils/api/urls';
import FormContainer from '../../components/containers/FormContainer';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import Button from '../../components/lib/Button';
import useErrorHandler from '../../hooks/useErrorHandler';
import Input from '../../components/form/Input';
import { isEmpty, validateForgotPaswordInputs } from '../../utils/validators';

const initialState = {
  email: '',
};

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const handler = useErrorHandler();
  const { pathname } = useLocation();

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      setSubmitted(true);
    },
    onError(error: any) {
      handler(error);
    },
  });

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } =
      validateForgotPaswordInputs(payload);

    if (valid) {
      mutate({
        url: FORGOT_PASSWORD,
        data: {
          ...payload,
          base_url: `${window.location.href.split(pathname)[0]}/`,
        },
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <FormContainer showBackArrow={false}>
        <div className="flex min-h-[80vh] max-w-[500px] flex-col items-center justify-center gap-5 p-5 lg:min-h-[60vh] lg:max-w-[650px]">
          {submitted ? (
            <>
              <Heading type="h1" className="text-center">
                Reset Pasword Link Sent
              </Heading>
              <Text className="max-w-[35ch] text-center" type="body2">
                We have sent a reset password link to{' '}
                <span className="text-green-500">{payload.email}</span>.
              </Text>
            </>
          ) : (
            <>
              <Heading
                underlined
                className="mx-auto w-[60vw] text-center text-2xl md:w-auto"
              >
                Forgot Password
              </Heading>

              <Text className="max-w-[35ch] text-center lg:max-w-none">
                Have you forgotten your password? Enter your email address and
                we'd send you a link to reset your password.
              </Text>

              <div className="w-full">
                <Input
                  label="Email addres"
                  type="email"
                  placeholder="example@domain.com"
                  name="email"
                  value={payload.email}
                  onChange={handleChange}
                  error={!isEmpty(errors.email)}
                  helperText={errors.email}
                />
              </div>

              <Button
                size="medium"
                variant="gradient"
                className="mx-auto mt-10 w-full max-w-[500px]"
                disabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit}
              >
                Proceed
              </Button>
            </>
          )}
        </div>
      </FormContainer>
    </>
  );
};

export default ForgotPassword;
