import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { ImArrowLeft2 } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import FloatingCircleGradient from '../../assets/icons/FloatingCircleGradient.svg';
import Heading from '../lib/Heading';
import { FC, PropsWithChildren } from 'react';

const FormContainer: FC<
  PropsWithChildren<{
    title?: string;
    showBackArrow?: boolean;
    onBackClicked?: () => void;
  }>
> = ({ children, showBackArrow = true, title, onBackClicked }) => {
  const navigate = useNavigate();

  return (
    <div className="max relative mx-auto flex w-full flex-col items-center gap-5 p-5 py-10 pb-10 md:p-10 lg:px-20 lg:py-16">
      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[5%] right-[5%] -z-10 h-7 w-7  opacity-40 md:top-[5%] md:right-[5%] lg:h-10 lg:w-10"
      />
      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[8%] right-[13%] -z-10 h-2 w-2 opacity-40 md:top-[10%] md:right-[10%]"
      />

      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[60%] left-[2%] -z-10 hidden h-7 w-7 opacity-40 md:top-[50%] md:left-[2%] lg:left-[7%] lg:block lg:h-10 lg:w-10"
      />
      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[60%] left-[0%] -z-10 hidden h-2 w-2 opacity-40 md:top-[50%] md:left-[0%] lg:left-[5%] lg:block"
      />

      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[97%] left-[50%] -z-10 h-7 w-7 opacity-40 md:top-[95%] md:left-[50%] lg:h-10 lg:w-10"
      />
      <img
        src={FloatingCircleGradient}
        alt=""
        className="absolute top-[100%] left-[48%] -z-10 h-2 w-2 opacity-40 md:top-[99%] md:left-[48%]"
      />

      <div className="flex w-full flex-col items-center justify-between gap-3 md:mb-10 lg:flex-row">
        {showBackArrow && (
          <div className="flex w-full justify-start lg:hidden">
            <MdOutlineKeyboardArrowLeft
              size={30}
              onClick={() => (onBackClicked ? onBackClicked() : navigate(-1))}
            />
          </div>
        )}

        {showBackArrow ? (
          <ImArrowLeft2
            size={30}
            onClick={() => (onBackClicked ? onBackClicked() : navigate(-1))}
            className="hidden cursor-pointer text-jblood-gray lg:block"
          />
        ) : (
          <span className="hidden lg:block">&nbsp;</span>
        )}
        {title && (
          <Heading underlined className="w-[60vw] text-center md:w-auto">
            {title}
          </Heading>
        )}
        <span className="hidden lg:block">&nbsp;</span>
      </div>

      {children}
    </div>
  );
};

export default FormContainer;
