import { useState } from 'react';
import { useQuery } from 'react-query';
import Location from '../../../../assets/icons/Location.svg';
import BackgroundImage from '../../../../assets/images/DashboardDataCardBg.png';
import { getRequest } from '../../../../utils/api/calls';
import queryKeys from '../../../../utils/api/queryKeys';
import { GET_USER } from '../../../../utils/api/urls';
import Heading from '../../../lib/Heading';
import Loader from '../../../lib/Loader';
import Text from '../../../lib/Text';

const DataCard = () => {
  const [user, setUser] = useState<{
    [key: string]: any;
  }>({});

  const { isLoading } = useQuery(
    [queryKeys.getUser],
    () => getRequest({ url: GET_USER }),
    {
      onSuccess(response) {
        setUser(response?.data?.user || {});
      },
    }
  );

  return (
    <div className="relative mb-10 flex aspect-video w-full flex-col justify-center gap-3 overflow-hidden rounded-lg p-5 md:mb-5">
      <img
        src={BackgroundImage}
        className="absolute top-0 left-0 -z-10 h-full w-full object-cover"
        alt="background"
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col">
              <Text
                className="font-soraRegular text-xs text-white/70"
                type="caption"
              >
                Your Location
              </Text>
              <Heading type="h1" className="font-soraSemibold text-white">
                {user?.state?.name}
              </Heading>
            </div>

            <div className="rounded-full bg-white/30 py-2 px-3">
              <Text className="font-soraSemibold text-white">
                {user?.blood_group}
              </Text>
            </div>
          </div>

          <div className="flex items-center">
            <hr className="flex-1 border-white" />

            <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white">
              <img src={Location} alt="location" className="h-8 w-8" />
            </div>

            <hr className="flex-1 border-white" />
          </div>
        </>
      )}
    </div>
  );
};

export default DataCard;
