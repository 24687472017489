import { FC } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../../../lib/Avatar';
import Text from '../../../../lib/Text';
import { BloodDonationRequestsCardProps } from './BloodDonationRequestsProps';

const BloodDonationRequestsCard: FC<BloodDonationRequestsCardProps> = ({
  blood_group,
  recipient,
  image,
  hospital,
  name,
  user,
}) => {
  return (
    <Link to="/app/requests">
      <div className="flex gap-2 rounded-lg border border-[#F5F0F0] bg-[#FCF9F8] p-5">
        <Avatar name={`${user?.first_name} ${user?.last_name}`} image={image} />

        <div className="flex-1 flex-col gap-2">
          <Text className="!font-interSemibold capitalize">
            {`${user?.first_name} ${user?.last_name}`}
          </Text>
          <Text className="text-xs" type="caption">
            {hospital}
          </Text>
        </div>

        <Text className="!font-interSemibold uppercase text-primary-main">
          {blood_group}
        </Text>
      </div>{' '}
    </Link>
  );
};

export default BloodDonationRequestsCard;
