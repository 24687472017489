import { FC, useContext } from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import { UserContext } from '../../../../../contexts/user';
import Avatar from '../../../../lib/Avatar';
import Text from '../../../../lib/Text';
import FindDonorCardProps from './FindDonorCardProps';

const FindDonorCard: FC<FindDonorCardProps> = ({ image, title }) => {
  const { user } = useContext(UserContext);

  return (
    <div className="flex cursor-pointer items-center gap-5 rounded-lg border border-[#F5F0F0] bg-[#FCF9F8] p-5">
      <Avatar
        image={image || user?.image}
        name={`${user?.first_name} ${user?.last_name}`}
      />
      <Text className="flex-1 !font-interSemibold">{title}</Text>

      <ImArrowRight2 size={18} className="cursor-pointer text-jblood-black" />
    </div>
  );
};

export default FindDonorCard;
