import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Accordion from '../../lib/Accordion';
import Heading from '../../lib/Heading';
import { faqs } from './dummyData/faqs';

const Faqs = () => {
  return (
    <section className="px-7 py-12 lg:px-20 lg:py-20">
      <div className="mx-auto flex w-full max-w-[900px] flex-col items-center gap-10 text-center">
        <Heading type="h2">Frequently Asked Questions</Heading>

        <div className="w-full">
          <Accordion data={faqs} />
          <Link to="/faqs" className="w-full">
            <div className="w-fulll flex cursor-pointer select-none items-center justify-between gap-3 border-b border-primary-dark py-5 md:py-7">
              <Heading type="h3">All frequently asked questions</Heading>
              <AiOutlineArrowRight
                className="min-w-[50px] text-primary-dark"
                size={20}
              />
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
