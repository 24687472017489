import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../../components/auth/Navbar';
import ActionSheetNavLinks from '../../components/auth/Navbar/ActionSheetNavLinks';
import Sidebar from '../../components/auth/Sidebar';
import Tabbar from '../../components/auth/Tabbar';
import ActionSheet from '../../components/lib/ActionSheet';
import useMediaQuery from '../../hooks/useMediaQuery';

const MainAuthLayout = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const largeScreen = useMediaQuery('(min-width: 1200px)');
  const navigate = useNavigate();

  useEffect(() => {
    setShowSideNav(false);
  }, [largeScreen]);

  useEffect(() => {
    const userData = localStorage.getItem('user');

    if (userData) {
      const user = JSON.parse(userData);

      if (!user?.id) {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSideNav = () => {
    setShowSideNav((prevState) => !prevState);
  };

  const toggleActionSheet = () => {
    setShowActionSheet((prevState) => !prevState);
  };

  return (
    <>
      <div className="relative flex h-screen w-screen flex-1 overflow-x-hidden overflow-y-hidden">
        <Sidebar {...{ open: showSideNav, onClose: toggleSideNav }} />

        <div className="flex h-screen w-full flex-1 flex-col overflow-x-hidden">
          <main className="flex flex-1 flex-col gap-5 overflow-y-auto py-0 pb-40 lg:h-[screen] lg:gap-10 lg:px-5 lg:py-10">
            <Navbar {...{ toggleSideNav, toggleActionSheet }} />
            <div className="flex flex-1 flex-col gap-10 p-5">
              <Outlet />
            </div>
          </main>
        </div>

        <ActionSheet
          {...{
            open: showActionSheet,
            onClose: () => setShowActionSheet(false),
          }}
        >
          <ActionSheetNavLinks {...{ setOpen: setShowActionSheet }} />
        </ActionSheet>
      </div>
      <Tabbar />
    </>
  );
};

export default MainAuthLayout;
