import { FC, useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByLatLng,
} from 'react-google-places-autocomplete';
import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import useMediaQuery from '../../../hooks/useMediaQuery';
import LocationAutoCompleteProps from './LocationAutoCompleteProps';

const LocationAutoComplete: FC<LocationAutoCompleteProps> = ({
  onChange,
  label,
  error,
  helperText,
  placeholder,
  helperLabel,
  initialLocation,
}) => {
  const [address, setAddress] = useState<any>();
  const largeScreen = useMediaQuery('(min-width: 1024px)');

  const initializeLocation = async (initialLocation?: LatLng) => {
    if (initialLocation) {
      const geocodeObj = await geocodeByLatLng(initialLocation);
      const initialAddress = {
        label: geocodeObj?.[0]?.formatted_address || '',
        value: {
          description: geocodeObj?.[0]?.formatted_address || '',
          place_id: geocodeObj?.[0]?.place_id || '',
          reference: geocodeObj?.[0]?.place_id || '',
        },
      };
      setAddress(initialAddress);
    }
  };

  useEffect(() => {
    initializeLocation(initialLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const func = async () => {
      const geocodeObj =
        address &&
        address.value &&
        (await geocodeByPlaceId(address.value.place_id));

      if (geocodeObj?.[0]) {
        const fullAddress = geocodeObj?.[0]?.formatted_address || '';
        const splitAddress = fullAddress.split(', ');

        const country = splitAddress[splitAddress.length - 1];
        const state = splitAddress[splitAddress.length - 2];

        const location = geocodeObj?.[0]?.geometry?.location;

        onChange({
          fullAddress: `${
            address?.value?.structured_formatting?.main_text
              ? `${address?.value?.structured_formatting?.main_text}, `
              : ''
          }${fullAddress}`,
          state: state === 'Federal Capital Territory' ? 'FCT' : state,
          country,
          geo: {
            latitude: location?.lat(),
            longitude: location?.lng(),
          },
        });
      }
    };

    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <div className="grid w-full">
      {label && (
        <label className="font-interSemibold text-xs md:text-sm">
          {label}{' '}
          <span className="font-interRegular text-xs text-jblood-gray">
            {helperLabel}
          </span>
        </label>
      )}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['ng'],
          },
        }}
        selectProps={{
          isClearable: true,
          placeholder: placeholder || 'Search Location',
          styles: {
            control: (provided: any) => ({
              ...provided,
              border: error
                ? 'solid 1px rgb(227 93 91 / 1) !important'
                : 'solid 1px rgb(139 136 135 / 1) !important',
              marginTop: '.35rem',
            }),
            input: (provided: any) => ({
              ...provided,
              padding: largeScreen
                ? '.8rem 2.5rem !important'
                : '.8rem 1.25rem !important',
              fontSize: '0.875rem !important',
              lineHeight: '1.25rem !important',
            }),
            option: (provided: any) => ({
              ...provided,
              padding: largeScreen
                ? '.8rem 2.5rem !important'
                : '.8rem 1.25rem !important',
              fontSize: '0.875rem !important',
              lineHeight: '1.25rem !important',
            }),
            singleValue: (provided: any) => ({
              ...provided,
              padding: largeScreen
                ? '.8rem 2.5rem !important'
                : '.8rem 1.25rem !important',
              fontSize: '0.875rem !important',
              lineHeight: '1.25rem !important',
            }),
          },
          value: address,
          onChange: (val: any) => {
            setAddress(val);
          },
        }}
      />
      {helperText && (
        <span
          className={`mt-1 text-xs ${
            error ? 'text-primary-dark' : 'text-jblood-black'
          }`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default LocationAutoComplete;
