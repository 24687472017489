import { FC, PropsWithChildren } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from '.';
import Button from '../Button';
import Heading from '../Heading';
import { SimpleModalProps } from './ModalProps';

const SimpleModal: FC<PropsWithChildren<SimpleModalProps>> = ({
  open,
  onClose,
  title,
  icon,
  children,
  onActionButtonClicked,
  showActionButton,
  actionButtonTitle,
  loading,
  className,
}) => {
  return (
    <Modal
      {...{
        open,
        onClose,
      }}
      className={`relative flex w-[90vw] max-w-[450px] flex-col items-center justify-center gap-3 rounded-lg p-10 ${
        className ? className : ''
      }`}
    >
      <MdClose
        size={20}
        className="absolute right-5 top-5 cursor-pointer"
        onClick={onClose}
      />

      {icon && icon}

      <Heading type="h3" className="!text-xl">
        {title}
      </Heading>
      <div className="text-center">{children}</div>

      {showActionButton && (
        <Button
          className="mx-auto"
          variant="gradient"
          onClick={() => {
            onActionButtonClicked && onActionButtonClicked();
          }}
          loading={loading}
        >
          {actionButtonTitle || 'Proceed'}
        </Button>
      )}
    </Modal>
  );
};

export default SimpleModal;
