import { Icon } from '@iconify/react';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NotificationContext } from '../../../contexts/notification';
import { RoleContext } from '../../../contexts/role';

import TabbarItem from './TabbarItem';

const Tabbar = () => {
  const { role } = useContext(RoleContext);
  const { notifications } = useContext(NotificationContext);
  const location = useLocation();

  return (
    <div
      className="sticky bottom-0 left-0 flex w-full items-center justify-between bg-white px-7 py-4 lg:hidden"
      style={{ boxShadow: '0 2px 20px rgba(0,0,0,0.15)' }}
    >
      <TabbarItem url="/app/dashboard" icon="ant-design:home-outlined" />

      {role === 'donor' ? (
        <TabbarItem url="/app/reminders" icon="charm:clock-alarm" />
      ) : (
        <TabbarItem url="/app/feedback" icon="jam-write" />
      )}

      {role === 'donor' ? (
        <Link to="/app/requests">
          <div
            className={`rounded-full p-3 ${
              location.pathname.startsWith('/app/requests')
                ? 'bg-primary-dark'
                : 'bg-gradient'
            }`}
          >
            <Icon
              icon="healthicons:blood-drop"
              className="text-xl text-white"
            />
          </div>
        </Link>
      ) : (
        <Link to="/app/your-requests">
          <div
            className={`rounded-full p-3 ${
              location.pathname.startsWith('/app/your-requests')
                ? 'bg-primary-dark'
                : 'bg-gradient'
            }`}
          >
            <Icon
              icon="healthicons:blood-drop"
              className="text-xl text-white"
            />
          </div>
        </Link>
      )}

      <div className="relative">
        <TabbarItem url="/app/notifications" icon="mi:notification" />
        {notifications.length > 0 && (
          <div className="absolute right-0 top-0 h-2 w-2 rounded-full bg-red-500" />
        )}
      </div>
      <TabbarItem url="/app/profile" icon="ic:outline-account-circle" />
    </div>
  );
};

export default Tabbar;
