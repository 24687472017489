// import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AcceptRequestModal from '../../../components/auth/Donor/DonationRequests/AcceptRequestModal';
import AddToCalendarModal from '../../../components/auth/Donor/DonationRequests/AddToCalendarModal';
import DonationRequestsList from '../../../components/auth/Donor/DonationRequests/DonationRequestList';
import Filter from '../../../components/auth/Donor/DonationRequests/Filter';
import RejectRequestModal from '../../../components/auth/Donor/DonationRequests/RejectRequestModal';
import RequestModal from '../../../components/auth/Donor/DonationRequests/RequestModal';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
import Pagination from '../../../components/lib/Pagination';
// import Text from '../../../components/lib/Text';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Request } from '../../../types/Request';
import { getRequest, patchRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import {
  ACCEPT_OR_DECLINE_REQUEST,
  GET_REQUESTS_FOR_DONOR,
} from '../../../utils/api/urls';
import { isEmpty } from '../../../utils/validators';

const initialCalendarReminders = {
  google: '',
  ics: '',
  outlook: '',
  yahoo: '',
};

const DonationRequests = () => {
  const [open, setOpen] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false);
  const [showFilter, setshowFilter] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [requests, setRequests] = useState<{ id: number; request: Request }[]>(
    []
  );
  const [calendarReminders, setCalendarReminders] = useState(
    initialCalendarReminders
  );
  const [acceptedRequest, setAcceptedRequest] = useState<Request | null>(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handler = useErrorHandler();
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    gender: '',
    blood_group: '',
  });

  const { isFetching } = useQuery(
    [queryKeys.getRequestsForDonor, page, filter.gender, filter.blood_group],
    () =>
      getRequest({
        url: GET_REQUESTS_FOR_DONOR(
          `?page=${page}&limit=20${
            isEmpty(filter.blood_group)
              ? ''
              : `&blood_group=${filter.blood_group}`
          }${isEmpty(filter.gender) ? '' : `&gender=${filter.gender}`}`
        ),
      }),
    {
      onSuccess(response) {
        setRequests(response?.data?.donations || []);
        setTotal(response?.data?.page_info?.total || 0);
        setCount(response?.data?.page_info?.total_pages || 0);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  const { mutate, isLoading: acceptIsLoading } = useMutation(patchRequest, {
    onSuccess(response) {
      setCalendarReminders(
        response?.data?.reminder || initialCalendarReminders
      );
      setOpen(false);
      setShowAcceptModal(true);
      setTimeout(() => {
        setShowAddToCalendarModal(true);
      }, 2000);
      queryClient.invalidateQueries(queryKeys.getRequestsForDonor);
    },
    onError(error) {
      handler(error);
    },
  });

  const toggleFilter = () => {
    setshowFilter((prevState) => !prevState);
  };

  const handleShowDetails = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleAcceptRequest = () => {
    setAcceptedRequest(requests[selectedIndex]?.request);

    setTimeout(() => {
      mutate({
        url: ACCEPT_OR_DECLINE_REQUEST({
          id: requests[selectedIndex]?.request?.id,
        }),
        data: {
          status: 'accepted',
        },
      });
    }, 500);
  };

  const handleRejectRequest = () => {
    setOpen(false);
    setShowRejectModal(true);
  };

  return (
    <PageTitle
      title={showFilter ? 'Filter Requests' : 'Blood Requests'}
      // leftComponent={
      //   <button onClick={toggleFilter}>
      //     {showFilter ? (
      //       <Text type="caption" className="text-xs">
      //         Close
      //       </Text>
      //     ) : (
      //       <Icon icon="mi:filter" className="text-xl" />
      //     )}
      //   </button>
      // }
    >
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card
          title="Blood Donation Requests"
          count={total}
          mobile={smallScreen}
          // titleComponent={
          //   <button
          //     className="flex cursor-pointer items-center gap-2 text-jblood-gray"
          //     onClick={toggleFilter}
          //   >
          //     {showFilter ? (
          //       <Text type="caption">Back</Text>
          //     ) : (
          //       <>
          //         <Icon icon="mi:filter" />
          //         <Text type="caption">Filter</Text>
          //       </>
          //     )}
          //   </button>
          // }
        >
          {showFilter ? (
            <Filter {...{ filter, setFilter, toggleFilter }} />
          ) : (
            <>
              <Pagination {...{ page, setPage, count }} />
              <DonationRequestsList
                {...{
                  setSelectedIndex: handleShowDetails,
                  requests,
                  isLoading: isFetching,
                }}
              />
            </>
          )}
        </Card>
      </div>

      <RequestModal
        {...{
          open,
          onClose: () => setOpen(false),
          selectedIndex,
          setSelectedIndex,
          requests,
          onAccept: handleAcceptRequest,
          acceptIsLoading,
          onReject: handleRejectRequest,
        }}
      />

      <AcceptRequestModal
        {...{
          open: showAcceptModal,
          onClose: () => setShowAcceptModal(false),
          selectedIndex,
          request: acceptedRequest,
        }}
      />

      <RejectRequestModal
        {...{
          open: showRejectModal,
          onClose: () => {
            setOpen(true);
            setShowRejectModal(false);
          },
          selectedIndex,
          requests,
          onSubmit: () => setShowRejectModal(false),
        }}
      />

      <AddToCalendarModal
        {...{
          open: showAddToCalendarModal,
          onClose: () => setShowAddToCalendarModal(false),
          calendarReminders,
        }}
      />
    </PageTitle>
  );
};

export default DonationRequests;
