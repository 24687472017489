import { FC, useContext, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlineNotificationsNone,
} from 'react-icons/md';
import { usePopper } from 'react-popper';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Avatar from '../../lib/Avatar';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import NavbarProps from './NavbarProps';
import NavLinks from './NavLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContext } from '../../../contexts/page';
import { RoleContext } from '../../../contexts/role';
import { UserContext } from '../../../contexts/user';
import { NotificationContext } from '../../../contexts/notification';
import Notifications from './Notifications';
import { greetUser } from '../../../utils/misc';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { useQuery } from 'react-query';
import queryKeys from '../../../utils/api/queryKeys';
import { getRequest } from '../../../utils/api/calls';
import { GET_DONATATION_STATS } from '../../../utils/api/urls';
import LifeSaver from '../../../assets/images/LifeSaver.png';
import Medal from '../../../assets/images/Medal.png';
import Zeal from '../../../assets/images/Zeal.png';

const Navbar: FC<NavbarProps> = ({ toggleSideNav, toggleActionSheet }) => {
  const { user } = useContext(UserContext);
  const { title, leftComponent } = useContext(PageContext);
  const { role } = useContext(RoleContext);
  const { notifications } = useContext(NotificationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const largeScreen = useMediaQuery('(min-width: 1024px)');
  const [open, setOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const handler = useErrorHandler();

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow' }],
    placement: 'bottom-end',
  });
  const isDashboardRoute = location.pathname === '/app/dashboard';
  const name = `${user?.first_name || ''} ${user?.last_name || ''}`;

  useQuery(
    [queryKeys.getdonationStats],
    () => getRequest({ url: GET_DONATATION_STATS }),
    {
      onSuccess(response) {
        setCount(response?.data?.confirmed_donation?.length || 0);
        setAttempts(response?.data?.attempted_donation?.count || 0);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const onNotificationClicked = () => {
    setOpen(false);
    setNotificationsOpen((prevState) => !prevState);
  };

  const onProfileClicked = () => {
    setNotificationsOpen(false);
    setOpen((prevState) => !prevState);
  };

  return (
    <nav
      className={`flex w-full items-center justify-between border-b-jblood-gray/50 p-5 ${
        isDashboardRoute ? 'border-0 pb-0' : 'border-b'
      } lg:border-none lg:px-5 lg:pt-0 lg:pb-5`}
    >
      {largeScreen ? (
        <>
          <div className="flex items-center gap-2">
            <div className="flex flex-col md:gap-1">
              <div className="flex items-center gap-3">
                <Text className="text-gray-500" type="caption">
                  {greetUser(name.split(' ')[0])}
                </Text>

                {(count > 0 || attempts > 0) && (
                  <figure className="flex h-7 w-7 items-center justify-center ">
                    <img
                      src={
                        count > 2
                          ? Medal
                          : count === 1
                          ? LifeSaver
                          : attempts > 0
                          ? Zeal
                          : ''
                      }
                      alt={
                        count > 2
                          ? 'Medal'
                          : count === 1
                          ? 'LifeSaver'
                          : attempts > 0
                          ? 'Zeal'
                          : ''
                      }
                      className="h-full w-full object-cover"
                    />
                  </figure>
                )}
              </div>

              <Heading type="h3">
                {role === 'donor'
                  ? 'Another day to save a life!'
                  : largeScreen
                  ? 'We’re here for you. We can do this together!'
                  : 'We’re here for you!'}
              </Heading>
            </div>
          </div>

          <div className="hidden items-center gap-2 lg:flex">
            <div
              className="relative flex h-12 w-12 items-center justify-center rounded-full border border-gray-500"
              onClick={onNotificationClicked}
            >
              <MdOutlineNotificationsNone className="text-2xl font-bold text-gray-500" />
              {notifications.length > 0 && (
                <div className="absolute top-[25%] right-[25%] h-3 w-3 rounded-full bg-red-500" />
              )}
            </div>

            <div
              className="flex cursor-pointer items-center"
              onClick={onProfileClicked}
              ref={setReferenceElement}
            >
              <Avatar className="!h-12 !w-12" name={name} image={user?.image} />
              <MdKeyboardArrowDown />
            </div>

            <Notifications
              {...{
                attributes,
                open: notificationsOpen,
                setOpen: setNotificationsOpen,
                setPopperElement,
                styles,
              }}
            />

            <NavLinks
              {...{ attributes, open, setOpen, setPopperElement, styles }}
            />
          </div>
        </>
      ) : (
        <>
          {isDashboardRoute ? (
            <>
              <div className="flex items-center gap-2">
                <div
                  className="cursor-pointer select-none"
                  role="button"
                  onClick={toggleActionSheet}
                >
                  <Avatar
                    className="!h-10 !w-10 lg:hidden"
                    name={name}
                    image={user?.image}
                  />
                </div>
                <div className="flex flex-col md:gap-1">
                  <div className="flex items-center gap-3">
                    <Text className="text-gray-500" type="caption">
                      {greetUser(name.split(' ')[0])}
                    </Text>

                    {(count > 0 || attempts > 0) && (
                      <figure className="flex h-7 w-7 items-center justify-center ">
                        <img
                          src={
                            count > 2
                              ? Medal
                              : count === 1
                              ? LifeSaver
                              : attempts > 0
                              ? Zeal
                              : ''
                          }
                          alt={
                            count > 2
                              ? 'Medal'
                              : count === 1
                              ? 'LifeSaver'
                              : attempts > 0
                              ? 'Zeal'
                              : ''
                          }
                          className="h-full w-full object-cover"
                        />
                      </figure>
                    )}
                  </div>

                  <Heading type="h3">We’re here for you!</Heading>
                </div>
              </div>

              <AiOutlineMenu
                className="lg:hidden"
                size={25}
                onClick={toggleSideNav}
              />
            </>
          ) : (
            <>
              <MdOutlineKeyboardArrowLeft
                size={30}
                onClick={() => navigate(-1)}
              />

              <Heading type="h3">{title}</Heading>

              {leftComponent ? leftComponent : <div className="w-[30px]" />}
            </>
          )}
        </>
      )}
    </nav>
  );
};

export default Navbar;
