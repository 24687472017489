import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Backdrop from '../Backdrop';
import ModalProps from './ModalProps';

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  onClose,
  className,
  children,
}) => {
  const [backdropIsOpen, setBackdropIsOpen] = useState(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    if (open) {
      // Prevent scolling
      document.body.style.overflowY = 'hidden';
      document.addEventListener('keydown', onKeyDown);
    }

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflowY = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setBackdropIsOpen(false);
      }, 300);
    } else {
      setBackdropIsOpen(true);
    }
  }, [open]);

  return (
    <Backdrop
      {...{
        open: backdropIsOpen,
        onClose,
      }}
    >
      <div className={`scale-up-center bg-white ${className ? className : ''}`}>
        {children}
      </div>
    </Backdrop>
  );
};

export default Modal;
