import { Icon } from '@iconify/react';
import { FC, FormEvent, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import toaster, { toast } from 'react-hot-toast';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { State } from '../../../types/State';
import { getRequest, patchRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import { GET_STATES, UPDATE_USER } from '../../../utils/api/urls';
import { genders, bloodGroups } from '../../../utils/constants';
import { getAge } from '../../../utils/misc';
import {
  isEmpty,
  validateUpdateProfileInputs,
} from '../../../utils/validators';
import Input from '../../form/Input';
import Select from '../../form/Select';
import Button from '../../lib/Button';
import SimpleModal from '../../lib/Modal/SimpleModal';
import Detail from './Detail';
import { ProfileProps } from './EditProfileProps';
import Text from '../../lib/Text';
import LocationAutoComplete from '../../lib/LocationAutoComplete';
import { LocationData } from '../../lib/LocationAutoComplete/LocationAutoCompleteProps';

const initialState = {
  gender: '',
  dob: '',
  blood_group: '',
  state_id: '',
  longitude: '',
  latitude: '',
};

const Profile: FC<ProfileProps> = ({ user }) => {
  const color = 'text-primary-main';
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [states, setStates] = useState<State[]>([]);
  const handler = useErrorHandler();
  const queryClient = useQueryClient();
  const [payload, setPayload] = useState({
    gender: user?.gender || '',
    dob: user?.dob || '',
    blood_group: user?.blood_group || '',
    state_id: String(user?.state_id || ''),
    longitude: String(user?.longitude),
    latitude: String(user?.latitude),
    address: '',
  });
  const [errors, setErrors] = useState({ ...initialState, location: '' });

  useQuery([queryKeys.getStates], () => getRequest({ url: GET_STATES }), {
    onSuccess(response) {
      setStates(response?.data?.states || []);
    },
    onError(error: any) {
      handler(error);
    },
  });

  const { mutate, isLoading } = useMutation(patchRequest, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.getUser);
      setEditMode(false);
      toaster.success('Vitals updated successfully');
    },
    onError(error) {
      handler(error);
    },
  });

  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
  };

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleLocationChange = (location: LocationData) => {
    const { geo, state, country, fullAddress } = location;

    if (country !== 'Nigeria') {
      toast.error('You can only select a location in Nigeria.');
      setErrors({
        ...errors,
        location: 'Location must be in Nigeria',
      });
      return;
    }

    const state_id = states.find((item) => item.name === state)?.id;

    setPayload({
      ...payload,
      address: fullAddress || '',
      state_id: String(state_id || ''),
      longitude: String(geo.longitude),
      latitude: String(geo.latitude),
    });

    setErrors({
      ...errors,
      location: '',
    });
  };

  const handleSubmit = () => {
    setErrors({ ...initialState, location: '' });

    const { valid, errors: validationErrors } =
      validateUpdateProfileInputs(payload);

    if (valid) {
      mutate({ url: UPDATE_USER, data: payload });
    } else {
      setErrors({
        ...validationErrors,
        longitude: '',
        latitude: '',
        location: '',
      });
    }
  };

  return (
    <>
      <div className="relative flex w-full items-center gap-5 overflow-hidden rounded-lg border border-primary-main bg-[#FCF9F8] p-5 md:p-10">
        <div className="absolute top-0 left-0 h-full w-2 bg-primary-light" />

        <div className="flex flex-1 flex-col gap-3 md:gap-5">
          <div className="flex w-full items-center justify-between">
            {editMode ? (
              <div />
            ) : (
              <Detail
                icon={
                  user?.gender === 'Male'
                    ? 'carbon:gender-male'
                    : 'carbon:gender-female'
                }
                color={color}
                title="Gender"
                value={user?.gender || ''}
              />
            )}

            <Icon
              icon={editMode ? 'iconoir:cancel' : 'eva:edit-outline'}
              className="cursor-pointer text-2xl text-jblood-gray hover:text-primary-main"
              onClick={toggleEditMode}
            />
          </div>

          {editMode ? (
            <>
              <Select
                label="Gender"
                placeholder="Enter your full name"
                name="gender"
                value={payload.gender}
                options={[{ key: 'Select Gender', value: '' }, ...genders]}
                onChange={handleChange}
                error={!isEmpty(errors.gender)}
                helperText={errors.gender}
              />
              <Input
                label="Date of Birth"
                type="date"
                placeholder=""
                name="dob"
                value={payload.dob}
                onChange={handleChange}
                error={!isEmpty(errors.dob)}
                helperText={errors.dob}
              />
              <Select
                label="Blood Group"
                placeholder="Select Blood Group"
                name="blood_group"
                value={payload.blood_group}
                options={[
                  { key: 'Select Blood Group', value: '' },
                  ...bloodGroups,
                ]}
                onChange={handleChange}
                error={!isEmpty(errors.blood_group)}
                helperText={errors.blood_group}
              />
              <LocationAutoComplete
                label="Search and Enter Near address"
                placeholder="Search for addres or landmark"
                error={
                  !isEmpty(errors.latitude) ||
                  !isEmpty(errors.longitude) ||
                  !isEmpty(errors.location)
                }
                helperText={
                  !isEmpty(errors.location)
                    ? errors.location
                    : !isEmpty(errors.latitude) || !isEmpty(errors.longitude)
                    ? 'Location must not be empty'
                    : ''
                }
                onChange={handleLocationChange}
                initialLocation={{
                  lat: Number(user?.latitude),
                  lng: Number(user?.longitude),
                }}
              />
              <Input
                label="Email"
                type="email"
                value={user?.email || ''}
                disabled
              />
            </>
          ) : (
            <>
              <Detail
                icon="ant-design:calendar-outlined"
                color={color}
                title="Age"
                value={user?.dob ? getAge(user.dob) : ''}
              />

              <Detail
                icon="healthicons:blood-drop-outline"
                color={color}
                title="Blood Group"
                value={user.blood_group || ''}
              />

              <Detail
                icon="cil:location-pin"
                color={color}
                title="Location"
                value={user?.address || ''}
              />

              <Detail
                icon="ic:outline-email"
                color={color}
                title="Email"
                value={user?.email || ''}
              />
            </>
          )}

          {editMode && (
            <div className="mt-5 flex w-full items-center justify-center">
              <Button size="medium" onClick={handleSubmit} loading={isLoading}>
                Update
              </Button>
            </div>
          )}
        </div>
      </div>

      <SimpleModal
        open={open}
        onClose={toggleModal}
        icon={
          <Icon
            icon="ant-design:warning-filled"
            className="text-2xl text-primary-dark"
          />
        }
        title="Warning"
      >
        <Text>You can only edit your Blood Group and Genotype once.</Text>
      </SimpleModal>
    </>
  );
};

export default Profile;
