import { useState } from 'react';
import Achievements from '../../../components/auth/Donor/Dashboard/Achievements';
import BloodDonationRequests from '../../../components/auth/Donor/Dashboard/BloodDonationRequests';
import DataCard from '../../../components/auth/Donor/Dashboard/DataCard';
import DonationHistory from '../../../components/auth/Donor/Dashboard/DonationHistory';
import Reminders from '../../../components/auth/Donor/Dashboard/Reminders';
import Button from '../../../components/lib/Button';
import Heading from '../../../components/lib/Heading';
import InfoModal from '../../../components/lib/Modal/InfoModal';
import Text from '../../../components/lib/Text';
import useMediaQuery from '../../../hooks/useMediaQuery';

const Dashboard = () => {
  const largeScreen = useMediaQuery('(min-width: 1024px)');
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <div className="masonry-1-col md:masonry-2-col responsive-masonry">
      {!largeScreen && <DataCard />}
      <BloodDonationRequests />
      <DonationHistory />
      <Reminders />
      <Achievements />

      <InfoModal onClose={() => null} open={open} title="Warning!">
        <div className="flex w-full flex-col items-center gap-5 lg:items-start">
          <Heading type="h3" className="!text-xl lg:hidden">
            Warning!
          </Heading>
          <Text className="hidden !font-interBold lg:block">
            Rejected Requests
          </Text>
          <Text className="text-center lg:text-left lg:!leading-8">
            You have been declining all blood donation requests for up 30 days.{' '}
            <br />
            Your account may be suspended for a month if you continue declining
            all requests.
          </Text>
          <Button
            size={largeScreen ? 'small' : 'large'}
            variant={largeScreen ? 'solid' : 'gradient'}
            onClick={toggleModal}
            className="mx-auto"
          >
            Okay
          </Button>
        </div>
      </InfoModal>
    </div>
  );
};

export default Dashboard;
