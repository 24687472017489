import moment from 'moment';
import { FC, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import Heading from '../../../lib/Heading';
import Text from '../../../lib/Text';
import ArticleCardProps from './ArticleCardProps';
import DefaultBlogImage from '../../../../assets/images/DefaultBlogImage.png';

const ArticleCard: FC<PropsWithChildren<ArticleCardProps>> = ({
  id,
  title,
  content,
  created_on,
  image,
}) => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <Link
      to={`/articles/${id}`}
      className="flex w-full flex-col items-center justify-between gap-5 lg:flex-row lg:gap-10"
    >
      <figure
        className={`aspect-[10/8] max-w-[500px] overflow-hidden md:aspect-[10/6] ${
          isAuthScreen ? 'lg:aspect-[10/8] lg:flex-1' : 'lg:w-[40vw]'
        }`}
      >
        <img
          src={image || DefaultBlogImage}
          alt={title}
          className="h-full w-full object-cover"
        />
      </figure>

      <div
        className={`flex flex-1 flex-col items-start gap-5 text-left md:text-left ${
          isAuthScreen ? 'lg:flex-1' : 'lg:w-[40vw]'
        }`}
      >
        <Heading type="h2">{title}</Heading>
        <div className="blog-content max-h-[150px] overflow-hidden">
          {parse(content)}
        </div>
        <Text type="body2" className="font-soraRegular text-jblood-gray">
          {moment(created_on).format('MMMM, DD YYYY')}
        </Text>
      </div>
    </Link>
  );
};

export default ArticleCard;
