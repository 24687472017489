import { FC, FormEvent, useEffect, useState } from 'react';
import { months } from '../../../../utils/constants';
import { isEmpty, validateForm4Inputs } from '../../../../utils/validators';
import Checkbox from '../../../form/Checkbox';
import Input from '../../../form/Input';
import Select from '../../../form/Select';
import Button from '../../../lib/Button';
import Heading from '../../../lib/Heading';
import Text from '../../../lib/Text';
import { FormProps } from './FormsProps';

const initialState: {
  month: string;
  year: string;
} = {
  month: '',
  year: '',
};

const Form4: FC<FormProps> = ({
  payload: generalPayload,
  setPayload: setGeneralPayload,
  handleSubmitForm,
  isLoading,
}) => {
  const [payload, setPayload] = useState(initialState);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState(initialState);

  useEffect(() => {
    if (checked) {
      setPayload({
        month: '',
        year: '',
      });
    }
  }, [checked]);

  useEffect(() => {
    setGeneralPayload({
      ...generalPayload,
      last_donation_date:
        isEmpty(payload.year) || isEmpty(payload.month)
          ? ''
          : `${payload.year}-${payload.month}-01`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload.year, payload.month]);

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    if (!checked) {
      const { valid, errors: validationErrors } = validateForm4Inputs(payload);

      if (!valid) {
        setErrors(validationErrors);
        return;
      }
    }

    handleSubmitForm && handleSubmitForm();
  };

  return (
    <>
      <Heading type="h3">When was your last blood donation?</Heading>
      <Text>
        If you recently donated blood. This will exclude you from receiving
        blood donation requests for an interval of 3-4 months, after which new
        requests may be sent.
      </Text>

      {!checked && (
        <>
          <Select
            label="Month"
            options={[{ key: 'Select Month', value: '' }, ...months]}
            name="month"
            value={payload.month}
            onChange={handleChange}
            error={!isEmpty(errors.month)}
            helperText={errors.month}
          />
          <Input
            label="Year"
            type="number"
            name="year"
            placeholder="Enter year"
            value={payload.year || ''}
            onChange={handleChange}
            error={!isEmpty(errors.year)}
            helperText={errors.year}
            min={1900}
            max={new Date().getFullYear()}
          />
        </>
      )}

      <Checkbox
        checked={checked}
        onChange={() => setChecked((prevState) => !prevState)}
        label="Never donated blood? Or can’t remember the last time you donated?"
      />

      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full max-w-[500px]"
        onClick={handleSubmit}
        disabled={isLoading}
        loading={isLoading}
      >
        Proceed
      </Button>
    </>
  );
};

export default Form4;
