const queryKeys = {
  getUser: 'getUser',
  getStates: 'getStates',
  getRecipientRequests: 'getRecipientRequests',
  getHistory: 'getHistory',
  getReminders: 'getReminders',
  getDeclineReasons: 'getDeclineReasons',
  getRequestReasons: 'getRequestReasons',
  getRequestsForDonor: 'getRequestsForDonor',
  getDonorRequestHistory: 'getDonorRequestHistory',
  getFeedback: 'getFeedback',
  getdonationStats: 'getdonationStats',
  getArticles: 'getArticles',
  getArticle: 'getArticle',
  getEducationLevels: 'getEducationLevels',
  getFeedbackStatus: 'getFeedbackStatus',
};

export default queryKeys;
