import { FC } from 'react';
import { Icon } from '@iconify/react';
import { TabbarItemProps } from './TabbarProps';
import { Link, useLocation } from 'react-router-dom';

const TabbarItem: FC<TabbarItemProps> = ({ icon, url }) => {
  const location = useLocation();

  return (
    <Link to={url}>
      <Icon
        icon={icon}
        className={`text-2xl text-jblood-gray ${
          location.pathname.startsWith(url) ? 'text-primary-main' : ''
        }`}
      />
    </Link>
  );
};

export default TabbarItem;
