import toaster from 'react-hot-toast';
import PageTitle from '../../components/containers/PageTitle';
import PartnerImage1 from '../../assets/images/PartnerImage1.jpg';
import PartnerImage from '../../assets/images/Partner.jpg';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/TextArea';
import Button from '../../components/lib/Button';
import Radio from '../../components/form/Radio';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, FormEvent } from 'react';
import { useMutation } from 'react-query';
import useErrorHandler from '../../hooks/useErrorHandler';
import { postRequest } from '../../utils/api/calls';
import { CREATE_PARTNERSHIP } from '../../utils/api/urls';
import { isEmpty, validatePartnershipInput } from '../../utils/validators';
import Select from '../../components/form/Select';

const partnershipOptions = [
  'Media Slots',
  'Branded Items',
  'Hotel accommodation/hall services',
  'Other',
];

const initialState = {
  name: '',
  email: '',
  description: '',
  is_organization: '',
};

const Partner = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');
  const [payload, setPayload] = useState({
    ...initialState,
    is_organization: false,
  });
  const [otherDescription, setOtherDescription] = useState('');
  const [otherDescriptionError, setOtherDescriptionError] = useState('');
  const [errors, setErrors] = useState(initialState);
  const handler = useErrorHandler();
  const navigate = useNavigate();

  const handleChange = (
    event:
      | FormEvent<HTMLInputElement>
      | FormEvent<HTMLTextAreaElement>
      | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleRadioChange = () => {
    setPayload((prevState) => ({
      ...payload,
      is_organization: !prevState.is_organization,
    }));
  };

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      toaster.success('Successful!');
      navigate('/');
    },
    onError(error) {
      handler(error);
    },
  });

  const handleSubmit = () => {
    setErrors(initialState);
    const { valid, errors: validationErrors } =
      validatePartnershipInput(payload);

    if (valid) {
      setOtherDescriptionError('');

      if (payload.description === 'Other' && isEmpty(otherDescription)) {
        setOtherDescriptionError('Description must not be empty');
        return;
      }

      mutate({
        url: CREATE_PARTNERSHIP,
        data: { ...payload },
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <PageTitle title="Contact Us">
      <div
        className={`max mx-auto flex flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          Partner with us
        </Heading>

        <figure className="aspect-[10/9] max-w-[450px] overflow-hidden lg:hidden">
          <img
            src={PartnerImage1}
            alt=""
            className="h-full w-full object-cover"
          />
        </figure>

        <div className="flex w-full flex-col gap-5 lg:p-5">
          <Text>
            To increase our publicity efforts about J Blood Match and continue
            our advocacy for voluntary blood donation, we require all hands-on
            deck to partner with us in various aspects.
          </Text>

          <Text>Key areas for collaboration are:</Text>

          <ul className="ml-5 list-disc">
            <li>
              <Text>
                Provision free live appearance/ public service announcement
                slots on broadcast media and newspapers (electronic and print)
                to publicize J Blood Match
              </Text>
            </li>

            <li>
              <Text>
                Branded items such as shirts, tote bags etc. to be given to J
                Blood Match advocates (note to developer: this will link to the
                JBM Advocacy page)
              </Text>
            </li>

            <li>
              <Text>
                Hotel accommodation and hall services for training of J Blood
                Match advocates
              </Text>
            </li>
          </ul>
        </div>

        <hr className="w-full bg-[#8B888780]" />

        <div className="flex w-full items-center justify-center gap-10 lg:justify-between">
          <div className="flex flex-col gap-3 lg:gap-5">
            <div className="flex flex-col gap-2">
              <Text>
                Don’t see something you can collaborate with us on? We are also
                happy to hear what you would like to offer!
              </Text>

              <Text>
                Send us a message now if you will like to partner with us.
              </Text>
            </div>

            <Input
              label="Full Name"
              placeholder="Enter your full name"
              name="name"
              value={payload.name}
              onChange={handleChange}
              error={!isEmpty(errors.name)}
              helperText={errors.name}
            />
            <Input
              label="Email Address"
              type="email"
              placeholder="example@domain.com"
              name="email"
              value={payload.email}
              onChange={handleChange}
              error={!isEmpty(errors.email)}
              helperText={errors.email}
            />

            <div className="mt-3 flex items-center gap-5">
              <span className="font-interSemibold text-sm md:text-base">
                Are you an organization?
              </span>

              <Radio
                label="Yes"
                checked={payload.is_organization}
                onChange={handleRadioChange}
              />
              <Radio
                label="No"
                checked={!payload.is_organization}
                onChange={handleRadioChange}
              />
            </div>

            <Select
              label="How would you like to partner with us?"
              placeholder="Enter description"
              name="description"
              value={payload.description}
              onChange={handleChange}
              error={!isEmpty(errors.description)}
              helperText={errors.description}
              options={[
                { key: 'Select Item', value: '' },
                ...partnershipOptions.map((item) => ({
                  key: item,
                  value: item,
                })),
              ]}
            />

            {payload.description === 'Other' && (
              <TextArea
                label="In what other way would you like to partner with us?"
                placeholder="Enter description"
                value={otherDescription}
                onChange={(event) =>
                  setOtherDescription(event.currentTarget.value)
                }
                error={!isEmpty(otherDescriptionError)}
                helperText={otherDescriptionError}
              />
            )}

            <div className="mt-5 flex w-full justify-center">
              <Button size="medium" loading={isLoading} onClick={handleSubmit}>
                Send
              </Button>
            </div>
          </div>

          <figure className="hidden aspect-[6/10] w-[30vw] max-w-[400px] lg:block">
            <img
              src={PartnerImage}
              alt=""
              className="h-full w-full object-cover"
            />
          </figure>
        </div>
      </div>
    </PageTitle>
  );
};

export default Partner;
