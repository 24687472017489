import { useLocation } from 'react-router-dom';
import PageTitle from '../../components/containers/PageTitle';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';

const TermsOfUse = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="Terms of use">
      <div
        className={`max mx-auto flex flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          Terms Of Use
        </Heading>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Things to note for donor</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                Get plenty of sleep the night before you plan to donate. Make
                sure to eat a healthy meal before your donation, Avoid fatty
                foods, such as a hamburger, fries or ice cream. Drink plenty of
                water before the donation.
              </Text>
            </li>
            <li>
              <Text>
                Please seek content from recipient before sharing any personal
                information about them (including their name, diagnosis or
                pictures).
              </Text>
            </li>
            <li>
              <Text>
                Although, you will undergo a general health checkup and some
                tests at the hospital to determine your eligibility for blood
                donation, some common factors that make you ineligible for
                donation includes pregnancy, fever, low blood pressure, high
                blood pressure, recent exposure to malaria and so on.
              </Text>
            </li>
            <li>
              <Text>No financial reward to be given to donors.</Text>
            </li>
            <li>
              <Text>
                Recipient should be responsible for the transportation of the
                donor.
              </Text>
            </li>
            <li>
              <Text>Commercial blood donation is strictly prohibited.</Text>
            </li>
            <li>
              <Text>
                Do not consent to blood donation that you do not willfully
                accept on J Blood Match as this puts you at risk.
              </Text>
            </li>
            <li>
              <Text>
                Only meet in the hospital sent to you by J Blood Match.
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Things to note for recipient</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                An area on your arm will cleansed and a brand–new sterile needle
                will be inserted for the blood draw. This feels like a quick
                pinch and is
              </Text>
            </li>
            <li>
              <Text>
                You will have some time to relax while the bag is filling. (For
                a whole blood donation, it is about 8-10 minutes)
              </Text>
            </li>
            <li>
              <Text>
                When approximately a pint of blood has been collected, the
                donation is complete and a health personnel will place a bandage
                on
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Refreshments</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                You will spend a few minutes consuming some fluids to allow your
                body adjust to the slight decrease in fluid volume.
              </Text>
            </li>
            <li>
              <Text>
                After 10-15 minutes you can then leave the donation site and
                continue with your normal daily activities.
              </Text>
            </li>
            <li>
              <Text>
                Enjoy the feeling of accomplishment knowing that you have helped
                save lives.
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </PageTitle>
  );
};

export default TermsOfUse;
