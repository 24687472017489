import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import PageTitle from '../../components/containers/PageTitle';
import { useLocation } from 'react-router-dom';

const About = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="About JBM">
      <div
        className={`max mx-auto flex flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          Pioneer’s story
        </Heading>

        <div className="mb-10 flex flex-col gap-5 lg:mb-5 lg:gap-7 lg:p-5">
          <Heading type="h3">Her Background</Heading>
          <Text>
            Ochu-Baiye is a high-profile advocate. Ochu-Baiye earned degrees
            from Queen Mary University of London and the University of
            Westminster, London, and began her career as a lawyer — but was
            drawn back to her childhood dream of becoming a talk show host.
            Since 2016, she has hosted The Conversation, a daily talk show that
            addresses all manner of social issues. In late 2016, she launched
            her flagship show, Jela’s Clinic.
          </Text>
          <Text>
            The issue that kept popping up was blood. There were complaints,
            questions, accusations and misconceptions: Listeners worried about
            everything from donated blood being used for ritual purposes or sold
            at exhorbitant rates in hospitals to fears about fainting while
            giving or acquiring a disease from contaminated blood.
          </Text>
          <Text>
            After doing some research, she brought in experts to raise
            awareness, debunk myths and take questions from listeners twice
            weekly on her show. Her four experts — a medical doctor, life coach,
            psychologist and lawyer — respond to listeners with a slogan: The
            shortest distance to your answers is asking.
          </Text>
          <Text>
            Ochu-Baiye has also hosted several blood drives in conjunction with
            the NBTS, WE FM and other organizations, which have netted around
            200 units of blood.
          </Text>
        </div>

        <div className="mb-10 flex flex-col gap-5 lg:mb-5 lg:gap-7 lg:p-5">
          <Heading type="h3">The Problem</Heading>
          <Text>
            Nigeria’s blood shortage nearly cost Angela Ochu-Baiye her life.
            Diagnosed with a ruptured ovarian cyst and an ectopic pregnancy in
            2008, she was scheduled for an emergency surgery. She urgently
            needed a blood transfusion, but her hospital had just one unit — and
            it had expired. She couldn’t find a donor match within close
            proximity. For six days, she managed to survive as the doctors
            wielded her condition.
          </Text>
          <Text>
            “Many women were not fortunate like me, but I believe God spared me
            so I could champion a great cause to ensure other women wouldn’t
            lose their lives from an unavailability of blood in emergency
            situations,” she says.
          </Text>
        </div>

        <div className="mx-auto mb-10 flex w-full max-w-[850px] flex-col items-center gap-1 rounded-3xl bg-primary-dark p-10 text-center text-white lg:mb-7 lg:gap-2 lg:p-16">
          <Heading type="h3">
            “If we have adequate blood at the right time and delivered at the
            right place, we will save a significant number of Nigerians from
            dying”
          </Heading>
          <Heading type="h3" className="mt-7">
            Dr. Omo Izedonmwem
          </Heading>
          <Text type="body2">Former Coordinator NBTS, Abuja center.</Text>
        </div>

        <div className="mb-10 flex flex-col gap-5 lg:mb-5 lg:gap-7 lg:p-5">
          <Text>
            In 2014, Nigeria’s National Blood Transfusion Service (NBTS)
            recorded 1.7 million units of blood needed … and just 49,908 units
            voluntarily donated. The country has a high maternal mortality rate
            of 917 deaths per 100,000 live births; the leading cause of those
            deaths is postpartum hemorrhage. And then there are the thousands
            who bleed out annually after traffic accidents on the nation’s
            notoriously poor roads. “If we have adequate blood at the right time
            and delivered at the right place, you will save a significant number
            of Nigerians from dying,” says Dr. Omo Izedonmwen, coordinator of
            the NBTS Abuja center.
          </Text>
        </div>

        <div className="mb-10 flex flex-col gap-5 lg:mb-5 lg:gap-7 lg:p-5">
          <Heading type="h3">The solution</Heading>
          <Text>
            To expand her reach, Angela Ochu-Baiye turned to technology. An AI
            blood match service (J Blood Match) was launched by her NGO, Jela’s
            Development Initiatives. It took 18 months for Ochu-Baiye and her
            team to execute test runs on J Blood Match. Within a week of its
            launch, it registered 106 donors nationwide.To increase the number
            of donors, she’s using her broadcasts and social media campaigns to
            spread the word.
          </Text>
          <Text>
            J Blood Match which is now transforming into a full fledged web app
            started as a bot on Facebook and the instant messaging app Telegram
            which allows people to register either as donors or advocates,
            indicating their location, nearest hospital, gender, age and blood
            type. In the event that someone on the platform needs blood, the
            system identifies donors based on location and blood type and
            notifies available matches via Telegram and Facebook, asking whether
            they agree or decline to give.
          </Text>
          <Text>
            The work is starting to bring international recognition. This year,
            Ochu-Baiye was tapped as a Mandela Washington Fellow by the U.S.
            State Department, and she has been listed among the 100 most
            inspiring women in Nigeria by The Guardian.
          </Text>
          <Text>
            Her goal moving forward is to increase the blood donor pool in
            Nigeria from less than 1 percent of the population to 3 percent
            within the next decade and to make sure the whole country knows they
            can save a life via their smartphone.
          </Text>
        </div>
      </div>
    </PageTitle>
  );
};

export default About;
