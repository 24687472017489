import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="flex w-full items-center justify-center bg-primary-main p-3 text-xxs text-white sm:text-xs md:p-5 md:text-base">
      <div className="max flex w-full flex-col items-center justify-between gap-5 md:flex-row">
        <span>JBloodMatch &copy; {new Date().getFullYear()}</span>

        <div className="flex justify-between gap-5 lg:gap-10">
          <Link to="/terms-of-use">
            <span>Terms of Use</span>
          </Link>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
          <Link to="/faqs">
            <span>FAQs</span>
          </Link>
          <Link to="/donate">
            <span>Donate</span>
          </Link>
          <Link to={'/chart'}>
            <span>Blood match chart</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
