import { Outlet } from 'react-router-dom';
import Navbar from '../../components/app/Navbar';
import Footer from '../../components/app/Footer';

const Applayout = () => {
  return (
    <>
      <Navbar />
      <main className="flex-1">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Applayout;
