import { Icon } from '@iconify/react';
import { FC } from 'react';
import Text from '../../lib/Text';
import { DetailProps } from './EditProfileProps';

const Detail: FC<DetailProps> = ({ color, icon, title, value }) => {
  return (
    <div className="flex items-center gap-2">
      <Icon icon={icon} className={`text-xl md:text-2xl ${color}`} />

      <Text>
        {title}: <span className="font-interBold">{value}</span>
      </Text>
    </div>
  );
};

export default Detail;
