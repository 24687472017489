import { FC, PropsWithChildren, ReactNode, useContext, useEffect } from 'react';
import { PageContext } from '../../contexts/page';

const PageTitle: FC<
  PropsWithChildren<{ title: string; leftComponent?: ReactNode }>
> = ({ title, children, leftComponent }) => {
  const { setTitle, setLeftComponent } = useContext(PageContext);

  useEffect(() => {
    setTitle(title);

    if (leftComponent) {
      setLeftComponent(leftComponent);
    } else {
      setLeftComponent(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, leftComponent]);

  return <>{children}</>;
};

export default PageTitle;
