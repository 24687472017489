import { FC, useState } from 'react';
import Text from '../../../../lib/Text';
import { bloodGroups, genders } from '../../../../../utils/constants';
import Radio from '../../../../form/Radio';
import Button from '../../../../lib/Button';
import FilterProps from './FilterProps';

const Filter: FC<FilterProps> = ({ filter, setFilter, toggleFilter }) => {
  const [blood_group, setBloodGroup] = useState(filter.blood_group);
  const [gender, setGender] = useState(filter.gender);

  const handleApplyFilter = () => {
    setFilter({
      blood_group,
      gender,
    });
    toggleFilter();
  };

  const handleClearFilter = () => {
    setFilter({
      blood_group: '',
      gender: '',
    });
    toggleFilter();
  };

  return (
    <div className="flex flex-col gap-5 lg:gap-10">
      <Text className="lg:blockfont-interBold hidden">
        Filter your requests
      </Text>

      <div className="flex flex-col gap-2">
        <span className="font-interSemibold text-xs md:text-sm">
          By Blood Group
        </span>

        <div className="flex w-full flex-wrap gap-5 border border-jblood-gray p-5 lg:p-10">
          {bloodGroups.map((group, index) => (
            <div className="w-[40%]" key={index}>
              <Radio
                label={group.key}
                onChange={() => setBloodGroup(group.value)}
                checked={blood_group === group.value}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <span className="font-interSemibold text-xs md:text-sm">By Gender</span>

        <div className="flex w-full flex-wrap gap-5 border border-jblood-gray p-5 lg:p-10">
          {genders.map((item, index) => (
            <div className="w-[100%]" key={index}>
              <Radio
                label={item.key}
                onChange={() => setGender(item.value)}
                checked={gender === item.value}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="mx-auto flex items-center justify-center gap-3">
        <Button onClick={handleApplyFilter}>Apply</Button>
        <Button variant="outline" onClick={handleClearFilter}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default Filter;
