import { FC } from 'react';
import AvatarProps from './AvatarProps';

const Avatar: FC<AvatarProps> = ({ name, image, className }) => {
  return image ? (
    <img
      className={`h-10 w-10 rounded-full ${className ? className : ''}`}
      src={image}
      alt=""
    />
  ) : name ? (
    <span
      className={`dark:border-gray-800" flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-gradient text-xs font-medium capitalize text-white hover:bg-gray-600 ${
        className ? className : ''
      }`}
    >
      {name.length > 3
        ? name
            .split(' ')
            .map((item) => item.charAt(0))
            .join('')
        : name}
    </span>
  ) : (
    <div
      className={`relative h-10 w-10 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600 ${
        className ? className : ''
      }`}
    >
      <svg
        className="absolute -left-1 h-12 w-12 text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default Avatar;
