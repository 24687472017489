import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import { numbers } from './dummyData/numbers';

const NUmberGroup = ({
  number,
  title,
  className,
}: {
  number: string;
  title: string;
  className: string;
}) => (
  <div
    className={`col-span-1 ${
      className || ''
    } flex flex-col items-center gap-5 text-center`}
  >
    <Heading type="h2">{number}</Heading>
    <Text type="subheading" className="text-jblood-gray">
      {title}
    </Text>
  </div>
);

const Numbers = () => {
  return (
    <section className="relative py-5 px-5 md:py-10">
      <div className="max z-20 mx-auto flex flex-col items-center gap-16">
        <Heading type="h3">A look at J Blood Match by numbers</Heading>

        <div className="mx-auto grid w-full max-w-[900px] grid-cols-2 items-center justify-between justify-items-center gap-10 md:grid-cols-4">
          {numbers.map(({ title, number }, index) => (
            <NUmberGroup
              key={index}
              {...{
                title,
                number,
                className: '',
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Numbers;
