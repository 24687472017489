import { Icon } from '@iconify/react';
import { FC, FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toaster from 'react-hot-toast';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { patchRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import { UPDATE_USER } from '../../../utils/api/urls';
import { isEmpty, validateUpdateVitalsInput } from '../../../utils/validators';
import Input from '../../form/Input';
import Button from '../../lib/Button';
import Detail from './Detail';
import { VitalsProps } from './EditProfileProps';

const initialState = {
  height: '',
  weight: '',
  blood_pressure: '',
  heart_rate: '',
};

const Vitals: FC<VitalsProps> = ({ user }) => {
  const color = 'text-primary-dark';
  const [editMode, setEditMode] = useState(false);
  const handler = useErrorHandler();
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState(initialState);
  const [payload, setPayload] = useState({
    height: user?.height || '',
    weight: user?.weight || '',
    blood_pressure: user?.blood_pressure || '',
    heart_rate: user?.heart_rate || '',
  });

  const { mutate, isLoading } = useMutation(patchRequest, {
    onSuccess() {
      queryClient.invalidateQueries(queryKeys.getUser);
      setEditMode(false);
      toaster.success('Vitals updated successfully');
    },
    onError(error) {
      handler(error);
    },
  });

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } =
      validateUpdateVitalsInput(payload);

    if (!valid) {
      setErrors(validationErrors);
      return;
    }

    mutate({
      url: UPDATE_USER,
      data: {
        height: isEmpty(payload.height) ? null : payload.height,
        weight: isEmpty(payload.weight) ? null : payload.weight,
        blood_pressure: isEmpty(payload.blood_pressure)
          ? null
          : payload.blood_pressure,
        heart_rate: isEmpty(payload.heart_rate) ? null : payload.heart_rate,
      },
    });
  };

  return (
    <div className="relative flex w-full items-center gap-5 overflow-hidden rounded-lg border border-primary-dark bg-[#FCF9F8] p-5 md:p-10">
      <div className="absolute top-0 left-0 h-full w-2 bg-primary-dark" />

      <div className="flex flex-1 flex-col gap-3 md:gap-5">
        <div className="flex w-full items-center justify-between">
          {editMode ? (
            <div />
          ) : (
            <Detail
              icon={
                user.gender === 'Male'
                  ? 'carbon:gender-male'
                  : 'carbon:gender-female'
              }
              color={color}
              title="Height"
              value={user?.height ? `${user?.height}ft` : ''}
            />
          )}

          <Icon
            icon={editMode ? 'iconoir:cancel' : 'eva:edit-outline'}
            className="cursor-pointer text-2xl text-jblood-gray hover:text-primary-main"
            onClick={toggleEditMode}
          />
        </div>

        {editMode ? (
          <>
            <Input
              label="Height"
              helperLabel="(in ft)"
              type="number"
              min={0}
              placeholder="Enter Height"
              name="height"
              value={payload.height}
              onChange={handleChange}
              error={!isEmpty(errors.height)}
              helperText={errors.height}
            />
            <Input
              label="Weight"
              helperLabel="(in kg)"
              type="number"
              min={0}
              placeholder="Enter Weight"
              name="weight"
              value={payload.weight}
              onChange={handleChange}
              error={!isEmpty(errors.weight)}
              helperText={errors.weight}
            />
            <Input
              label="Blood Pressure"
              helperLabel="(in mm Hg)"
              placeholder="Enter Blood Pressure"
              name="blood_pressure"
              value={payload.blood_pressure}
              onChange={handleChange}
              error={!isEmpty(errors.blood_pressure)}
              helperText={errors.blood_pressure}
            />
            <Input
              label="Heart Rate"
              helperLabel="(in BPM)"
              type="number"
              min={0}
              placeholder="Enter Heart"
              name="heart_rate"
              value={payload.heart_rate}
              onChange={handleChange}
              error={!isEmpty(errors.heart_rate)}
              helperText={errors.heart_rate}
            />
          </>
        ) : (
          <>
            <Detail
              icon="material-symbols:monitor-weight-outline"
              color={color}
              title="Weight"
              value={user?.weight ? `${user?.weight}kg` : ''}
            />
            <Detail
              icon="healthicons:blood-pressure-outline"
              color={color}
              title="Blood Pressure"
              value={
                user?.blood_pressure ? `${user?.blood_pressure} mm Hg` : ''
              }
            />
            <Detail
              icon="icon-park-outline:heart-rate"
              color={color}
              title="Heart Rate"
              value={user?.heart_rate ? `${user?.heart_rate}BPM` : ''}
            />
          </>
        )}

        {editMode && (
          <div className="mt-5 flex w-full items-center justify-center">
            <Button size="medium" onClick={handleSubmit} loading={isLoading}>
              Update
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Vitals;
