import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/user';
import Button from '../../lib/Button';
import { mobileNavLinks } from './links';

const MobileNav = ({ onClose }: { onClose: () => void }) => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    // Prevent scolling
    document.body.style.overflow = 'hidden';

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'visible';
    };
  });

  return (
    <div className="fixed top-0 left-0 z-30 h-full w-full bg-black/20">
      <div className="max-h-[100vh]">
        <div className="flex h-full w-full flex-col items-center justify-end gap-8 overflow-y-auto bg-white p-10 pt-20">
          {mobileNavLinks.map((item, index) => (
            <Link to={item.url} key={index}>
              <span
                onClick={onClose}
                className="cursor-pointer font-interSemibold"
              >
                {item.title}
              </span>
            </Link>
          ))}

          <Link to="/signup">
            {user ? (
              <Button
                size="medium"
                onClick={() => {
                  onClose();
                }}
              >
                Go to dashboard
              </Button>
            ) : (
              <Button
                size="medium"
                onClick={() => {
                  onClose();
                }}
              >
                Sign up
              </Button>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
