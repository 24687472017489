import { FormEvent, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import toaster from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FormContainer from '../../components/containers/FormContainer';
import Input from '../../components/form/Input';
import Button from '../../components/lib/Button';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import { patchRequest, postRequest } from '../../utils/api/calls';
import { LOGIN, UPDATE_NOTIFICATION_TOKEN } from '../../utils/api/urls';
import {
  isEmpty,
  notificationIsSupported,
  validateLoginInputs,
} from '../../utils/validators';
import { UserContext } from '../../contexts/user';
import useErrorHandler from '../../hooks/useErrorHandler';
import { RoleContext } from '../../contexts/role';
import { getNotificationToken } from '../../firebase/messaging';
import Logo from '../../components/app/Navbar/Logo';

const initialState = {
  email: '',
  password: '',
};

const Login = () => {
  const { login } = useContext(UserContext);
  const { setRole } = useContext(RoleContext);
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(true);
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const { pathname } = useLocation();
  const handler = useErrorHandler();

  const { mutate: updateNotificationToken } = useMutation(patchRequest, {
    onError(error: any) {
      handler(error);
    },
  });

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess(response) {
      // Check if the user has verified their email and make
      // them vreify it if they haevn't

      if (response?.data?.user?.status === 'active') {
        // Check if the user has complete onboarding and redirect them to
        // complete onboarding if they haven't

        if (!response?.data?.user?.onboarded) {
          if (response?.data?.token) {
            localStorage.setItem('token', response.data.token);
          }
          navigate('/signup?registered=true');
          return;
        }

        // Persist the user's token if available and continue to dashboard
        if (response?.data?.token) {
          localStorage.setItem('token', response.data.token);

          // As discussed, the user should always login with the donor role,
          // refer to the "Role Context"
          localStorage.setItem('role', 'donor');
          setRole('donor');

          /////////// SUBSCRIBING TO PUSH NOTIFICATIONS START //////////////

          // Request notification permission from user and the send it to
          // the backend once the user has granted permission.
          notificationIsSupported() &&
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                // Because we're on the web, we cannot get get device ID for
                // firebase messaging. We will then generate a UUID, persist
                // it and then use that for the device ID instead
                let generatedDeviceId = '';

                if (localStorage.getItem('device_id')) {
                  generatedDeviceId = localStorage.getItem(
                    'device_id'
                  ) as string;
                } else {
                  const newId = uuidv4();
                  generatedDeviceId = newId;
                  localStorage.setItem('device_id', newId);
                }

                getNotificationToken()
                  .then((response) => {
                    updateNotificationToken({
                      url: UPDATE_NOTIFICATION_TOKEN,
                      data: {
                        notification_token: response,
                        device_id: generatedDeviceId,
                      },
                    });
                  })
                  .catch(() => {
                    toaster.error('Could not get notification token.');
                  });
              }
            });
          /////////// SUBSCRIBING TO PUSH NOTIFICATIONS END /////////////////

          login(response?.data?.user);
          navigate('/app/dashboard');
          return;
        }
      } else {
        setIsVerified(false);
      }
    },
    onError(error: any) {
      handler(error);
    },
  });

  useEffect(() => {
    const userData = localStorage.getItem('user');

    if (userData) {
      const user = JSON.parse(userData);

      if (user?.id) {
        navigate('/app/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } = validateLoginInputs(payload);

    if (valid) {
      mutate({
        url: LOGIN,
        data: {
          ...payload,
          base_url: `${window.location.href.split(pathname)[0]}/`,
        },
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <FormContainer showBackArrow onBackClicked={() => navigate('/')}>
      <div className="flex min-h-[70vh] w-full max-w-[500px] flex-col justify-center gap-3 lg:min-h-[50vh] lg:max-w-[650px] lg:gap-5">
        {isVerified ? (
          <>
            <div className="mb-10 -mt-10 flex w-full justify-center">
              <Logo />
            </div>
            <Heading
              underlined
              className="mx-auto w-[60vw] text-center text-2xl md:w-auto"
            >
              Welcome!
            </Heading>
            <Input
              label="Email address"
              type="email"
              placeholder="example@domain.com"
              name="email"
              value={payload.email}
              onChange={handleChange}
              error={!isEmpty(errors.email)}
              helperText={errors.email}
            />

            <Input
              label="Password"
              type="password"
              placeholder="********"
              name="password"
              value={payload.password}
              onChange={handleChange}
              error={!isEmpty(errors.password)}
              helperText={errors.password}
            />

            <Link to="/forgot-password" className="self-end">
              <Text type="caption" className="text-primary-main">
                Forgot Password?
              </Text>
            </Link>

            <Button
              size="medium"
              variant="gradient"
              className="mx-auto mt-10 w-full max-w-[500px]"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleSubmit}
            >
              Log in
            </Button>

            <Text className="text-center" type="caption">
              Don’t have an account?{' '}
              <Link to="/signup" className="text-primary-main">
                Sign up
              </Link>
            </Text>
          </>
        ) : (
          <>
            <Heading type="h3">Verify your email address</Heading>
            <Text className="mb-36">
              We have sent you an email with a verification link to{' '}
              <span className="font-interSemibold text-green-500">
                {payload.email}
              </span>
              . Please check your spam folder if you don’t find it in your
              inbox.
            </Text>
          </>
        )}
      </div>
    </FormContainer>
  );
};

export default Login;
