import React, { createContext, ReactNode, useReducer } from 'react';

type PageState = {
  leftComponent: ReactNode;
  title: string;
};

type TitleAction = {
  type: 'SET TITLE';
  title: string;
};

type LeftComponentAction = {
  type: 'SET LEFT COMPONENT';
  leftComponent: ReactNode;
};

type ActionType = TitleAction | LeftComponentAction;

const InitialState: PageState = {
  title: '',
  leftComponent: null,
};

const PageContext: React.Context<{
  title: string;
  leftComponent: null;
  setTitle: (title: string) => void;
  setLeftComponent: (leftComponent: ReactNode) => void;
}> = createContext({
  title: '',
  leftComponent: null,
  setTitle: (title: string) => {},
  setLeftComponent: (leftComponent: ReactNode) => {},
});

const PageReducer = (state: PageState, action: ActionType) => {
  switch (action.type) {
    case 'SET TITLE':
      return {
        ...state,
        title: action.title || '',
      };

    case 'SET LEFT COMPONENT':
      return {
        ...state,
        leftComponent: action.leftComponent,
      };

    default:
      return state;
  }
};

const PageProvider = (props: any) => {
  const [state, dispatch] = useReducer(PageReducer, InitialState);

  const setTitle = (title: string) => {
    dispatch({
      type: 'SET TITLE',
      title,
    });
  };

  const setLeftComponent = (leftComponent: ReactNode) => {
    dispatch({
      type: 'SET LEFT COMPONENT',
      leftComponent,
    });
  };

  return (
    <PageContext.Provider
      value={{
        title: state.title,
        leftComponent: state.leftComponent,
        setTitle,
        setLeftComponent,
      }}
      {...props}
    />
  );
};

export { PageContext, PageProvider };
