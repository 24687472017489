import { FC, FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { State } from '../../../../../types/State';
import { getRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { GET_STATES } from '../../../../../utils/api/urls';
import {
  isEmpty,
  validateRequestDonorForm3Inputs,
} from '../../../../../utils/validators';
import Input from '../../../../form/Input';
// import Select from '../../../../form/Select';
import Button from '../../../../lib/Button';
import LocationAutoComplete from '../../../../lib/LocationAutoComplete';
import { LocationData } from '../../../../lib/LocationAutoComplete/LocationAutoCompleteProps';
import { FormProps } from './FormsProps';

const initialState: {
  hospital: string;
  longitude: number | null;
  latitude: number | null;
  physician: string;
  state_id: string;
} = {
  hospital: '',
  longitude: null,
  latitude: null,
  physician: '',
  state_id: '',
};

const Form3: FC<FormProps> = ({
  handleSubmit: handleSubmitForm,
  payload: generalPayload,
  setPayload: setGeneralPayload,
  isLoading,
}) => {
  const [payload, setPayload] = useState(initialState);
  const [states, setStates] = useState<State[]>([]);
  const [errors, setErrors] = useState({
    ...initialState,
    longitude: '',
    latitude: '',
    location: '',
  });
  const handler = useErrorHandler();

  useQuery([queryKeys.getStates], () => getRequest({ url: GET_STATES }), {
    onSuccess(response) {
      setStates(response?.data?.states || []);
    },
    onError(error: any) {
      handler(error);
    },
  });

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleLocationChange = (location: LocationData) => {
    const { geo, state, country, fullAddress } = location;

    if (country !== 'Nigeria') {
      toast.error('You can only select a location in Nigeria.');
      setErrors({
        ...errors,
        location: 'Location must be in Nigeria',
      });
      return;
    }

    const state_id = states.find((item) => item.name === state)?.id;

    setPayload({
      ...payload,
      ...geo,
      hospital: fullAddress || '',
      state_id: String(state_id || ''),
    });

    setErrors({
      ...errors,
      location: '',
    });
  };

  const handleSubmit = () => {
    setErrors({ ...initialState, longitude: '', latitude: '', location: '' });

    const { valid, errors: validationErrors } =
      validateRequestDonorForm3Inputs(payload);

    if (valid && isEmpty(errors.location)) {
      setGeneralPayload({
        ...generalPayload,
        ...payload,
      });

      handleSubmitForm &&
        handleSubmitForm({
          ...generalPayload,
          ...payload,
        });
    } else {
      setErrors({ ...validationErrors, location: errors.location });
    }
  };

  return (
    <>
      <Input
        label="Physician Name"
        placeholder="Enter Physician name"
        name="physician"
        value={payload.physician}
        onChange={handleChange}
        error={!isEmpty(errors.physician)}
        helperText={errors.physician}
      />
      {/* <Input
        label="Hospital Name"
        placeholder="Enter Hospital name"
        name="hospital"
        value={payload.hospital}
        onChange={handleChange}
        error={!isEmpty(errors.hospital)}
        helperText={errors.hospital}
      /> */}

      <LocationAutoComplete
        label="Search Hospital name, address"
        helperLabel="(e.g Lagos University Teaching Hospital, Ishaga, Lagos)"
        placeholder="Search hospital name or address"
        error={
          !isEmpty(errors.latitude) ||
          !isEmpty(errors.longitude) ||
          !isEmpty(errors.location)
        }
        helperText={
          !isEmpty(errors.location)
            ? errors.location
            : !isEmpty(errors.latitude) || !isEmpty(errors.longitude)
            ? 'Location must not be empty'
            : ''
        }
        onChange={handleLocationChange}
      />

      {/* <Select
        label="State"
        placeholder="Select State"
        name="state_id"
        value={payload.state_id}
        options={[
          { key: 'Select State', value: '' },
          ...states.map((state) => ({
            key: state.name,
            value: state.id,
          })),
        ]}
        onChange={handleChange}
        error={!isEmpty(errors.state_id)}
        helperText={errors.state_id}
      /> */}

      <Button
        size="medium"
        variant="solid"
        className="mx-auto mt-10 w-full lg:w-auto"
        onClick={handleSubmit}
        loading={isLoading}
      >
        Send Request
      </Button>
    </>
  );
};

export default Form3;
