import { FC } from 'react';
import moment from 'moment';
import Radio from '../../../../form/Radio';
import Text from '../../../../lib/Text';
import { ReminderItemProps } from './RemindersProps';

const ReminderItem: FC<ReminderItemProps> = ({ title, date }) => {
  return (
    <div className="flex items-start gap-3 border-b border-jblood-gray/50 py-3">
      <div className="mt-1">
        <Radio />
      </div>

      <div className="flex flex-col">
        <Text>{title}</Text>
        <Text className="text-primary-main">
          {moment(date).format('DD/MM/YYYY, hh:mm A')}
        </Text>
      </div>
    </div>
  );
};

export default ReminderItem;
