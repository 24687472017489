import { useState } from 'react';
import { useQuery } from 'react-query';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { Request } from '../../../../../types/Request';
import { getRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { GET_DONOR_REQUEST_HISTORY } from '../../../../../utils/api/urls';
import Heading from '../../../../lib/Heading';
import ContainedLoader from '../../../../lib/Loader/ContainedLoader';
import Pagination from '../../../../lib/Pagination';
import Text from '../../../../lib/Text';
import DonationHistoryCard from './DonationHistoryCard';

const DonationHistoryList = () => {
  const [history, setHistory] = useState<{ request: Request }[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getHistory, page],
    () =>
      getRequest({ url: GET_DONOR_REQUEST_HISTORY(`?limit=20&page=${page}`) }),
    {
      onSuccess(response) {
        setHistory(response?.data?.donations || []);
        setCount(response?.data?.page_info?.total_pages || 0);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  return (
    <>
      <Pagination {...{ page, setPage, count }} />
      {isLoading ? (
        <ContainedLoader />
      ) : history.length === 0 ? (
        <div className="flex min-h-[200px] w-full flex-col items-center justify-center gap-1 text-center">
          <Heading type="h3">Nothing to see here!</Heading>
          <Text>You do not have any blood donation history at the moment.</Text>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          {history.map((history, index) => (
            <DonationHistoryCard
              key={index}
              {...{
                ...history?.request,
                variant: index % 2 === 0 ? 'dark' : 'light',
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default DonationHistoryList;
