import PageTitle from '../../components/containers/PageTitle';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import Button from '../../components/lib/Button';
import { Link, useLocation } from 'react-router-dom';

const Donate = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="Dontation">
      <div
        className={`max mx-auto flex flex-col gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'overflow-y-auto p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] max-w-[300px] self-center text-center md:mb-10 md:w-auto`}
        >
          Donate to JDI
        </Heading>

        <Text className="!leading-loose">
          Jela’s Development Initiatives (JDI) is a Nigerian organization
          registered in 2019 to provide access to voluntary blood donors, clean
          water and sanitation/hygiene facilities, mental healthcare and youth
          empowerment through volunteering opportunities.
        </Text>

        <Text>
          As the organization behind J Blood Match, the goal of providing this
          service is to promote health and wellness while curbing the rate of
          preventable deaths through voluntary blood donation.{' '}
        </Text>

        <Text>
          Our goal is to increase the Nigerian blood donor pool from less than
          1% to 3%!
        </Text>

        <Text>
          <span className="font-bold">Our 1000 for 1000 initiative:</span> As
          incentive for willingly donating blood, JDI encourages such selfless
          donors by crediting their phone lines with NGN1,000 airtime,
          irrespective of the telecommunication network they use.
        </Text>

        <Text>
          With 1000 people donating NGN 1000, heroic blood donors are encouraged
          to keep being blood donors!
        </Text>

        <Text>
          Collaborate with us to make it a reality. Donations cover the cost of
          running the app, airtime incentives to donors after a successful
          donation process
        </Text>

        <div className="grid justify-start justify-items-start gap-5">
          <Link to={isAuthScreen ? '/app/donate/form' : '/donate/form'}>
            <Button size="medium">Donate Now</Button>
          </Link>
        </div>
      </div>
    </PageTitle>
  );
};

export default Donate;
