import { useState } from 'react';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
import Stepper from '../../../components/lib/Stepper';
import Forms from '../../../components/auth/Recipient/FindDonor/Forms';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useQueryParam } from '../../../hooks/useQueryParams';

const FindDonor = () => {
  const [activeStep, setActiveStep] = useState(1);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const recipient = useQueryParam().get('recipient') || '';

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  return (
    <PageTitle title="Find Donor">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Find A Donor" mobile={smallScreen}>
          <div className="mt-0 flex w-full flex-col gap-3 lg:mt-10 lg:gap-5">
            <div className="mx-auto w-full max-w-[350px]">
              <Stepper
                {...{ steps: recipient === 'self' ? 2 : 3, activeStep }}
              />
            </div>

            <Forms {...{ activeStep, setActiveStep, handleNext, recipient }} />
          </div>
        </Card>
      </div>
    </PageTitle>
  );
};

export default FindDonor;
