import { FC } from 'react';
import Text from '../Text';
import NoContentProps from './NoContentProps';

const NoContent: FC<NoContentProps> = ({ label }) => {
  return (
    <div className="flex min-h-[200px] w-full items-center justify-center text-center">
      <Text>{label || 'Nothing to see here!'}</Text>
    </div>
  );
};

export default NoContent;
