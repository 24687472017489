import { Icon } from '@iconify/react';
import { FC } from 'react';
import Text from '../../../../lib/Text';
import { AcceptRequestDetailProps } from './AcceptRequestModalProps';

const AcceptRequestDetail: FC<AcceptRequestDetailProps> = ({
  icon,
  title,
  detail,
}) => {
  return (
    <div className="flex items-center gap-3 rounded-lg bg-white px-5 py-2 shadow shadow-primary-light/50 lg:px-10 lg:py-3">
      <Icon icon={icon} className="text-2xl text-primary-main lg:text-2xl" />

      <div>
        <Text type="caption" className="!text-xs text-jblood-gray lg:!text-sm">
          {title}
        </Text>

        {title === 'Phone Number' ? (
          <a href={`tel:${detail}`} target="_blank" rel="noreferrer">
            <Text className="!font-interSemibold">{detail}</Text>
          </a>
        ) : (
          <Text className="!font-interSemibold text-primary-main underline">
            {detail}
          </Text>
        )}
      </div>
    </div>
  );
};

export default AcceptRequestDetail;
