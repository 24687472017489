import toaster from 'react-hot-toast';

const useErrorHandler = () => {
  const handler = (error: any) =>
    toaster.error(
      error?.response?.data?.message || 'Something went wrong, try again later.'
    );

  return handler;
};

export default useErrorHandler;
