import DonationHistoryList from '../../../components/auth/Donor/DonationHistory/DonationHistoryList';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
import useMediaQuery from '../../../hooks/useMediaQuery';

const DonationHistory = () => {
  const smallScreen = useMediaQuery('(max-width: 1204px)');

  return (
    <PageTitle title="Donation History">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Donation History" mobile={smallScreen}>
          <DonationHistoryList />
        </Card>
      </div>
    </PageTitle>
  );
};

export default DonationHistory;
