import { Icon } from '@iconify/react';
import moment from 'moment';
import toaster from 'react-hot-toast';
import { FC, useState } from 'react';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { deleteRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { DELETE_REMINDER } from '../../../../../utils/api/urls';
import Radio from '../../../../form/Radio';
import Text from '../../../../lib/Text';
import { ReminderListItemProps } from './RemindersListProps';

const ReminderListItem: FC<ReminderListItemProps> = ({ id, title, date }) => {
  const [expanded, setExpanded] = useState(false);
  const handler = useErrorHandler();
  const queryClient = useQueryClient();

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  const { mutate, isLoading } = useMutation(deleteRequest, {
    onSuccess() {
      toaster.success('Reminder deleted successfully');
      queryClient.invalidateQueries(queryKeys.getReminders);
    },
    onError(error) {
      handler(error);
    },
  });

  const handleDelete = () => {
    mutate({
      url: DELETE_REMINDER(id),
      data: {},
    });
  };

  return (
    <div className="flex w-full flex-col gap-5 border-b border-jblood-gray/50 py-5">
      <button
        className="flex w-full items-start justify-between gap-3"
        onClick={toggleExpanded}
      >
        <Radio containerClass="mt-1" onChange={() => {}} disabled />

        <div className="flex flex-1 flex-col items-start gap-1">
          <Text>{title}</Text>
          <Text className="!text-primary-main">
            {moment(date).format('DD/MM/YYYY, hh:mm A')}
          </Text>
        </div>

        <MdKeyboardArrowUp
          className={`text-jblood-gray transition-all duration-300 ${
            expanded ? 'rotate-180' : 'rotate-0'
          }`}
          size={24}
        />
      </button>

      {expanded && (
        <div className="flex w-full gap-3">
          <div className="h-4 w-4" />

          <div className="flex flex-1 flex-col gap-3">
            <Text>
              Date:{' '}
              <span className="font-interSemibold">
                {moment(date).format('dddd , DD MMMM YYYY')}
              </span>
            </Text>

            <Text>
              Time:{' '}
              <span className="font-interSemibold">
                {moment(date).format('hh:mm A')}
              </span>
            </Text>

            <div className="flex w-full justify-end">
              <button
                disabled={isLoading}
                onClick={handleDelete}
                className="flex items-center justify-center rounded-full border border-primary-dark p-2 disabled:border-gray-300"
              >
                <Icon
                  icon="fluent:delete-16-regular"
                  className={`text-xl ${
                    isLoading ? 'text-gray-300' : 'text-primary-dark'
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReminderListItem;
