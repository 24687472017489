import { useState } from 'react';
import { MdArrowDownward } from 'react-icons/md';
import FloatingCircleBig from '../../../assets/icons/FloatingCircleBig.svg';
import FloatingCircleSmall from '../../../assets/icons/FloatingCircleSmall.svg';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import { tesimonials } from './dummyData/testimonials';

const DonorTestimonialCard = ({
  name,
  details,
}: {
  image: string;
  name: string;
  details: string;
}) => {
  const [more, setMore] = useState(false);

  return (
    <div className="col-span-1 flex max-w-[350px] flex-col">
      {/* <figure className="aspect-[10/8] w-full overflow-hidden">
      <img src={image} alt={name} className="h-full w-full object-cover" />
    </figure> */}

      <div className="flex min-h-[220px] flex-col gap-5 bg-[#F2F1F6] p-5 pt-7">
        <Heading type="h3" className="capitalize">
          {name}
        </Heading>
        <Text type="caption" className="flex-1">
          {more ? details : `${details.substring(0, 70)}...`}
        </Text>

        <button onClick={() => setMore((prevstate) => !prevstate)}>
          <Text type="caption" className="flex items-center gap-2">
            {more ? 'Show Less' : 'Show More'}
            <MdArrowDownward
              className={`duration-200 ${more ? 'rotate-180' : 'rotate-0'}`}
            />
          </Text>
        </button>
      </div>
    </div>
  );
};

const DonorTestimonial = () => {
  return (
    <section className="relative self-stretch py-16 px-5 md:py-20">
      <img
        src={FloatingCircleBig}
        alt=""
        className="absolute top-[2%] left-[15%] h-16 w-16 md:top-[12%] md:left-[5%]"
      />
      <img
        src={FloatingCircleSmall}
        alt=""
        className="absolute top-[2%] left-[36%] md:top-[19%] md:left-[12%]"
      />

      <div className="max z-20 mx-auto flex flex-col items-center gap-16">
        <Heading type="h2">Testimonials</Heading>

        <div className="grid w-full grid-cols-1 justify-between gap-10 md:grid-cols-3">
          {tesimonials.map(({ name, image, details }, index) => (
            <DonorTestimonialCard key={index} {...{ name, image, details }} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default DonorTestimonial;
