import { FC } from 'react';
import { FeedbackListCardProps } from './FeedbackListProps';
import Avatar from '../../../lib/Avatar';
import Text from '../../../lib/Text';
import { AiFillHeart } from 'react-icons/ai';
import { trimString } from '../../../../utils/misc';

const FeedbackListCard: FC<FeedbackListCardProps> = ({
  name,
  description,
  onClick,
  variant,
}) => {
  return (
    <div
      className="relative flex cursor-pointer items-center gap-3 overflow-hidden rounded-lg bg-[#FCF9F8] p-5 lg:gap-5 lg:pl-7"
      onClick={onClick}
    >
      <div
        className={`absolute top-0 left-0 h-full w-2 ${
          variant === 'dark' ? 'bg-primary-main' : 'bg-primary-light'
        }`}
      />

      <Avatar name={name} />

      <div className="flex flex-1 flex-col gap-1">
        <Text className="!font-interSemibold capitalize">{name}</Text>
        <Text className="text-jblood-gray" type="caption">
          {trimString(description, 30)}
        </Text>
      </div>

      <AiFillHeart className="text-2xl text-red-500 lg:text-3xl" />
    </div>
  );
};

export default FeedbackListCard;
