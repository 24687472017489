import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../../form/Checkbox';
import Button from '../../../lib/Button';
import Text from '../../../lib/Text';

const Page2 = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/');
  };

  return (
    <>
      <ul className="ml-5 flex list-disc flex-col gap-5 md:gap-10">
        <li>
          <Text>
            You are required to give feedback on the app after donating so that
            you do not receive further requests before you are due for donation.
          </Text>
        </li>
        <li>
          <Text>
            Ensure you wear loose-fitting clothes, preferably something with
            short sleeves to give easy access to your forearm.
          </Text>
        </li>
        <li>
          <Text>
            Ensure that you have had something to eat before donating and drink
            lots of water.
          </Text>
        </li>
        <li>
          <Text>
            You may take pictures with the recipient, but only after consent is
            given and the details of the patient’s ailment must be kept
            confidential.
          </Text>
        </li>
      </ul>

      <div className="mt-10 flex justify-center">
        <Checkbox
          label="I agree to the points above, I also agree to be sent tips to help me become a healthier donor."
          checked={checked}
          onChange={() => setChecked((prevState) => !prevState)}
        />
      </div>

      <Button
        size="medium"
        variant="gradient"
        disabled={!checked}
        className="mx-auto w-full max-w-[500px]"
        onClick={handleSubmit}
      >
        Done
      </Button>
    </>
  );
};

export default Page2;
