import Testimonial1 from '../../../../assets/images/Testimonial1.jpg';
import Testimonial2 from '../../../../assets/images/Testimonial2.jpg';
import Testimonial3 from '../../../../assets/images/Testimonial3.jpg';

export const tesimonials = [
  {
    name: 'Zibai Blessed, JBM Donor',
    details:
      'Due to the various stories I have heard about how people have died because they couldn’t afford blood, that touched me a lot. I was part of a sensitization program before the launch of the J Blood Match app, they told us some of the problems, I just felt there was no harm, it was just my own way of helping. It was something out of humanity',
    image: Testimonial1,
  },
  {
    name: 'Okorie Favour, JBM Recipient',
    details:
      'It’s a really simple process. It’s not complicated. It’s very easy to use. Registration is free and you get responses almost immediately.',
    image: Testimonial2,
  },
  {
    name: 'Precious Onyeka Awanye, JBM Donor',
    details:
      'In August 2017 in Abuja, I was in the office then I started feeling funny and dizzy, I had to visit the hospital where tests were carried out.  \nThe doctor explained that my blood level was too high and there was need to donate blood. He recommended that after treatment I should come back in two weeks to donate which I did. September 2017 became my very first time of donating blood. \nAfter that episode, I decided to donate blood regularly.\nThe NBTS team explained that I could actually donate every three months. \nIn November 2019, I also donated  blood at the launch of  J Blood Match at Transcorp Hilton, Abuja.\nI am a happy voluntary blood donor.',
    image: Testimonial3,
  },
];
