import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { Reminder } from '../../../../../types/Reminder';
import { getRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { GET_REMINDERS } from '../../../../../utils/api/urls';
import Card from '../../../../lib/Card';
import ContainedLoader from '../../../../lib/Loader/ContainedLoader';
import NoContent from '../../../../lib/NoContent';
import Text from '../../../../lib/Text';
import ReminderItem from './ReminderItem';

const Reminders = () => {
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getReminders],
    () => getRequest({ url: GET_REMINDERS('?limit=5') }),
    {
      onSuccess(response) {
        setReminders(response?.data?.reminders || []);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  return (
    <Card
      title="Your Reminders"
      className="break-inside mb-10 md:mb-5"
      titleComponent={
        <Link to="/app/reminders">
          <Text className="font-interSemibold text-jblood-gray" type="caption">
            View all
          </Text>
        </Link>
      }
    >
      {isLoading ? (
        <ContainedLoader />
      ) : reminders.length === 0 ? (
        <NoContent />
      ) : (
        <div className="flex flex-col gap-5">
          {reminders.map((item, index) => (
            <ReminderItem key={index} {...item} />
          ))}
        </div>
      )}
    </Card>
  );
};

export default Reminders;
