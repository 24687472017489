import { Icon } from '@iconify/react';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import toaster from 'react-hot-toast';
import { MdClose } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RequestContext } from '../../../../../contexts/request';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { patchRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import {
  CONFIRM_DONATION,
  TOGGLE_REQUEST_STATUS,
} from '../../../../../utils/api/urls';
import Avatar from '../../../../lib/Avatar';
import Button from '../../../../lib/Button';
import Heading from '../../../../lib/Heading';
import Loader from '../../../../lib/Loader';
import Modal from '../../../../lib/Modal';
import SimpleModal from '../../../../lib/Modal/SimpleModal';
import Text from '../../../../lib/Text';
import Toggle from '../../../../lib/Toggle';
import DonorDetail from './DonorDetail';
import RequestDetail from './RequestDetail';
import RequestModalProps from './RequestModalProps';

const BloodGroup = ({ group }: { group?: string }) => (
  <div className="mx-auto mt-1 flex flex-col items-center">
    <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gradient">
      <Text className="text-white">{group}</Text>
    </div>

    <Text type="caption" className="text-center text-jblood-gray">
      Blood Group
    </Text>
  </div>
);

const RequestModal: FC<RequestModalProps> = ({
  open,
  onClose,
  selectedIndex,
  setSelectedIndex,
  requests,
}) => {
  const { setRequest } = useContext(RequestContext);
  const [showWarningModal, setWarningModal] = useState(false);
  const data = requests[selectedIndex];
  const [isActive, setIsActive] = useState(false);
  const [showDonors, setShowDonors] = useState(false);
  const queryClient = useQueryClient();
  const handler = useErrorHandler();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.status === 'inactive') {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [data]);

  const { mutate, isLoading } = useMutation(patchRequest, {
    onSuccess() {
      setRequest(requests[selectedIndex]);
      queryClient.invalidateQueries(queryKeys.getRecipientRequests);
      handleClose();
      navigate(`/app/feedback/${requests[selectedIndex].id}`);
    },
    onError(error) {
      handler(error);
    },
  });

  const { mutate: changeRequestStatus, isLoading: requestStatusLoading } =
    useMutation(patchRequest, {
      onSuccess(response) {
        queryClient.invalidateQueries(queryKeys.getRecipientRequests);
        toaster.success('Status updated successfully');
        setWarningModal(false);
        setIsActive((prevState) => !prevState);
      },
      onError(error) {
        handler(error);
      },
    });

  const handleClose = () => {
    onClose();
    setShowDonors(false);
  };

  const toggleWarningModal = () => {
    setWarningModal((prevState) => !prevState);
  };

  const toggleShowDonors = () => {
    setShowDonors((prevState) => !prevState);
  };

  const toggleActive = () => {
    if (isActive) {
      toggleWarningModal();
    } else {
      changeRequestStatus({
        url: TOGGLE_REQUEST_STATUS({ id: data?.id }),
        data: {
          status: 'pending',
        },
      });
    }
  };

  const handleActionButtonClicked = () => {
    changeRequestStatus({
      url: TOGGLE_REQUEST_STATUS({ id: data?.id }),
      data: {
        status: 'inactive',
      },
    });
  };

  const handleConfirmDonation = (id: number) => {
    mutate({
      url: CONFIRM_DONATION({ id }),
      data: {},
    });
  };

  return (
    <>
      <Modal
        {...{ open, onClose: handleClose }}
        className="relative max-h-[75vh] w-[95vw] max-w-[600px] rounded-lg bg-[#FCF9F8] lg:max-h-max"
      >
        <figure className="absolute left-[50%] -top-[7%] z-20 h-16 w-16 -translate-x-[50%] overflow-hidden rounded-full border-4 border-white md:h-20 md:w-20 md:border-8">
          {data?.image ? (
            <img
              src={data.image}
              alt="User Avatar"
              className="h-full w-full object-cover"
            />
          ) : (
            <Avatar
              className="!h-full !w-full"
              name={
                data?.recipient === 'self'
                  ? data?.recipient
                  : `${data?.user?.first_name} ${data?.user?.last_name}`
              }
            />
          )}
        </figure>

        <MdClose
          onClick={handleClose}
          className="absolute top-5 right-5 z-20 cursor-pointer text-2xl"
        />

        {/* <MdKeyboardArrowLeft
          onClick={handlePrevious}
          className="absolute top-[50%] left-[2%] z-20 cursor-pointer text-2xl"
        />

        <MdKeyboardArrowRight
          onClick={handleNext}
          className="absolute top-[50%] right-[2%] z-20 cursor-pointer text-2xl"
        /> */}
        <div className="relative flex max-h-[75vh] w-[95vw] max-w-[600px] flex-col items-center gap-3 overflow-y-auto bg-transparent px-10 py-10 lg:max-h-max lg:py-14 lg:px-20">
          <Heading type="h3" className="capitalize">
            {data?.recipient === 'self'
              ? data?.recipient
              : `${data?.user?.first_name ?? data?.first_name ?? ''} ${data?.user?.last_name ?? data?.last_name ?? ''}`}
          </Heading>

          <Text type="caption" className="capitalize text-jblood-gray">
            {`${data?.gender}`}
          </Text>

          <div className="mt-5 flex w-full flex-col gap-5 rounded-lg bg-white py-5 px-5 shadow shadow-primary-light/50 lg:px-10">
            {!showDonors ? (
              <>
                <RequestDetail
                  icon="fluent:patient-24-filled"
                  title="Reason for request"
                  detail={data?.reason}
                />
                <RequestDetail
                  icon="carbon:location-filled"
                  title="Location"
                  detail={data?.hospital}
                />
                <RequestDetail
                  icon="ic:baseline-access-time-filled"
                  title="Date Needed"
                  detail={moment(data?.date).format('DD MMMM, hh:mm A')}
                />
              </>
            ) : data?.matches ? (
              data.matches.map((match, index) => (
                <div className="flex w-full flex-col flex-wrap items-start justify-between gap-3 md:flex-row md:items-center">
                  <DonorDetail
                    key={index}
                    name={`${match?.user?.first_name} ${match?.user?.last_name}`}
                    detail={
                      match?.user?.phone_number
                        ? `+${match?.user?.phone_number}`
                        : ''
                    }
                  />
                  {isActive && (
                    <>
                      {match.status !== 'confirmed' ? (
                        <Button
                          onClick={() => handleConfirmDonation(match?.id)}
                          disabled={isLoading}
                          className="!text-xs"
                          variant="outline"
                        >
                          Confirm Donation
                        </Button>
                      ) : (
                        <Text className="text-primary-main" type="caption">
                          Received!
                        </Text>
                      )}
                    </>
                  )}
                </div>
              ))
            ) : (
              <></>
            )}

            <div className="mt-10 flex w-full flex-col items-center justify-center gap-1 md:flex-row md:gap-3 lg:gap-5">
              {data?.matches ? (
                data?.matches.length > 0 ? (
                  <>
                    <Text type="caption" className="!font-interSemibold">
                      {`Donors: ${data?.matches.length} / ${data.units}`}
                    </Text>

                    <Button
                      onClick={toggleShowDonors}
                      variant="outline"
                      className="!text-xs"
                    >
                      {showDonors ? 'Show Request Details' : 'Show Donors'}
                    </Button>
                  </>
                ) : (
                  <BloodGroup group={data?.blood_group} />
                )
              ) : (
                <BloodGroup group={data?.blood_group} />
              )}
            </div>
          </div>

          {data?.status !== 'Donation Received' && data?.matches?.length === 0 && (
            <div className="mx-auto mt-5 flex w-full flex-wrap items-center justify-center gap-5">
              <div className="flex flex-col gap-1">
                {requestStatusLoading ? (
                  <Loader color="#333" />
                ) : (
                  <Toggle
                    label="Turn request on/off"
                    checked={isActive}
                    onChange={toggleActive}
                  />
                )}
              </div>
            </div>
          )}

          {/* <div className="mx-auto mt-3 flex w-full items-center justify-center gap-2">
            {requests.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === selectedIndex
                    ? 'bg-primary-light'
                    : 'bg-primary-light/30'
                }`}
              />
            ))}
          </div> */}
        </div>
      </Modal>

      <SimpleModal
        {...{
          open: showWarningModal,
          onClose: toggleWarningModal,
          title: 'Turn Request Off',
          actionButtonTitle: 'Yes',
          showActionButton: true,
          onActionButtonClicked: handleActionButtonClicked,
          loading: requestStatusLoading,
          icon: (
            <Icon
              icon="ant-design:warning-filled"
              className="text-2xl text-primary-dark"
            />
          ),
        }}
      >
        <Text>Are you sure you want to turn this blood request off?</Text>
      </SimpleModal>
    </>
  );
};

export default RequestModal;
