import { v4 as uuid } from 'uuid';
import { FC, PropsWithChildren, useContext } from 'react';
import toast from 'react-hot-toast';
import { NotificationContext } from '../../contexts/notification';
import { onMessageListener } from '../../firebase/messaging';
import Notification from '../lib/Notification';

const NotificationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { addNotification } = useContext(NotificationContext);

  onMessageListener()
    .then((payload: any) => {
      addNotification({
        id: uuid(),
        title: `${
          payload?.notification
            ? payload?.notification?.title
            : payload?.data?.title
        }`,
        body: `${
          payload?.notification
            ? payload?.notification?.body
            : payload?.data?.body
        }`,
      });

      toast(
        <Notification
          title={`${
            payload?.notification
              ? payload?.notification?.title
              : payload?.data?.title
          }`}
          body={`${
            payload?.notification
              ? payload?.notification?.body
              : payload?.data?.body
          }`}
        />,
        {
          className: 'p-0',
        }
      );
    })
    .catch((err) => console.log('failed: ', err));

  return <>{children}</>;
};

export default NotificationWrapper;
