import { FC, PropsWithChildren } from 'react';
import { NotificationProvider } from './notification';
import { PageProvider } from './page';
import { RequestProvider } from './request';
import { RoleProvider } from './role';
import { UserProvider } from './user';

const AppContext: FC<PropsWithChildren> = ({ children }) => {
  return (
    <UserProvider>
      <NotificationProvider>
        <PageProvider>
          <RoleProvider>
            <RequestProvider>{children}</RequestProvider>
          </RoleProvider>
        </PageProvider>
      </NotificationProvider>
    </UserProvider>
  );
};

export default AppContext;
