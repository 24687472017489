import { FC, useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { Reason } from '../../../../../types/Reason';
import { getRequest, patchRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import {
  ACCEPT_OR_DECLINE_REQUEST,
  GET_DECLINE_REASONS,
} from '../../../../../utils/api/urls';
import { isEmpty } from '../../../../../utils/validators';
import Select from '../../../../form/Select';
import Avatar from '../../../../lib/Avatar';
import Button from '../../../../lib/Button';
import Heading from '../../../../lib/Heading';
import Modal from '../../../../lib/Modal';
import Text from '../../../../lib/Text';
import RejectRequestModalProps from './RejectRequestModalProps';

const RejectRequestModal: FC<RejectRequestModalProps> = ({
  open,
  onClose,
  selectedIndex,
  requests,
}) => {
  const data = requests[selectedIndex]?.request;
  const [reasons, setReasons] = useState<Reason[]>([]);
  const [reason, setReason] = useState('');
  const handler = useErrorHandler();
  const queryClient = useQueryClient();
  const [error, setError] = useState('');

  useQuery(
    [queryKeys.getDeclineReasons],
    () => getRequest({ url: GET_DECLINE_REASONS }),
    {
      onSuccess(response) {
        setReasons(response?.data?.reasons || []);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const { mutate, isLoading } = useMutation(patchRequest, {
    onSuccess() {
      onClose();
      queryClient.invalidateQueries(queryKeys.getRequestsForDonor);
    },
    onError(error) {
      handler(error);
    },
  });

  const handleSubmit = () => {
    if (isEmpty(reason)) setError('Please select a reason');
    else {
      setError('');
      mutate({
        url: ACCEPT_OR_DECLINE_REQUEST({
          id: requests[selectedIndex]?.request?.id,
        }),
        data: {
          status: 'declined',
          reason_id: reason,
          decline_reason: reasons.find((item) => item.id === Number(reason))
            ?.name,
        },
      });
    }
  };

  return (
    <Modal
      {...{ open, onClose }}
      className="relative max-h-[75vh] w-[95vw] max-w-[600px] rounded-lg bg-[#FCF9F8] lg:max-h-max"
    >
      <figure className="absolute left-[50%] -top-[7%] z-20 h-16 w-16 -translate-x-[50%] overflow-hidden rounded-full border-4 border-white md:h-20 md:w-20 md:border-8">
        {data?.image ? (
          <img
            src={data?.image}
            alt="User Avatar"
            className="h-full w-full object-cover"
          />
        ) : (
          <Avatar
            className="!h-full !w-full"
            name={`${data?.user?.first_name} ${data?.user?.last_name}`}
          />
        )}
      </figure>

      <MdKeyboardArrowLeft
        onClick={onClose}
        className="absolute top-5 left-5 z-20 cursor-pointer text-2xl"
      />

      <div className="relative flex max-h-[75vh] w-[95vw] max-w-[600px] flex-col items-center gap-3 overflow-y-auto bg-transparent px-10 py-10 lg:max-h-max lg:py-14 lg:px-20">
        <Heading type="h3" className="capitalize">
          {`${data?.user?.first_name} ${data?.user?.last_name}`}
        </Heading>

        <Text type="caption" className="capitalize text-jblood-gray">
          {`${data?.gender}`}
        </Text>

        <div className="mt-5 flex min-h-[200px] w-full flex-col gap-5 rounded-lg bg-white py-5 px-5 shadow shadow-primary-light/50 lg:min-h-[300px] lg:px-10">
          <div>
            <Text className="font-interSemibold">
              Please let us know why you rejected this request
            </Text>
            <Text type="caption" className="text-jblood-gray">
              This helps us serve you better.
            </Text>
          </div>

          <Select
            options={[
              { key: 'Select Reason', value: '' },
              ...reasons.map((item) => ({
                key: item.name,
                value: item.id,
              })),
            ]}
            onChange={(event) => setReason(event.currentTarget.value)}
            value={reason}
            placeholder="Select Reason"
            error={!isEmpty(error)}
            helperText={error}
          />
        </div>

        <div className="mx-auto mt-5 flex w-full justify-center gap-5">
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RejectRequestModal;
