import { FC } from 'react';
import { BsCalendar2EventFill } from 'react-icons/bs';
import { RiGoogleFill } from 'react-icons/ri';
import { SiMicrosoftoutlook, SiYahoo } from 'react-icons/si';
import { isEmpty } from '../../../../../utils/validators';
import SimpleModal from '../../../../lib/Modal/SimpleModal';
import Text from '../../../../lib/Text';
import AddToCalendarModalProps, {
  CalendarLinkProps,
} from './AddToCalendarModalProps';

const CalendarLink: FC<CalendarLinkProps> = ({ icon, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="flex h-14 w-14 items-center justify-center rounded-full bg-gradient"
    >
      {icon}
    </a>
  );
};

const AddToCalendarModal: FC<AddToCalendarModalProps> = ({
  open,
  onClose,
  calendarReminders,
}) => {
  return (
    <SimpleModal onClose={onClose} open={open} title="Add to Calendar">
      <Text type="caption">
        Add a reminder for this donation request to your calendar of choice.
      </Text>

      <div className="mt-10 flex w-full justify-center gap-5">
        {!isEmpty(`${calendarReminders?.google}`) && (
          <CalendarLink
            link={`${calendarReminders?.google}`}
            icon={<RiGoogleFill className="text-2xl text-white" />}
          />
        )}

        {!isEmpty(`${calendarReminders?.outlook}`) && (
          <CalendarLink
            link={`${calendarReminders?.outlook}`}
            icon={<SiMicrosoftoutlook className="text-2xl text-white" />}
          />
        )}

        {!isEmpty(`${calendarReminders?.yahoo}`) && (
          <CalendarLink
            link={`${calendarReminders?.yahoo}`}
            icon={<SiYahoo className="text-2xl text-white" />}
          />
        )}

        {!isEmpty(`${calendarReminders?.ics}`) && (
          <CalendarLink
            link={`${calendarReminders?.ics}`}
            icon={<BsCalendar2EventFill className="text-2xl text-white" />}
          />
        )}
      </div>
    </SimpleModal>
  );
};

export default AddToCalendarModal;
