import { Icon } from '@iconify/react';
import { FC } from 'react';
import Text from '../../../../lib/Text';
import { RequestDetailProps } from './RequestModalProps';

const RequestDetail: FC<RequestDetailProps> = ({ icon, title, detail }) => {
  return (
    <div className="flex items-center gap-3">
      <Icon icon={icon} className="text-2xl text-primary-main lg:text-2xl" />

      <div>
        <Text type="caption" className="text-jblood-gray lg:text-xs">
          {title}
        </Text>

        {title === 'Phone Number' ? (
          <a href={`tel:${detail}`} target="_blank" rel="noreferrer">
            <Text className="!font-interSemibold !text-sm text-primary-main underline">
              {detail}
            </Text>
          </a>
        ) : (
          <Text className="!font-interSemibold !text-xs lg:!text-sm">
            {detail}
          </Text>
        )}
      </div>
    </div>
  );
};

export default RequestDetail;
