import { useState } from 'react';
import { useQuery } from 'react-query';
import RemindersList from '../../../components/auth/Donor/Reminders/RemindersList';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
import Pagination from '../../../components/lib/Pagination';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { Reminder } from '../../../types/Reminder';
import { getRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import { GET_REMINDERS } from '../../../utils/api/urls';

const Reminders = () => {
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getReminders, page],
    () => getRequest({ url: GET_REMINDERS(`?limit=20&page=${page}`) }),
    {
      onSuccess(response) {
        setReminders(response?.data?.reminders || []);
        setCount(response?.data?.page_info?.total_pages || 0);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  return (
    <PageTitle title="Reminders">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Reminders">
          <Pagination {...{ page, setPage, count }} />
          <RemindersList {...{ reminders, isLoading }} />
        </Card>
      </div>
    </PageTitle>
  );
};

export default Reminders;
