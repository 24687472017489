import { RiArrowRightLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
// import FeaturedRecipient from '../../../assets/images/FeaturedRecipient.jpg';
import Button from '../../lib/Button';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';

const FeaturedRecipientStory = () => {
  return (
    <section className="px-5 py-10 md:py-20 md:px-10">
      <div className="max mx-auto flex flex-col items-center justify-center gap-20 lg:flex-row">
        {/* <figure className="aspect-[10/9] max-w-[450px] overflow-hidden">
          <img
            src={FeaturedRecipient}
            alt=""
            className="h-full w-full object-cover"
          />
        </figure> */}

        <div className="flex flex-col items-center gap-5 text-center md:gap-7 lg:max-w-[70vw]">
          <Heading type="h2">Featured Recipient Story</Heading>
          <Text type="body2">
            At about 10:14 pm on Friday the 4th of December 2020, I was notified
            by the J Blood Match Bot that my assistance was required by Mr. Paul
            at Garki Hospital Abuja, for voluntary blood donation. The reason?
            Sickle cell disorder. I accepted the request with excitement.
          </Text>
          <Link className="" to="recipient-story">
            <Button variant="outline" size="medium">
              <div className="flex items-center justify-center gap-2">
                <span>Read More</span>
                <RiArrowRightLine size={20} />
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedRecipientStory;
