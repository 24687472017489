import { FC, FormEvent, useState } from 'react';
import { bloodGroups, genders } from '../../../../../utils/constants';
import {
  isEmpty,
  validateRequestDonorForm1Inputs,
} from '../../../../../utils/validators';
import Input from '../../../../form/Input';
import Select from '../../../../form/Select';
import Button from '../../../../lib/Button';
import { FormProps } from './FormsProps';

const initialState = {
  gender: '',
  blood_group: '',
  first_name: '',
  last_name: '',
};

const Form1: FC<FormProps> = ({
  handleNext,
  payload: generalPayload,
  setPayload: setGeneralPayload,
}) => {
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } =
      validateRequestDonorForm1Inputs(payload);

    if (valid) {
      setGeneralPayload({
        ...generalPayload,
        ...payload,
      });
      handleNext && handleNext();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <Input
        label="Recipient's First Name"
        placeholder="Enter Recipient's First Name"
        name="first_name"
        value={payload.first_name}
        error={!isEmpty(errors.first_name)}
        helperText={errors.first_name}
        onChange={handleChange}
      />
      <Input
        label="Recipient's Last Name"
        placeholder="Enter Recipient's Last Name"
        name="last_name"
        value={payload.last_name}
        error={!isEmpty(errors.last_name)}
        helperText={errors.last_name}
        onChange={handleChange}
      />
      <Select
        label="Recipient's Gender"
        placeholder="Select Gender"
        name="gender"
        value={payload.gender}
        error={!isEmpty(errors.gender)}
        helperText={errors.gender}
        options={[{ key: 'Select Gender', value: '' }, ...genders]}
        onChange={handleChange}
      />
      <Select
        label="Recipient's Blood Group"
        placeholder="Select Blood Group"
        name="blood_group"
        value={payload.blood_group}
        error={!isEmpty(errors.blood_group)}
        helperText={errors.blood_group}
        options={[{ key: 'Select Blood Group', value: '' }, ...bloodGroups]}
        onChange={handleChange}
      />

      <Button
        size="medium"
        variant="solid"
        className="mx-auto mt-10 w-full lg:w-auto"
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </>
  );
};

export default Form1;
