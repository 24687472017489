import { FC, useContext } from 'react';
import { MdCheck } from 'react-icons/md';
import Avatar from '../../lib/Avatar';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import navLinks from './links';
import { ActionSheetNavLinksProps } from './NavbarProps';
import NavLink from './NavLink';
import { RoleContext } from '../../../contexts/role';
import { UserContext } from '../../../contexts/user';

const ActionSheetNavLinks: FC<ActionSheetNavLinksProps> = ({ setOpen }) => {
  const { role, setRole } = useContext(RoleContext);
  const { user, logout } = useContext(UserContext);

  const handleLogout = () => {
    setOpen(false);
    logout();
  };

  const handleSwitchRole = () => {
    setOpen(false);
    if (role === 'donor') {
      setRole('recipient');
    } else {
      setRole('donor');
    }
  };

  return (
    <div className="flex flex-col gap-3 px-2 pb-1">
      <div className="mb-7 flex w-full items-center justify-between">
        <div className="flex items-center gap-1">
          <Avatar
            className="!h-10 !w-10 lg:hidden"
            name={`${user?.first_name} ${user?.last_name}`}
            image={user?.image}
          />

          <div className="flex flex-col">
            <Heading type="h3">{`${user?.first_name} ${user?.last_name}`}</Heading>
            <Text className="!text-xs capitalize text-gray-500" type="caption">
              {role} account
            </Text>
          </div>
        </div>

        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gradient">
          <MdCheck className="text-lg text-white" />
        </div>
      </div>

      {navLinks.map((link, index) => (
        <NavLink key={index} onClose={() => setOpen(false)} url={link.url}>
          {link.label}
        </NavLink>
      ))}

      <NavLink onClose={handleSwitchRole} url={'/app/dashboard'}>
        Switch Account Type
      </NavLink>

      <hr />

      <NavLink onClose={handleLogout} url={'/'}>
        Logout
      </NavLink>
    </div>
  );
};

export default ActionSheetNavLinks;
