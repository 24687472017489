import HeaderImage from '../../../assets/images/header-image.png';
import FloatingCircleBig from '../../../assets/icons/FloatingCircleBig.svg';
import FloatingCircleMedium from '../../../assets/icons/FloatingCircleMedium.svg';
import FloatingCircleSmall from '../../../assets/icons/FloatingCircleSmall.svg';
import Button from '../../lib/Button';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="max relative mx-auto flex w-full flex-col items-center justify-between gap-10 p-10 pt-20 md:flex-row md:p-10 md:pt-10">
      <img
        src={FloatingCircleBig}
        alt=""
        className="absolute top-[5%] left-[65%] md:top-[14%] md:left-[41%]"
      />
      <img
        src={FloatingCircleMedium}
        alt=""
        className="absolute top-[2%] left-[90%] md:top-[35%] md:left-[50%]"
      />
      <img
        src={FloatingCircleSmall}
        alt=""
        className="absolute top-[25%] left-[12%]  md:top-[auto] md:bottom-[15%] md:left-[35%]"
      />

      <div className="z-20 flex w-full max-w-[700px] flex-col gap-10 text-center md:w-3/5 md:text-left">
        <Heading type="h1">
          You don’t have to be a Doctor to save lives!
        </Heading>
        <Text type="subheading">
          J Blood Match is a platform that connects non-remunerated voluntary
          blood donors to matching recipients at the point of need.
        </Text>
        <div>
          <Button
            size="medium"
            variant="gradient"
            onClick={() => navigate('/signup')}
          >
            Become a donor
          </Button>
        </div>
      </div>

      <figure className="aspect-square w-full max-w-[300px] md:w-2/5 md:max-w-[450px]">
        <img src={HeaderImage} alt="JBM Donor composition" />
      </figure>
    </header>
  );
};

export default Header;
