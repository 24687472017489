import { FC, useContext } from 'react';
import { NavLinksProps } from './NavbarProps';
import Text from '../../lib/Text';
import { NotificationContext } from '../../../contexts/notification';
import { IoNotifications } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const MAX_NO_OF_NOTIFICATIONS_TO_BE_DISPLAYED = 6;

const Notifications: FC<NavLinksProps> = ({
  open,
  setOpen,
  setPopperElement,
  styles,
  attributes,
}) => {
  const { notifications, clearAllNotifications, removeNotification } =
    useContext(NotificationContext);

  return open ? (
    <div
      ref={setPopperElement}
      style={styles.popper}
      className="z-40 flex min-w-[250px] flex-col gap-3 rounded-md border border-[#DEDEE6] bg-white py-5 shadow-sm transition-none"
      onMouseLeave={() => setOpen(false)}
      {...attributes.popper}
    >
      <div className="flex w-full items-center justify-between px-7">
        <Text className="!font-soraBold" type="caption">
          Notifications
        </Text>

        {notifications.length > 0 && (
          <button onClick={clearAllNotifications}>
            <Text
              className="text-xs underline hover:text-primary-main"
              type="caption"
            >
              Mark all as read
            </Text>
          </button>
        )}
      </div>

      <hr className="border-jblood-gray" />

      <div className="h-full max-h-[400px] w-[400px] overflow-y-auto px-7">
        {notifications.length === 0 ? (
          <div className="flex min-h-[100px] items-center justify-center">
            <Text type="caption" className="font-soraSemibold">
              You do not have any notification
            </Text>
          </div>
        ) : (
          [...notifications]
            .splice(0, MAX_NO_OF_NOTIFICATIONS_TO_BE_DISPLAYED)
            .map((notification, index) => (
              <div
                className="flex w-full items-start gap-2 border-b py-3"
                key={index}
              >
                <IoNotifications className="text-2xl text-primary-main" />

                <div className="flex flex-1 flex-col">
                  <Text className="!font-soraSemibold" type="caption">
                    {notification.title}
                  </Text>
                  <Text type="caption">{notification.body}</Text>
                </div>

                <button onClick={() => removeNotification(notification.id)}>
                  <Text
                    className="text-xs underline hover:text-primary-main"
                    type="caption"
                  >
                    Read
                  </Text>
                </button>
              </div>
            ))
        )}

        {notifications.length > 0 && (
          <div className="flex w-full justify-center px-7 pt-3">
            <Link to="/app/notifications" onClick={() => setOpen(false)}>
              <Text
                type="caption"
                className="font-soraSemibold underline hover:text-primary-main"
              >
                View All
              </Text>
            </Link>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Notifications;
