import Card from '../../../lib/Card';
import Text from '../../../lib/Text';
import Medal from '../../../../assets/images/Medal.png';
import Heart from '../../../../assets/images/Heart.png';
import LifeSaver from '../../../../assets/images/LifeSaver.png';
import Zeal from '../../../../assets/images/Zeal.png';
import { MdShare } from 'react-icons/md';
import { useState } from 'react';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { useQuery } from 'react-query';
import queryKeys from '../../../../utils/api/queryKeys';
import { getRequest } from '../../../../utils/api/calls';
import { GET_DONATATION_STATS } from '../../../../utils/api/urls';

const Achievements = () => {
  const [count, setCount] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const handler = useErrorHandler();

  useQuery(
    [queryKeys.getdonationStats],
    () => getRequest({ url: GET_DONATATION_STATS }),
    {
      onSuccess(response) {
        setCount(response?.data?.confirmed_donation?.length || 0);
        setAttempts(response?.data?.attempted_donation?.count || 0);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  return (
    <>
      <Card
        title={count > 0 ? 'Donation Confirmed' : 'Confirmed Donations'}
        className="break-inside mb-10 md:mb-5"
      >
        {count > 0 ? (
          <div className="mt-10 flex flex-col items-center justify-center gap-10 text-center">
            <Text className="!font-interSemibold" type="caption">
              Thank you for donating!
            </Text>

            <figure className="flex h-32 w-32 items-center justify-center ">
              <img
                src={Heart}
                alt="Heart"
                className="h-full w-full object-cover"
              />
            </figure>

            <Text className="max-w-[32ch] text-jblood-gray" type="caption">
              You are truly a SUPERHERO, because you are the reason for
              someone’s heartbeat.
            </Text>
          </div>
        ) : (
          <div className="mt-10 flex flex-col items-center justify-center gap-5 text-center">
            <Text className="!font-interSemibold" type="caption">
              No Donation
            </Text>

            <Text className="max-w-[32ch] text-jblood-gray" type="caption">
              You haven't made any donation yet!
            </Text>
          </div>
        )}
      </Card>

      <Card
        title="Achievements"
        className="break-inside mb-10 md:mb-5"
        titleComponent={<MdShare size={20} color="#8B8887" />}
      >
        {count > 2 ? (
          <div className="mt-10 flex flex-col items-center justify-center gap-10 text-center">
            <Text className="!font-interSemibold" type="caption">
              Congratulations!
            </Text>

            <figure className="flex h-32 w-32 items-center justify-center">
              <img
                src={Medal}
                alt="Medal"
                className="h-full w-full object-cover"
              />
            </figure>

            <Text className="max-w-[32ch] text-jblood-gray" type="caption">
              You earned a Hero badge for donating blood up to three times this
              year.
            </Text>
          </div>
        ) : count === 1 ? (
          <div className="mt-10 flex flex-col items-center justify-center gap-10 text-center">
            <Text className="!font-interSemibold" type="caption">
              Congratulations!
            </Text>

            <figure className="flex h-32 w-32 items-center justify-center">
              <img
                src={LifeSaver}
                alt="Medal"
                className="h-full w-full object-cover"
              />
            </figure>

            <Text className="max-w-[32ch] text-jblood-gray" type="caption">
              You earned a Life Saver badge for donating blood once this year.
            </Text>
          </div>
        ) : attempts > 1 ? (
          <div className="mt-10 flex flex-col items-center justify-center gap-10 text-center">
            <Text className="!font-interSemibold" type="caption">
              Congratulations!
            </Text>

            <figure className="flex h-32 w-32 items-center justify-center">
              <img
                src={Zeal}
                alt="Medal"
                className="h-full w-full object-cover"
              />
            </figure>

            <Text className="max-w-[32ch] text-jblood-gray" type="caption">
              You earned a Zeal badge for attempting to donate blood.
            </Text>
          </div>
        ) : (
          <div className="mt-10 flex flex-col items-center justify-center gap-5 text-center">
            <Text className="!font-interSemibold" type="caption">
              No Achievement yet
            </Text>

            <Text className="max-w-[32ch] text-jblood-gray" type="caption">
              Keep donating to earn earn a badge.
            </Text>
          </div>
        )}
      </Card>
    </>
  );
};

export default Achievements;
