import { Icon } from '@iconify/react';
import { FC } from 'react';
import Text from '../../../../lib/Text';
import { DonorDetailProps } from './RequestModalProps';

const DonorDetail: FC<DonorDetailProps> = ({ name, detail }) => {
  return (
    <div className="flex items-center gap-3">
      <Icon
        icon="carbon:phone-filled"
        className="text-2xl text-primary-main lg:text-2xl"
      />

      <div>
        <Text type="caption" className="text-jblood-gray lg:text-xs">
          {name}
        </Text>

        <a href={`tel:${detail}`} target="_blank" rel="noreferrer">
          <Text className="!font-interSemibold !text-sm text-primary-main underline">
            {detail}
          </Text>
        </a>
      </div>
    </div>
  );
};

export default DonorDetail;
