import { FC, useContext } from 'react';
import { MdClose } from 'react-icons/md';
import { RoleContext } from '../../../contexts/role';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Logo from '../../app/Navbar/Logo';
import { donorLinks, publicLinks, recipientLinks } from './links';
import SidebarLink from './SidebarLink';
import SidebarProps from './SidebarProps';

const Sidebar: FC<SidebarProps> = ({ open, onClose }) => {
  const { role } = useContext(RoleContext);
  const smallScreen = useMediaQuery('(max-width: 1024px)');

  const links = role === 'donor' ? donorLinks : recipientLinks;

  return (
    <aside
      className={`absolute top-0 left-0 z-[90] flex h-full min-h-screen w-full flex-col gap-5 overflow-y-auto bg-[#FCF9F8] py-5 lg:static lg:max-w-[300px] lg:py-10 ${
        open
          ? 'translate-x-0 animate-slide-right'
          : 'translate-x-[-100%] animate-slide-right-reverse lg:translate-x-[0] lg:animate-none'
      }`}
    >
      <div className="flex w-full items-center justify-between px-5 lg:justify-center lg:px-10">
        <Logo url="/app/dashboard" />
        <MdClose size={25} onClick={onClose} className="lg:hidden" />
      </div>

      <div className="w-full flex-1 flex-col gap-5 overflow-y-auto px-5 md:max-h-full lg:px-10">
        <div className="flex w-full flex-col gap-3">
          {links.map((link, index) => (
            <div
              key={index}
              onClick={() => {
                if (smallScreen) {
                  onClose();
                } else {
                  return;
                }
              }}
            >
              <SidebarLink
                {...{
                  icon: link.icon,
                  label: link.label,
                  url: link.url,
                }}
              />
            </div>
          ))}
        </div>

        <hr className="my-5 border-jblood-gray" />

        <div className="flex w-full flex-col gap-3">
          {publicLinks.map((link, index) => (
            <div
              key={index}
              onClick={() => {
                if (smallScreen) {
                  onClose();
                } else {
                  return;
                }
              }}
            >
              <SidebarLink
                {...{
                  icon: link.icon,
                  label: link.label,
                  url: link.url,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
