import { FC, useContext } from 'react';
import NavLink from './NavLink';
import navLinks from './links';
import { NavLinksProps } from './NavbarProps';
import { RoleContext } from '../../../contexts/role';
import Text from '../../lib/Text';
import { MdCheck } from 'react-icons/md';
import { UserContext } from '../../../contexts/user';

const NavLinks: FC<NavLinksProps> = ({
  open,
  setOpen,
  setPopperElement,
  styles,
  attributes,
}) => {
  const { user, logout } = useContext(UserContext);
  const { role, setRole } = useContext(RoleContext);

  const handleLogout = () => {
    setOpen(false);
    logout();
  };

  const handleSwitchRole = () => {
    setOpen(false);
    if (role === 'donor') {
      setRole('recipient');
    } else {
      setRole('donor');
    }
  };

  return open ? (
    <div
      ref={setPopperElement}
      style={styles.popper}
      className="z-40 flex min-w-[250px] flex-col gap-3 rounded-md border border-[#DEDEE6] bg-white py-5 px-7 shadow-sm  transition-none"
      onMouseLeave={() => setOpen(false)}
      {...attributes.popper}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-1">
          <div className="flex flex-col">
            <Text className="!font-soraBold" type="caption">
              {`${user?.first_name} ${user?.last_name}`}
            </Text>
            <Text className="!text-xs capitalize text-gray-500" type="caption">
              {role} account
            </Text>
          </div>
        </div>

        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-gradient">
          <MdCheck className="text-xs text-white" />
        </div>
      </div>

      <hr className="border-jblood-gray" />

      {navLinks.map((link, index) => (
        <NavLink key={index} onClose={() => setOpen(false)} url={link.url}>
          {link.label}
        </NavLink>
      ))}

      <NavLink onClose={handleSwitchRole} url={'/app/dashboard'}>
        Switch Account Type
      </NavLink>

      <hr className="border-jblood-gray" />

      <NavLink onClose={handleLogout} url={'/login'}>
        Logout
      </NavLink>
    </div>
  ) : (
    <></>
  );
};

export default NavLinks;
