import { FC } from 'react';
import Input from '../../../form/Input';
import Select from '../../../form/Select';
import { PaymentInputProps } from './PaymentInputProps';

const PaymentInput: FC<PaymentInputProps> = ({
  currencies,
  error,
  helperText,
  currencyValue,
  onCurrencyChange,
  amountValue,
  onAmountChange,
}) => {
  return (
    <div className="">
      <label className="font-interSemibold text-xs md:text-sm">
        Amount to charge
      </label>

      <div className="flex items-center gap-5">
        <Select
          placeholder="Select Currency"
          name="currency"
          value={currencyValue}
          error={error}
          options={[
            { key: 'Select', value: '' },
            ...currencies.map((currency) => ({
              key: `${currency.value}`,
              value: currency.value,
            })),
          ]}
          onChange={onCurrencyChange}
          className="w-[20vw] max-w-[150px]"
        />

        <div className="flex-1">
          <Input
            type="number"
            min={0}
            placeholder="Enter Amount"
            name="amount"
            value={amountValue}
            error={error}
            onChange={onAmountChange}
          />
        </div>
      </div>

      {error && helperText && (
        <span
          className={`text-xs ${
            error ? 'text-primary-dark' : 'text-jblood-black'
          }`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default PaymentInput;
