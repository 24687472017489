import PageTitle from '../../components/containers/PageTitle';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';

const DonationProcess = () => {
  return (
    <PageTitle title="Donation Process">
      <div className="max mx-auto flex flex-col items-center gap-5 p-5 py-10 md:p-10 lg:px-20 lg:py-16">
        <Heading underlined className="w-[60vw] text-center md:mb-10 md:w-auto">
          Donation Process
        </Heading>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Text>
            Donating blood is a simple thing to do, but it can make a big
            difference in the lives of others. The donation process from the
            time you arrive until the time you leave takes about an hour. The
            donation itself is only about 8-10 minutes on average. The steps
            involved in the entire donation process include registration at a
            health centre closest to you, mandatory health checkup and tests,
            donation and refreshments.
          </Text>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Registration</Heading>
          <Text>
            You will complete donor registration, which includes information
            such as your name, address, phone number, and donor identification
            number (if you have one).
          </Text>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Mandatory health checkup and tests</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                You will answer some questions during a private and confidential
                interview about your health history.
              </Text>
            </li>
            <li>
              <Text>
                You will have your temperature, hemoglobin, blood pressure,
                pulse and other vitals checked.
              </Text>
            </li>
            <li>
              <Text>
                A number of medical tests will be run on your blood sample to
                ensure that you are fit to donate blood.
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Donation</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                An area on your arm will cleansed and a brand–new sterile needle
                will be inserted for the blood draw. This feels like a quick
                pinch and is
              </Text>
            </li>
            <li>
              <Text>
                You will have some time to relax while the bag is filling. (For
                a whole blood donation, it is about 8-10 minutes)
              </Text>
            </li>
            <li>
              <Text>
                When approximately a pint of blood has been collected, the
                donation is complete and a health personnel will place a bandage
                on
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Refreshments</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                You will spend a few minutes consuming some fluids to allow your
                body adjust to the slight decrease in fluid volume.
              </Text>
            </li>
            <li>
              <Text>
                After 10-15 minutes you can then leave the donation site and
                continue with your normal daily activities.
              </Text>
            </li>
            <li>
              <Text>
                Enjoy the feeling of accomplishment knowing that you have helped
                save lives.
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </PageTitle>
  );
};

export default DonationProcess;
