import React, { createContext, useReducer } from 'react';
import { Notification } from '../../types/Notification';

type NotificationState = {
  notifications: Notification[];
};

type AddNotificationAction = {
  type: 'ADD';
  notification: Notification;
};

type RemoveNotificationAction = {
  type: 'REMOVE';
  id: string;
};

type ClearAllNotificationsAction = {
  type: 'CLEAR';
};

type ActionType =
  | AddNotificationAction
  | RemoveNotificationAction
  | ClearAllNotificationsAction;

const InitialState: NotificationState = {
  notifications: [],
};

const NotificationContext: React.Context<{
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  removeNotification: (notificationId: string) => void;
  clearAllNotifications: () => void;
}> = createContext({
  notifications: [] as Notification[],
  addNotification: (notification: Notification) => {},
  removeNotification: (notificationId: string) => {},
  clearAllNotifications: () => {},
});

const NotificationReducer = (state: NotificationState, action: ActionType) => {
  switch (action.type) {
    case 'ADD':
      return {
        notifications: [action.notification, ...state.notifications],
      };

    case 'REMOVE':
      const newNotifications = [...state.notifications];
      const notificationIndex = newNotifications.findIndex(
        (notification) => notification.id === action.id
      );
      newNotifications.splice(notificationIndex, 1);

      return {
        notifications: newNotifications,
      };

    case 'CLEAR':
      return {
        notifications: [],
      };

    default:
      return state;
  }
};

const NotificationProvider = (props: any) => {
  const [state, dispatch] = useReducer(NotificationReducer, InitialState);

  const addNotification = (notification: Notification) => {
    dispatch({
      type: 'ADD',
      notification,
    });
  };

  const removeNotification = (notificationId: string) => {
    dispatch({
      type: 'REMOVE',
      id: notificationId,
    });
  };

  const clearAllNotifications = () => {
    dispatch({
      type: 'CLEAR',
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications: state.notifications,
        addNotification,
        removeNotification,
        clearAllNotifications,
      }}
      {...props}
    />
  );
};

export { NotificationContext, NotificationProvider };
