import React, { createContext, useReducer } from 'react';
import { Request } from '../../types/Request';

type RequestState = {
  request: Request | null;
};

type ActionType = {
  type: 'SET';
  request: Request;
};

const InitialState: RequestState = {
  request: null,
};

const RequestContext: React.Context<{
  request: null | Request;
  setRequest: (request: Request) => void;
}> = createContext({
  request: null as Request | null,
  setRequest: (request: Request) => {},
});

const RequestReducer = (state: RequestState, action: ActionType) => {
  switch (action.type) {
    case 'SET':
      return {
        request: action.request,
      };

    default:
      return state;
  }
};

const RequestProvider = (props: any) => {
  const [state, dispatch] = useReducer(RequestReducer, InitialState);

  const setRequest = (request: Request) => {
    dispatch({
      type: 'SET',
      request,
    });
  };

  return (
    <RequestContext.Provider
      value={{
        request: state.request,
        setRequest,
      }}
      {...props}
    />
  );
};

export { RequestContext, RequestProvider };
