import { FormEvent, useContext, useState } from 'react';
import { AiFillCamera } from 'react-icons/ai';
import { useMutation, useQuery } from 'react-query';
import toaster, { toast } from 'react-hot-toast';
import Profile from '../../../components/auth/EditProfile/Profile';
import Vitals from '../../../components/auth/EditProfile/Vitals';
import PageTitle from '../../../components/containers/PageTitle';
import Avatar from '../../../components/lib/Avatar';
import Card from '../../../components/lib/Card';
import Heading from '../../../components/lib/Heading';
import { UserContext } from '../../../contexts/user';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { getRequest, patchRequest } from '../../../utils/api/calls';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { uploadFile } from '../../../firebase/storage';
import { GET_USER, UPDATE_USER } from '../../../utils/api/urls';
import Loader from '../../../components/lib/Loader';
import queryKeys from '../../../utils/api/queryKeys';
import ContainedLoader from '../../../components/lib/Loader/ContainedLoader';

const EditProfile = () => {
  const { user: storedUser, login } = useContext(UserContext);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [imagefile, setImageFile] = useState<File | null>(null);
  const [imageIsUploading, setImageIsUploading] = useState(false);
  const [user, setUser] = useState({
    first_name: storedUser?.first_name,
    last_name: storedUser?.last_name,
    image: storedUser?.image,
    gender: storedUser?.gender,
    dob: storedUser?.dob,
    blood_group: storedUser?.blood_group,
    state: {
      name: '',
    },
    height: '',
    blood_pressure: '',
    address: '',
  });
  const handler = useErrorHandler();

  const { mutate, isLoading: updateImageIsLoading } = useMutation(
    patchRequest,
    {
      onSuccess(response) {
        toast.success('Image uploaded successfully');

        if (response?.data?.user) {
          login(response?.data?.user);
        }
      },
      onError(error) {
        setImageFile(null);
        handler(error);
      },
    }
  );

  const { isLoading } = useQuery(
    [queryKeys.getUser],
    () => getRequest({ url: GET_USER }),
    {
      onSuccess(response) {
        if (response?.data?.user) {
          setUser(response?.data?.user);
        }
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const handleImageUpload = async (file: File) => {
    setImageIsUploading(true);

    await uploadFile({
      file,
      onError() {
        setImageIsUploading(false);
        toaster.error('Something went wrong, try again later.');
      },
      onSuccess(url) {
        mutate({
          url: UPDATE_USER,
          data: {
            image: url,
          },
        });
        setImageIsUploading(false);
      },
    });
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];

      if (file) {
        setImageFile(file);
        handleImageUpload(file);
      }
    } else {
      return;
    }
  };

  return (
    <PageTitle title="Edit Profile">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Edit Profile" mobile={smallScreen}>
          {isLoading ? (
            <ContainedLoader />
          ) : (
            <div className="flex w-full flex-col items-center gap-10 md:py-10">
              <div className="flex flex-col items-center gap-5">
                <label
                  htmlFor="profile-image"
                  className="group relative h-16 w-16 cursor-pointer overflow-hidden rounded-full"
                >
                  <Avatar
                    name={`${user?.first_name || ''} ${user?.last_name || ''}`}
                    image={
                      imagefile ? URL.createObjectURL(imagefile) : user?.image
                    }
                    className="!h-16 !w-16"
                  />

                  <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/10 group-hover:bg-black/50">
                    {updateImageIsLoading || imageIsUploading ? (
                      <Loader small />
                    ) : (
                      <AiFillCamera size={20} color="#fff" />
                    )}
                  </div>

                  <input
                    type="file"
                    name="profile-image"
                    id="profile-image"
                    className="hidden"
                    accept=".png,.jpg,.jpeg,.gif"
                    onChange={handleChange}
                    disabled={updateImageIsLoading || imageIsUploading}
                  />
                </label>

                <Heading type="h3">{`${user?.first_name || ''} ${
                  user?.last_name || ''
                }`}</Heading>
              </div>

              <Profile {...{ user }} />

              <div className="flex w-full flex-col items-center gap-5">
                <Heading type="h3" className="text-center">
                  Update vitals from last blood donation
                </Heading>

                <Vitals {...{ user }} />
              </div>
            </div>
          )}
        </Card>
      </div>
    </PageTitle>
  );
};

export default EditProfile;
