import { FC } from 'react';
import { IoNotifications } from 'react-icons/io5';
import Text from '../Text';
import NotificationProps from './NotificationProps';

const Notification: FC<NotificationProps> = ({ title, body }) => {
  return (
    <div className="flex min-w-[250px] max-w-[300px] items-center gap-3 p-1">
      <IoNotifications className="text-2xl text-primary-main" />

      <div className="flex flex-col">
        <Text type="body2" className="capitalize">
          {title.length > 30 ? `${title.substring(0, 30)}...` : title}
        </Text>
        <Text type="caption">
          {body.length > 60 ? `${body.substring(0, 60)}...` : body}
        </Text>
      </div>
    </div>
  );
};

export default Notification;
