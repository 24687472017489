import { FC, Fragment } from 'react';
import StepperProps from './StepperProps';

const Stepper: FC<StepperProps> = ({ activeStep, steps }) => {
  return (
    <div className="mb-10 flex w-full items-center">
      {Array.from(Array(steps).keys()).map((item) => (
        <Fragment key={item}>
          {item !== 0 && (
            <div
              className={`h-0.5 flex-1 ${
                activeStep > item ? 'bg-primary-dark' : 'bg-gray-300'
              }`}
            />
          )}

          {item + 1 === activeStep ? (
            <div className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-primary-main bg-transparent">
              <div className="h-3 w-3 rounded-full bg-primary-main" />
            </div>
          ) : (
            <div
              className={`h-6 w-6 rounded-full ${
                activeStep > item + 1
                  ? 'bg-gradient'
                  : 'border-2 border-gray-300 bg-transparent'
              }`}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Stepper;
