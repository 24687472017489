import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DonationHistoryCardProps } from './DonationHistoryListProps';
import Blood from '../../../../../assets/icons/Blood.svg';
import Text from '../../../../lib/Text';
import { MdKeyboardArrowDown } from 'react-icons/md';
import DonationHistoryDetail from './DonationHistoryDetail';
import moment from 'moment';
import { ImArrowRight2 } from 'react-icons/im';
import { useQuery } from 'react-query';
import queryKeys from '../../../../../utils/api/queryKeys';
import { getRequest } from '../../../../../utils/api/calls';
import { GET_FFEDBACK_STATUS } from '../../../../../utils/api/urls';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { RequestContext } from '../../../../../contexts/request';

const DonationHistoryCard: FC<DonationHistoryCardProps> = ({
  reason,
  date,
  gender,
  hospital,
  user,
  variant,
  id,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const { setRequest } = useContext(RequestContext);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const handler = useErrorHandler();
  const navigate = useNavigate();

  const toggleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  useQuery(
    [queryKeys.getFeedbackStatus, id],
    () => getRequest({ url: GET_FFEDBACK_STATUS({ requestId: id }) }),
    {
      onSuccess(response) {
        setFeedbackGiven(response?.data || false);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  const handleGiveFeedback = () => {
    setRequest({
      reason,
      date,
      gender,
      hospital,
      user,
      id,
      ...rest,
    });
    navigate(`/app/feedback/${id}`);
  };

  return (
    <button
      className={`relative flex items-start gap-5 overflow-hidden rounded-lg border bg-[#FCF9F8] px-3 py-5 pl-5 lg:p-5 lg:pl-7 ${
        open
          ? variant === 'dark'
            ? 'border-primary-main'
            : 'border-primary-light'
          : ''
      }`}
      onClick={toggleOpen}
    >
      <div
        className={`absolute top-0 left-0 h-full w-2 ${
          variant === 'dark' ? 'bg-primary-main' : 'bg-primary-light'
        }`}
      />

      <img src={Blood} alt="blood" className="h-5 w-3" />

      <div className="flex flex-1 flex-col gap-1 text-left">
        <Text className="!font-interSemibold capitalize">Blood Donation</Text>
        <Text className="text-jblood-gray" type="caption">
          {moment(date).format('DD MMMM YYYY, hh:mm A')}
        </Text>

        {open && (
          <div className="mt-5 flex flex-col gap-3">
            <DonationHistoryDetail
              title="Recipient"
              detail={`${user?.first_name} ${user?.last_name}`}
            />
            <DonationHistoryDetail title="Gender" detail={gender} />
            <DonationHistoryDetail title="Condition" detail={reason} />
            <DonationHistoryDetail title="Location" detail={hospital} />

            <div className="flex w-full justify-end lg:hidden">
              {!feedbackGiven && (
                <div
                  role="button"
                  onClick={handleGiveFeedback}
                  className="cursor-pointer"
                >
                  <Text
                    type="caption"
                    className="flex items-center gap-2 text-green-500"
                  >
                    Give Feedback
                    <ImArrowRight2 size={15} />
                  </Text>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="hidden flex-col items-end gap-2 lg:flex">
        <Text type="caption" className="flex cursor-pointer items-center gap-2">
          {!open && 'Details'}
          <MdKeyboardArrowDown
            size={20}
            className={`text-jblood-black ${
              open ? 'rotate-180' : 'rotate-0'
            } duration-200`}
          />
        </Text>

        {open && !feedbackGiven && (
          <div
            role="button"
            onClick={handleGiveFeedback}
            className="cursor-pointer"
          >
            <Text
              type="caption"
              className="flex items-center gap-2 text-green-500"
            >
              Give Feedback
              <ImArrowRight2 size={15} />
            </Text>
          </div>
        )}
      </div>

      <MdKeyboardArrowDown
        size={20}
        className={`absolute right-3 top-3 text-jblood-black lg:hidden ${
          open ? 'rotate-180' : 'rotate-0'
        } duration-200`}
      />
    </button>
  );
};

export default DonationHistoryCard;
