import { FC, useCallback } from 'react';
import { ImArrowRight2 } from 'react-icons/im';
import Avatar from '../../../../lib/Avatar';
import Chip from '../../../../lib/Chip';
import Text from '../../../../lib/Text';
import { RequestsCardProps } from './RequestsProps';

const StatusChip = ({
  status,
  units,
  matches,
}: {
  status: string;
  units: number;
  matches: number;
}) => {
  switch (status) {
    case 'accepted':
      return (
        <Chip
          label={`Donation accepted`}
          className="!border-primary-main bg-primary-main/10 text-primary-main"
        />
      );

    case 'match':
      return (
        <Chip
          label={`Request Pending (${matches} / ${units})`}
          className="border-primary-light bg-primary-light/10 text-primary-light"
        />
      );

    case 'pending':
      return (
        <Chip
          label="Request Pending"
          className="border-primary-light bg-primary-light/10 text-primary-light"
        />
      );

    case 'Donation Received':
      return (
        <Chip
          label={status}
          className="border-green-500 bg-green-500/10 text-green-500"
        />
      );

    default:
      return <Chip label={status} />;
  }
};

const BloodDonationRequestsCard: FC<RequestsCardProps> = ({
  blood_group,
  image,
  recipient,
  hospital,
  onClick,
  status,
  matches,
  units,
  first_name,
  last_name,
}) => {
  const getChipStatus = useCallback(() => {
    if (status !== 'pending') return status;

    if (matches) {
      if (matches?.length > 0) {
        return 'match';
      }
    }

    return status;
  }, [matches, status]);

  return (
    <button
      className="flex cursor-pointer flex-wrap gap-2 rounded-lg border border-[#F5F0F0] bg-[#FCF9F8] p-5 lg:gap-5"
      onClick={() => {
        onClick && onClick();
      }}
    >
      <Avatar name={blood_group} image={image} />

      <div className="flex-1 flex-col gap-2 text-left">
        <Text className="!font-interSemibold capitalize">
          {recipient === 'self' ? recipient : `${first_name} ${last_name}`}
        </Text>
        <Text className="text-xs" type="caption">
          {hospital}
        </Text>
      </div>
      <div className="flex w-full items-center justify-between gap-2 md:w-auto lg:gap-5">
        <StatusChip
          status={getChipStatus()}
          matches={matches ? matches.length : 0}
          units={units}
        />

        <ImArrowRight2 size={15} className="cursor-pointer text-jblood-gray" />
      </div>
    </button>
  );
};

export default BloodDonationRequestsCard;
