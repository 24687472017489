import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../../../form/Checkbox';
import Button from '../../../../lib/Button';
import Text from '../../../../lib/Text';
const Page2 = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/app/dashboard');
  };

  return (
    <>
      <ul className="ml-5 flex min-h-[300px] list-disc flex-col gap-5 md:gap-10">
        <li>
          <Text>
            You may put in another request for blood donors if the donor you are
            paired with is unable to donate for any reason. Refer to the
            ‘Recipient Feedback’ to report this.{' '}
          </Text>
        </li>
        <li>
          <Text>
            The donor may request to take a picture with the recipient (with the
            consent of the recipient) so as to aid J Blood Match’s storytelling
            on voluntary blood donation.
          </Text>
        </li>
      </ul>

      <div className="mt-10 flex justify-center">
        <Checkbox
          label="I agree to the points above."
          checked={checked}
          onChange={() => setChecked((prevState) => !prevState)}
        />
      </div>

      <Button
        size="medium"
        variant="gradient"
        disabled={!checked}
        className="mx-auto w-full lg:w-auto"
        onClick={handleSubmit}
      >
        Done
      </Button>
    </>
  );
};

export default Page2;
