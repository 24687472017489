import { Icon } from '@iconify/react';
import { FC, useEffect, useState } from 'react';
import Card from '../../../../lib/Card';
import Heading from '../../../../lib/Heading';
import AchievementStreakProps from './AchievementStreakProps';

const AchievementStreak: FC<AchievementStreakProps> = ({ count }) => {
  const [hearts, setHearts] = useState(0);

  useEffect(() => {
    if (count === 0) {
      setHearts(0);
    } else {
      if (count % 3 === 0) {
        setHearts(3);
      } else {
        setHearts(count % 3);
      }
    }
  }, [count]);

  return (
    <div className="flex w-full flex-col items-center gap-5 text-center">
      <Heading type="h3">
        Earn a Hero Badge when you donate up to three times a year
      </Heading>

      <Card className="w-full">
        <div className="flex w-full flex-col items-center gap-5 p-5">
          <div className="flex items-center gap-5">
            <div
              className={`flex items-center justify-center rounded-full p-3 ${
                hearts > 0 ? 'bg-gradient' : 'bg-gray-300'
              }`}
            >
              <Icon
                icon="ant-design:heart-filled"
                className={`text-xl text-white`}
              />
            </div>

            <div
              className={`flex items-center justify-center rounded-full p-3 ${
                hearts > 1 ? 'bg-gradient' : 'bg-gray-300'
              }`}
            >
              <Icon
                icon="ant-design:heart-filled"
                className={`text-2xl text-white`}
              />
            </div>

            <div
              className={`flex items-center justify-center rounded-full p-3 ${
                hearts > 2 ? 'bg-gradient' : 'bg-gray-300'
              }`}
            >
              <Icon
                icon="ant-design:heart-filled"
                className={`text-3xl text-white`}
              />
            </div>
          </div>

          <div className="flex h-3 w-full max-w-[600px] overflow-hidden rounded-2xl bg-gray-300">
            <div
              className="h-3 rounded-2xl bg-gradient"
              style={{ width: `${(hearts / 3) * 100}%` }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AchievementStreak;
