import { FC } from 'react';

import Page1 from './Page1';
import Page2 from './Page2';

const Pages: FC<{
  activeStep: number;
  handleNext: () => void;
}> = ({ activeStep, handleNext }) => {
  switch (activeStep) {
    case 1:
      return <Page1 {...{ handleNext }} />;

    case 2:
      return <Page2 />;

    default:
      return <></>;
  }
};

export default Pages;
