import { FC, PropsWithChildren } from 'react';
import Text from '../Text';
import CardProps from './CardProps';

const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  count,
  titleComponent,
  className,
  mobile = false,
  children,
  small = false,
}) => {
  return (
    <div
      className={`rounded-lg  bg-white ${
        mobile ? 'border-none p-0 px-2' : 'border border-[#E6E8EC]'
      } ${className ? className : ''} ${
        small ? 'p-3 lg:py-3 lg:px-3' : 'p-5 lg:py-5 lg:px-10'
      }`}
    >
      {!mobile && title && (
        <div className="flex w-full items-center justify-between">
          <div className="flex items-end gap-1">
            <Text className="!font-interSemibold">{title}</Text>
            {typeof count !== 'undefined' && (
              <Text type="caption" className="text-jblood-gray">
                ({count})
              </Text>
            )}
          </div>

          {titleComponent && titleComponent}
        </div>
      )}

      {!mobile && title && <hr className="my-5 border-jblood-gray/50" />}

      {children}
    </div>
  );
};

export default Card;
