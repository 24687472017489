import { FC } from 'react';
import Button from '../../../lib/Button';
import Text from '../../../lib/Text';

const Page1: FC<{
  handleNext: () => void;
}> = ({ handleNext }) => {
  const handleSubmit = () => {
    handleNext();
  };

  return (
    <>
      <ul className="ml-5 flex list-disc flex-col gap-5 md:gap-10">
        <li>
          <Text>Only meet in the hospital sent to you by J Blood Match.</Text>
        </li>
        <li>
          <Text>
            Confirm what time the transfusion is to take place, to enable you
            prepare adequately.
          </Text>
        </li>
        <li>
          <Text>
            You may be found to be ineligible to donate blood for medical
            reasons. Stay calm as this will be confirmed to you before donation
            at the hospital.
          </Text>
        </li>
        <li>
          <Text>
            Bear in mind that your blood sample will be taken at the hospital
            before donation and tested for syphilis, HIV I, HIV II, Hepatitis B
            & C, and then your blood type will be confirmed. This is a regular
            procedure in the blood donation process, as such, it could take 1 to
            2 hours.
          </Text>
        </li>
        <li>
          <Text>
            Recipient should be responsible for the transportation of the donor
            and may only be required to forego it if the donor does not need it.
          </Text>
        </li>
      </ul>

      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full max-w-[500px]"
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </>
  );
};

export default Page1;
