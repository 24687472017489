import moment from 'moment';
import { FC, FormEvent, useState } from 'react';
import { useQuery } from 'react-query';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import { Reason } from '../../../../../types/Reason';
import { getRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { GET_REQUEST_REASONS } from '../../../../../utils/api/urls';
import {
  isEmpty,
  validateRequestDonorForm2Inputs,
} from '../../../../../utils/validators';
import Input from '../../../../form/Input';
import Select from '../../../../form/Select';
import Button from '../../../../lib/Button';
import { FormProps } from './FormsProps';

const initialState = {
  reason_id: '',
  units: '',
  phone_number: '',
  date: '',
};

const Form2: FC<FormProps> = ({
  handleNext,
  payload: generalPayload,
  setPayload: setGeneralPayload,
  recipient,
}) => {
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [reasons, setReasons] = useState<Reason[]>([]);
  const handler = useErrorHandler();

  useQuery(
    [queryKeys.getRequestReasons],
    () => getRequest({ url: GET_REQUEST_REASONS }),
    {
      onSuccess(response) {
        setReasons(response?.data?.reasons || []);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const handleChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLSelectElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } = validateRequestDonorForm2Inputs(
      payload,
      recipient || ''
    );

    if (valid) {
      setGeneralPayload({
        ...generalPayload,
        ...payload,
        reason_id: payload.reason_id,
        reason:
          reasons.find((item) => String(item?.id) === payload.reason_id)
            ?.name || '',
        date: moment(payload.date).format('YYYY-MM-DD HH:mm:ss'),
        phone_number:
          recipient === 'self' ? '' : payload.phone_number.split('+')[1],
      });
      handleNext && handleNext();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <Select
        label="Reason for blood request"
        placeholder="Select request"
        name="reason_id"
        value={payload.reason_id}
        error={!isEmpty(errors.reason_id)}
        helperText={errors.reason_id}
        options={[
          { key: 'Select Reason', value: '' },
          ...reasons.map((reason) => ({
            key: reason.name,
            value: String(reason.id),
          })),
        ]}
        onChange={handleChange}
        className="w-full"
      />
      <Input
        label="Number of units/pints needed"
        type="number"
        placeholder="Enter amount"
        name="units"
        value={payload.units}
        error={!isEmpty(errors.units)}
        helperText={errors.units}
        onChange={handleChange}
        min={0}
        max={4}
      />

      {recipient !== 'self' && (
        <Input
          label="Phone Number"
          helperLabel="(+234 format)"
          placeholder="Enter phone number"
          name="phone_number"
          value={payload.phone_number}
          error={!isEmpty(errors.phone_number)}
          helperText={errors.phone_number}
          onChange={handleChange}
        />
      )}

      <div>
        <label className="font-interSemibold text-xs md:text-sm">
          When is the blood needed?
        </label>
        <div className="flex w-full flex-col items-start gap-5 md:flex-row md:items-center">
          <div className="w-full flex-1 md:w-auto">
            <Input
              type="datetime-local"
              placeholder="Enter date"
              name="date"
              value={payload.date}
              error={!isEmpty(errors.date)}
              helperText={errors.date}
              onChange={handleChange}
            />
          </div>

          <Button
            className="mt-1"
            onClick={() =>
              setPayload({
                ...payload,
                date: moment().format('YYYY-MM-DDTHH:mm'),
              })
            }
          >
            Now!
          </Button>
        </div>
      </div>

      <Button
        size="medium"
        variant="solid"
        className="mx-auto mt-10 w-full lg:w-auto"
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </>
  );
};

export default Form2;
