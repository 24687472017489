import { FC } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Avatar from '../../../../lib/Avatar';
import Text from '../../../../lib/Text';
import { DonationRequestsCardProps } from './DonationRequestsProps';

const BloodDonationRequestsCard: FC<DonationRequestsCardProps> = ({
  image,
  hospital,
  blood_group,
  onClick,
  user,
}) => {
  return (
    <button
      className="flex cursor-pointer gap-2 rounded-lg border border-[#F5F0F0] bg-[#FCF9F8] p-5 lg:gap-5"
      onClick={() => {
        onClick && onClick();
      }}
    >
      <Avatar name={`${user?.first_name} ${user?.last_name}`} image={image} />

      <div className="flex-1 flex-col gap-2 text-left">
        <Text className="!font-interSemibold capitalize">
          {`${user?.first_name} ${user?.last_name}`}
        </Text>
        <Text className="text-xs" type="caption">
          {hospital}
        </Text>
      </div>

      <div className="flex flex-col items-end">
        <Text className="!font-interSemibold uppercase text-primary-main lg:text-jblood-black">
          {blood_group}
        </Text>

        <Text
          className="hidden items-center gap-1 text-jblood-gray lg:flex"
          type="caption"
        >
          See more <MdKeyboardArrowDown />
        </Text>
      </div>
    </button>
  );
};

export default BloodDonationRequestsCard;
