import AchievementCard from '../../../components/auth/Donor/Achievements/AchievementCard';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
// import Heart from '../../../assets/images/Heart.png';
import Medal from '../../../assets/images/Medal.png';
import Zeal from '../../../assets/images/Zeal.png';
import LifeSaver from '../../../assets/images/LifeSaver.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import AchievementStreak from '../../../components/auth/Donor/Achievements/AchievementStreak';
import { useState } from 'react';
import { useQuery } from 'react-query';
import queryKeys from '../../../utils/api/queryKeys';
import { getRequest } from '../../../utils/api/calls';
import { GET_DONATATION_STATS } from '../../../utils/api/urls';
import useErrorHandler from '../../../hooks/useErrorHandler';

const Achievements = () => {
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [count, setCount] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const handler = useErrorHandler();

  useQuery(
    [queryKeys.getdonationStats],
    () => getRequest({ url: GET_DONATATION_STATS }),
    {
      onSuccess(response) {
        setCount(response?.data?.confirmed_donation?.length || 0);
        setAttempts(response?.data?.attempted_donation?.count || 0);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  return (
    <PageTitle title="Achievements">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Achievements" mobile={smallScreen}>
          <div className="flex flex-col gap-5 lg:gap-10">
            {/* <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
              {Array.from(Array(count > 3 ? 3 : count).keys()).map(() => (
                <AchievementCard
                  // title="Donation Confirmed"
                  small
                  icon={Heart}
                  subtitle="Thank you for donating!"
                  caption="You are truly a SUPERHERO, because you are the reason for someone’s heartbeat."
                />
              ))}
            </div> */}

            <AchievementStreak count={count > 3 ? 3 : count} />

            <div className="grid w-full grid-cols-1 md:grid-cols-2">
              <AchievementCard
                title="Life Saver Badge"
                className="col-span-1"
                icon={LifeSaver}
                incomplete={count < 1}
                subtitle="Congratulations!"
                caption="You earned a Life Saver badge for donating blood once this year."
              />
              <AchievementCard
                title="Hero Badge"
                className="col-span-1"
                icon={Medal}
                incomplete={count < 3}
                subtitle="Congratulations!"
                caption="You earned a Hero badge for donating blood up to three times this year."
              />
            </div>

            <hr />

            <div className="grid w-full grid-cols-1 md:grid-cols-2">
              <AchievementCard
                title="We see you!"
                className="col-span-1"
                icon={Zeal}
                incomplete={attempts <= 0}
                subtitle="Congratulations"
                caption="You earned a Zeal badge for attempting to donate blood."
              />
            </div>
          </div>
        </Card>
      </div>
    </PageTitle>
  );
};

export default Achievements;
