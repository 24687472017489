import moment from 'moment';
import { FC } from 'react';
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import Avatar from '../../../../lib/Avatar';
import Button from '../../../../lib/Button';
import Heading from '../../../../lib/Heading';
import Modal from '../../../../lib/Modal';
import Text from '../../../../lib/Text';
import RequestDetail from './RequestDetail';
import RequestModalProps from './RequestModalProps';

const RequestModal: FC<RequestModalProps> = ({
  open,
  onClose,
  selectedIndex,
  setSelectedIndex,
  onAccept,
  acceptIsLoading,
  onReject,
  requests,
}) => {
  const data = requests[selectedIndex]?.request;

  const handleNext = () => {
    if (selectedIndex + 1 > requests.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedIndex < 1) {
      setSelectedIndex(requests.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return (
    <Modal
      {...{ open, onClose }}
      className="relative max-h-[75vh] w-[95vw] max-w-[600px] rounded-lg bg-[#FCF9F8] lg:max-h-max"
    >
      <figure className="absolute left-[50%] -top-[7%] z-20 h-16 w-16 -translate-x-[50%] overflow-hidden rounded-full border-4 border-white md:h-20 md:w-20 md:border-8">
        {data?.image ? (
          <img
            src={data?.image}
            alt="User Avatar"
            className="h-full w-full object-cover"
          />
        ) : (
          <Avatar
            className="!h-full !w-full"
            name={`${data?.user?.first_name} ${data?.user?.last_name}`}
          />
        )}
      </figure>

      <MdClose
        onClick={onClose}
        className="absolute top-5 right-5 z-20 cursor-pointer text-2xl"
      />

      <MdKeyboardArrowLeft
        onClick={handlePrevious}
        className="absolute top-[50%] left-[2%] z-20 cursor-pointer text-2xl"
      />

      <MdKeyboardArrowRight
        onClick={handleNext}
        className="absolute top-[50%] right-[2%] z-20 cursor-pointer text-2xl"
      />
      <div className="relative flex max-h-[75vh] w-[95vw] max-w-[600px] flex-col items-center gap-3 overflow-y-auto bg-transparent px-10 py-10 lg:max-h-max lg:py-14 lg:px-20">
        <Heading type="h3" className="capitalize">
          {`${data?.user?.first_name} ${data?.user?.last_name}`}
        </Heading>

        <Text type="caption" className="capitalize text-jblood-gray">
          {`${data?.gender}`}
        </Text>

        <div className="mt-5 flex w-full flex-col gap-3 rounded-lg bg-white py-5 px-5 shadow shadow-primary-light/50 lg:px-10">
          <RequestDetail
            icon="fluent:patient-24-filled"
            title="Reason for request"
            detail={data?.reason}
          />
          <RequestDetail
            icon="carbon:location-filled"
            title="Location"
            detail={data?.hospital}
          />
          <RequestDetail
            icon="ic:baseline-access-time-filled"
            title="Date Needed"
            detail={moment(data?.date).format('DD MMMM, hh:mm A')}
          />
          {/* <RequestDetail
            icon="carbon:phone-filled"
            title="Phone Number"
            detail={
              data?.user?.phone_number ? `+${data?.user?.phone_number}` : ''
            }
          /> */}

          <div className="mx-auto mt-1 flex flex-col items-center">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gradient">
              <Text className="text-white">{data?.blood_group}</Text>
            </div>

            <Text type="caption" className="text-center text-jblood-gray">
              Blood Group
            </Text>
          </div>
        </div>

        <div className="mx-auto mt-5 flex w-full justify-center gap-5">
          <Button onClick={onAccept} loading={acceptIsLoading}>
            Accept
          </Button>
          <Button onClick={onReject} variant="outline">
            Reject
          </Button>
        </div>

        <div className="mx-auto mt-3 flex w-full items-center justify-center gap-2">
          {requests.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${
                index === selectedIndex
                  ? 'bg-primary-light'
                  : 'bg-primary-light/30'
              }`}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default RequestModal;
