import { RiArrowRightLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../lib/Button';
import Heading from '../../lib/Heading';
import Text from '../../lib/Text';

const ProcessBanner = () => {
  const smallScreen = useMediaQuery('(max-width: 768px)');

  return (
    <section className="bg-secondary-light px-7 py-12 lg:px-20 lg:py-20">
      <div className="max mx-auto flex flex-col items-center gap-10 text-center">
        <Heading type="h2">How does the blood donation process work?</Heading>
        <Text type="body1">
          {smallScreen
            ? `
            The donation process from the time you arrive until the time you leave takes about an hour. 
The steps involved in the entire donation process include registration at a health centre closest to you, mandatory health checkup and tests, donation and refreshments.
            `
            : `Donating blood is a simple thing to do, but it can make a big
          difference in the lives of others. The donation process from the time
          you arrive until the time you leave takes about an hour. The donation
          itself is only about 8-10 minutes on average. The steps involved in
          the entire donation process include registration at a health centre
          closest to you, mandatory health checkup and tests, donation and
          refreshments.`}
        </Text>
        <Link to="/donation-process">
          <Button variant="outline" size="medium">
            <div className="flex items-center justify-center gap-2">
              <span>Learn more</span>
              <RiArrowRightLine size={20} />
            </div>
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default ProcessBanner;
