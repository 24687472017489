import AppStore from '../../../assets/images/AppStore.png';
import PlayStore from '../../../assets/images/PlayStore.png';
import Heading from '../../lib/Heading';

const ComingSoon = () => {
  return (
    <section className="flex flex-col items-center gap-10 py-10 px-5 pb-32">
      <Heading type="h2">Coming soon...</Heading>

      <div className="flex items-center justify-center gap-3 lg:gap-10">
        <figure className="aspect-[25/8] w-[30vw] max-w-[120px] cursor-pointer overflow-hidden md:max-w-[200px]">
          <img src={AppStore} alt="" className="h-full w-full" />
        </figure>
        <figure className="aspect-[25/8] w-[30vw] max-w-[120px] cursor-pointer overflow-hidden md:max-w-[200px]">
          <img src={PlayStore} alt="" className="h-full w-full" />
        </figure>
      </div>
    </section>
  );
};

export default ComingSoon;
