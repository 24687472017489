import { FormEvent, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../../components/containers/PageTitle';
import Checkbox from '../../../components/form/Checkbox';
import Input from '../../../components/form/Input';
import Select from '../../../components/form/Select';
import TextArea from '../../../components/form/TextArea';
import Button from '../../../components/lib/Button';
import Card from '../../../components/lib/Card';
import { RoleContext } from '../../../contexts/role';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { State } from '../../../types/State';
import { getRequest, postRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import { GET_STATES, SHARE_TESTIMONIAL } from '../../../utils/api/urls';
import { LENGTH_OF_THE_LONGEST_WORD_IN_THE_DICTIONARY } from '../../../utils/constants';
import {
  isEmpty,
  validateShareTestimonialInputs,
} from '../../../utils/validators';

const initialState = {
  name: '',
  state: '',
  summary: '',
  challenges: '',
  aftermath: '',
  conclusion: '',
  reason: '',
  lesson: '',
  type: '',
};

const ShareTestimonial = () => {
  const navigate = useNavigate();
  const { role } = useContext(RoleContext);
  const [payload, setPayload] = useState({ ...initialState, type: role });
  const [errors, setErrors] = useState(initialState);
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState('');
  const [states, setStates] = useState<State[]>([]);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const handler = useErrorHandler();

  useQuery([queryKeys.getStates], () => getRequest({ url: GET_STATES }), {
    onSuccess(response) {
      setStates(response?.data?.states || []);
    },
    onError(error: any) {
      handler(error);
    },
  });

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      toast.success('Testimonial shared successfully');
      navigate('/app/dashboard');
    },
    onError(error) {
      handler(error);
    },
  });

  const toggleChecked = () => {
    setChecked((prevState) => !prevState);
  };

  const handleChange = (
    event: FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    maxLength?: number
  ) => {
    const value = event.currentTarget.value;
    const words = value.split(' ');

    if (maxLength && words.length > maxLength) {
      return;
    }

    if (
      words.length > 0 &&
      words[words.length - 1].length >
        LENGTH_OF_THE_LONGEST_WORD_IN_THE_DICTIONARY
    )
      return;

    setPayload({
      ...payload,
      [event.currentTarget.name]: value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);
    setCheckedError('');

    const { valid, errors: validationErrors } =
      validateShareTestimonialInputs(payload);

    if (!valid) {
      setErrors(validationErrors);
      return;
    }

    if (!checked) {
      setCheckedError('You must accept this condition');
      return;
    }

    mutate({
      url: SHARE_TESTIMONIAL,
      data: payload,
    });
  };

  return (
    <PageTitle title="Share Testimonial">
      <div className="grid w-full max-w-[900px] gap-10 lg:w-[60vw]">
        <Card title="Share Testimonial" mobile={smallScreen}>
          <div className="grid w-full gap-5 lg:w-[600px]">
            <Input
              label="Full Name"
              placeholder="Enter your full name"
              helperLabel="(fill only if you want your name to be shared)"
              name="name"
              value={payload.name}
              onChange={handleChange}
              error={!isEmpty(errors.name)}
              helperText={errors.name}
            />
            <Select
              label="Location"
              placeholder="Select Location"
              name="state"
              value={payload.state}
              options={[
                { key: 'Select State', value: '' },
                ...states.map((state) => ({
                  key: state.name,
                  value: state.name,
                })),
              ]}
              onChange={handleChange}
              error={!isEmpty(errors.state)}
              helperText={errors.state}
            />
            <TextArea
              label={
                role === 'donor'
                  ? 'Kindly give a summary of what you were doing when the request came'
                  : 'Summary of the blood need that led to the request. Your response should also include whothe request was meant for and if alternative means of getting a donor/blood were tried'
              }
              placeholder={
                role === 'donor'
                  ? 'I received the donation request while…'
                  : 'I made the request because…'
              }
              helperLabel={`(${role === 'donor' ? '20' : '50'} words)`}
              name="summary"
              value={payload.summary}
              onChange={(event) => handleChange(event, 50)}
              error={!isEmpty(errors.summary)}
              helperText={errors.summary}
              rows={6}
            />
            {role === 'donor' && (
              <TextArea
                label="Why did you accept this donation request? Did something about it resonate with you?"
                placeholder="I accepted the request because…"
                helperLabel="(100 words)"
                name="reason"
                value={payload.reason}
                onChange={(event) => handleChange(event, 100)}
                error={!isEmpty(errors.reason)}
                helperText={errors.reason}
                rows={6}
              />
            )}
            <TextArea
              label={
                role === 'donor'
                  ? 'What challenges did you face in the entire process, if any?'
                  : 'What challenges did you face in trying to get a donor on J Blood Match, if any?'
              }
              placeholder="Optional"
              helperLabel="(100 words)"
              name="challenges"
              value={payload.challenges}
              onChange={(event) => handleChange(event, 100)}
              error={!isEmpty(errors.challenges)}
              helperText={errors.challenges}
              rows={6}
            />
            <TextArea
              label={
                role === 'donor'
                  ? 'Describe how you felt after donating blood? Share on the emotions you experienced'
                  : 'Describe how you felt after being matched with a blood donor?'
              }
              placeholder="I felt…"
              helperLabel="(100 words)"
              name="aftermath"
              value={payload.aftermath}
              onChange={(event) => handleChange(event, 100)}
              error={!isEmpty(errors.aftermath)}
              helperText={errors.aftermath}
              rows={6}
            />
            {role === 'donor' ? (
              <TextArea
                label="What would you say to advise someone else to become a J Blood Match donor?"
                placeholder="Optional"
                helperLabel="(100 words)"
                name="conclusion"
                value={payload.conclusion}
                onChange={(event) => handleChange(event, 100)}
                error={!isEmpty(errors.conclusion)}
                helperText={errors.conclusion}
                rows={6}
              />
            ) : (
              <TextArea
                label="What would you say to advise someone about donating blood on J Blood Match?"
                placeholder="Being a J Blood Match donor…"
                helperLabel="(100 words)"
                name="conclusion"
                value={payload.conclusion}
                onChange={(event) => handleChange(event, 100)}
                error={!isEmpty(errors.conclusion)}
                helperText={errors.conclusion}
                rows={6}
              />
            )}
            {role === 'donor' && (
              <TextArea
                label="What new thing did you learn about yourself or blood donation?"
                placeholder="Being a J Blood Match donor has helped me learn…"
                helperLabel="(100 words)"
                name="lesson"
                value={payload.lesson}
                onChange={(event) => handleChange(event, 100)}
                error={!isEmpty(errors.lesson)}
                helperText={errors.lesson}
                rows={6}
              />
            )}
            <Checkbox
              checked={checked}
              onChange={toggleChecked}
              error={!isEmpty(checkedError)}
              helperText={checkedError}
              label="By filling this form, you accept to have your testimonial shared on our social media pages and other relevant communication material"
            />
            <Button
              size="medium"
              variant="gradient"
              className="mx-auto mt-10 w-full max-w-[500px]"
              disabled={!checked}
              loading={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Card>
      </div>
    </PageTitle>
  );
};

export default ShareTestimonial;
