import { FC } from 'react';
import Button from '../../../../lib/Button';
import Text from '../../../../lib/Text';

const Page1: FC<{
  handleNext: () => void;
}> = ({ handleNext }) => {
  const handleSubmit = () => {
    handleNext();
  };

  return (
    <>
      <ul className="ml-5 flex list-disc flex-col gap-5 md:gap-10">
        <li>
          <Text>
            You are responsible for the donor’s transportation fare unless he or
            she declines.
          </Text>
        </li>
        <li>
          <Text>
            Do not give financial reward to the donor, commercial blood donation
            is strictly prohibited on this platform.
          </Text>
        </li>
        <li>
          <Text>
            Do not contact the donor outside of J Blood Match to request another
            blood donation.
          </Text>
        </li>
        <li>
          <Text>
            Where you require another blood donor, ensure that you place your
            request on J Blood Match only.
          </Text>
        </li>
        <li>
          <Text>
            Keep your phone close to you. This way you will be reachable when
            the donor is on his/her way to the hospital indicated on the app.
          </Text>
        </li>
        <li>
          <Text>
            Do not direct the donor anywhere outside of the hospital indicated
            on J Blood Match.
          </Text>
        </li>
      </ul>

      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full lg:w-auto"
        onClick={handleSubmit}
      >
        Proceed
      </Button>
    </>
  );
};

export default Page1;
