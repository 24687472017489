import toaster from 'react-hot-toast';
import Text from '../../components/lib/Text';
import Input from '../../components/form/Input';
import Button from '../../components/lib/Button';
import Select from '../../components/form/Select';
import FormContainer from '../../components/containers/FormContainer';
import { FormEvent, useState } from 'react';
import useErrorHandler from '../../hooks/useErrorHandler';
import { State } from '../../types/State';
import { useMutation, useQuery } from 'react-query';
import queryKeys from '../../utils/api/queryKeys';
import { getRequest, postRequest } from '../../utils/api/calls';
import {
  CREATE_ADVOCATE,
  GET_EDUCATION_LEVELS,
  GET_STATES,
} from '../../utils/api/urls';
import { useNavigate } from 'react-router-dom';
import { isEmpty, validateAdvocateInputs } from '../../utils/validators';
import TextArea from '../../components/form/TextArea';
import FileUpload from '../../components/form/FileUpload';
import { EducationLevel } from '../../types/EducationLevel';
import { uploadFile } from '../../firebase/storage';

const initialState = {
  name: '',
  email: '',
  phone_number: '',
  state: '',
  age: '',
  education_level: '',
  bio: '',
  jbm_description: '',
  advocate_reason: '',
};

const BecomeAnAdvocateForm = () => {
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState({
    ...initialState,
    passport: '',
    cv: '',
  });
  const [states, setStates] = useState<State[]>([]);
  const [educationLevels, setEducationLevels] = useState<EducationLevel[]>([]);
  const [passport, setPassport] = useState<File | null>(null);
  const [cv, setCV] = useState<File | null>(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const handler = useErrorHandler();
  const navigate = useNavigate();

  useQuery([queryKeys.getStates], () => getRequest({ url: GET_STATES }), {
    onSuccess(response) {
      setStates(response?.data?.states || []);
    },
    onError(error) {
      handler(error);
    },
  });

  useQuery(
    [queryKeys.getEducationLevels],
    () => getRequest({ url: GET_EDUCATION_LEVELS }),
    {
      onSuccess(response) {
        setEducationLevels(response?.data?.states || []);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const handleChange = (
    event:
      | FormEvent<HTMLInputElement>
      | FormEvent<HTMLSelectElement>
      | FormEvent<HTMLTextAreaElement>
  ) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      toaster.success('Successful!');
      navigate('/');
    },
    onError(error) {
      handler(error);
    },
  });

  const handleFileChange = (
    event: FormEvent<HTMLInputElement>,
    stateSetter: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = event.currentTarget?.files?.[0];

    if (file) {
      stateSetter(file);
    }
  };

  const handleFileUpload = async (file: File) => {
    setFileIsUploading(true);
    let fileUrl = '';

    await uploadFile({
      file,
      onError() {
        setFileIsUploading(false);
        toaster.error('Something went wrong, try again later.');
      },
      onSuccess(url) {
        fileUrl = url;
        setFileIsUploading(false);
      },
    });

    return fileUrl;
  };

  const handleSubmit = async () => {
    try {
      setErrors({ ...initialState, passport: '', cv: '' });
      const { valid, errors: validationErrors } = validateAdvocateInputs({
        ...payload,
        passport,
        cv,
      });

      if (!valid) {
        setErrors(validationErrors);
        return;
      }

      const passportUrl = await handleFileUpload(passport as File);
      const cvUrl = await handleFileUpload(cv as File);

      mutate({
        url: CREATE_ADVOCATE,
        data: {
          ...payload,
          phone_number: payload.phone_number.split('+')[1],
          passport: passportUrl,
          cv: cvUrl,
        },
      });
    } catch (error) {
      handler(error);
    }
  };

  return (
    <FormContainer title="JBM Advocacy Programme">
      <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
        <Text className="text-center">
          Register for the J Blood Match (JBM) Advocacy Programme by filling the
          form below:
        </Text>
      </div>

      <div className="flex w-full max-w-[650px] flex-col gap-3 lg:gap-5">
        <Input
          label="Full Name"
          placeholder="Enter your full name"
          name="name"
          value={payload.name}
          onChange={handleChange}
          error={!isEmpty(errors.name)}
          helperText={errors.name}
        />
        <Input
          label="Age"
          placeholder="Enter your age"
          name="age"
          type="number"
          min={18}
          value={payload.age}
          onChange={handleChange}
          error={!isEmpty(errors.age)}
          helperText={errors.age}
        />
        <Input
          label="Email Address"
          placeholder="Enter your email address"
          name="email"
          type="email"
          value={payload.email}
          onChange={handleChange}
          error={!isEmpty(errors.email)}
          helperText={errors.email}
        />
        <Input
          label="Phonenumber"
          helperLabel="(+234 format)"
          placeholder="Enter your phonenumber"
          name="phone_number"
          value={payload.phone_number}
          onChange={handleChange}
          error={!isEmpty(errors.phone_number)}
          helperText={errors.phone_number}
        />
        <Select
          label="Current Location"
          placeholder="Select State"
          name="state"
          value={payload.state}
          options={[
            { key: 'Select State', value: '' },
            ...states.map((state) => ({
              key: state.name,
              value: state.name,
            })),
          ]}
          onChange={handleChange}
          error={!isEmpty(errors.state)}
          helperText={errors.state}
        />
        <Select
          label="Highest degree of education"
          placeholder="Select Option"
          name="education_level"
          value={payload.education_level}
          options={[
            { key: 'Select Option', value: '' },
            ...educationLevels.map((level) => ({
              key: level.name,
              value: level.name,
            })),
          ]}
          onChange={handleChange}
          error={!isEmpty(errors.education_level)}
          helperText={errors.education_level}
        />
        <TextArea
          label="Write a short bio of yourself."
          helperLabel="It should be written in the third person and be no more than 200 words."
          placeholder="Enter bio"
          name="bio"
          value={payload.bio}
          onChange={handleChange}
          error={!isEmpty(errors.bio)}
          helperText={errors.bio}
        />
        <TextArea
          label="What do you understand that J Blood Match is?"
          placeholder="Enter response"
          name="jbm_description"
          value={payload.jbm_description}
          onChange={handleChange}
          error={!isEmpty(errors.jbm_description)}
          helperText={errors.jbm_description}
        />
        <TextArea
          label="Why do you want to become a J Blood Match advocate?"
          placeholder="Enter response"
          name="advocate_reason"
          value={payload.advocate_reason}
          onChange={handleChange}
          error={!isEmpty(errors.advocate_reason)}
          helperText={errors.advocate_reason}
        />
        <FileUpload
          label="Upload a passport photo"
          file={passport}
          accept=".png,.jpg,.jpeg,.webp,.avif"
          onChange={(event) => handleFileChange(event, setPassport)}
          error={!isEmpty(errors.passport)}
          helperText={errors.passport}
        />
        <FileUpload
          label="Upload your updated CV"
          file={cv}
          accept=".docx,.pdf"
          onChange={(event) => handleFileChange(event, setCV)}
          error={!isEmpty(errors.cv)}
          helperText={errors.cv}
        />

        <Button
          size="medium"
          variant="gradient"
          className="mx-auto mt-10 w-full max-w-[500px]"
          loading={isLoading || fileIsUploading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};

export default BecomeAnAdvocateForm;
