import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Forms from '../../components/access/Signup/Forms';
import Logo from '../../components/app/Navbar/Logo';
import FormContainer from '../../components/containers/FormContainer';
import Heading from '../../components/lib/Heading';
import Stepper from '../../components/lib/Stepper';
import { useQueryParam } from '../../hooks/useQueryParams';

const Signup = () => {
  const registered = useQueryParam().get('registered');
  const [activeStep, setActiveStep] = useState(registered ? 2 : 1);

  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem('user');

    if (userData) {
      const user = JSON.parse(userData);

      if (user?.id) {
        navigate('/app/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  return (
    <FormContainer>
      <div className="flex w-full max-w-[650px] flex-col gap-3 lg:gap-5">
        <div className="mb-10 -mt-10 flex w-full justify-center">
          <Logo />
        </div>
        <Heading
          underlined
          className="mx-auto mb-10 w-[60vw] text-center text-2xl md:w-auto"
        >
          Become a Blood Donor
        </Heading>
        <Stepper {...{ steps: 4, activeStep }} />
        <Forms {...{ activeStep, setActiveStep, handleNext }} />
      </div>
    </FormContainer>
  );
};

export default Signup;
