import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCqaL6mIodbQJmCA7UI9B4u8b2jCm1ZzK0',
  authDomain: 'jbloodmatch.firebaseapp.com',
  projectId: 'jbloodmatch',
  storageBucket: 'jbloodmatch.appspot.com',
  messagingSenderId: '376960236932',
  appId: '1:376960236932:web:4dbceffff80b8446e471d1',
  measurementId: 'G-1YR38BJ9C4',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
