export const publicLinks = [
  {
    icon: 'bi:grid',
    label: 'About JBM',
    url: '/app/about',
  },
  {
    icon: 'carbon:partnership',
    label: 'Partner With Us',
    url: '/app/partner',
  },
  {
    icon: 'pixelarticons:notes-multiple',
    label: 'Articles',
    url: '/app/articles',
  },
  {
    icon: 'fluent:calendar-info-20-regular',
    label: 'Terms of Use',
    url: '/app/terms-of-use',
  },
  {
    icon: 'fa-solid:question',
    label: 'FAQs',
    url: '/app/faqs',
  },
  {
    icon: 'carbon:person-favorite',
    label: 'JBM Advocacy Programme',
    url: '/app/become-an-advocate',
  },
  {
    icon: 'bx:donate-heart',
    label: 'Donate to JDI',
    url: '/app/donate',
  },
];

export const donorLinks = [
  {
    icon: 'ant-design:home-outlined',
    label: 'Dashboard',
    url: '/app/dashboard',
  },
  {
    icon: 'fontisto:blood-drop',
    label: 'Donation Requests',
    url: '/app/requests',
  },
  {
    icon: 'bx:network-chart',
    label: 'Blood Match Chart',
    url: '/app/chart',
  },
  {
    icon: 'lucide:users',
    label: 'Donation History',
    url: '/app/history',
  },
  {
    icon: 'carbon:badge',
    label: 'Achievements',
    url: '/app/achievements',
  },
  {
    icon: 'charm:clock-alarm',
    label: 'Reminders',
    url: '/app/reminders',
  },
  {
    icon: 'jam:write',
    label: 'Feedback',
    url: '/app/feedback',
  },
];

export const recipientLinks = [
  {
    icon: 'bx:user',
    label: 'Find A Donor',
    url: '/app/dashboard',
  },
  {
    icon: 'fontisto:blood-drop',
    label: 'Your Requests',
    url: '/app/your-requests',
  },
  {
    icon: 'jam:write',
    label: 'Feedback',
    url: '/app/feedback',
  },
  {
    icon: 'clarity:warning-standard-line',
    label: 'Report Abuse',
    url: '/app/report',
  },
];
