import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import FeedbackList from '../../../components/auth/Feedback/FeedbackList';
import FeedbackModal from '../../../components/auth/Feedback/FeedbackModal';
import PageTitle from '../../../components/containers/PageTitle';
import Button from '../../../components/lib/Button';
import Card from '../../../components/lib/Card';
import Pagination from '../../../components/lib/Pagination';
import Text from '../../../components/lib/Text';
import { RoleContext } from '../../../contexts/role';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Feedback as FeedbackType } from '../../../types/Feedback';
import { getRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import {
  GET_DONOR_FEEDBACK,
  GET_RECIPIENT_FEEDBACK,
} from '../../../utils/api/urls';

const Feedback = () => {
  const navigate = useNavigate();
  const { role } = useContext(RoleContext);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [feedback, setFeedback] = useState<FeedbackType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getFeedback, role],
    () =>
      getRequest({
        url: role === 'donor' ? GET_DONOR_FEEDBACK : GET_RECIPIENT_FEEDBACK,
      }),
    {
      onSuccess(response) {
        if (response?.data?.feedbacks) {
          setFeedback(response?.data?.feedbacks);
          setTotal(response?.data?.page_info?.total || 0);
          setCount(response?.data?.page_info?.total_pages || 0);
        }
      },
      onError(error) {
        handler(error);
      },
    }
  );

  const handleSelectFeedback = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  return (
    <PageTitle title="Your Feedback">
      <div className="grid w-full max-w-[900px] gap-10 lg:w-[60vw]">
        <Card count={total} title="Your Feedback" mobile={smallScreen}>
          <Pagination {...{ page, setPage, count }} />
          <FeedbackList
            {...{ feedback, setSelectedIndex: handleSelectFeedback, isLoading }}
          />
        </Card>

        <Card title="Testimonial">
          <div className="flex w-full flex-col gap-5 lg:w-[600px]">
            <Text className="!font-interLight !leading-loose">
              {role === 'donor'
                ? 'Would you like to share a testimonial about being a J Blood Match donor? Sharing your story encourages others to become real-life superheroes just like you! Click on this link to fill our testimonial form.'
                : 'Would you like to share a testimonial about using J Blood Match? Sharing your story encourages other donors to become real-life superheroes through this solution! Click on this link to fill ourtestimonial form.'}
            </Text>

            <Button
              className="self-start"
              variant="gradient"
              onClick={() => navigate('/app/share-testimonial')}
            >
              Share Testimonial
            </Button>
          </div>
        </Card>
      </div>

      <FeedbackModal
        {...{
          open,
          onClose: () => setOpen(false),
          selectedIndex,
          setSelectedIndex,
          feedback,
        }}
      />
    </PageTitle>
  );
};

export default Feedback;
