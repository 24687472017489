import { useLocation, useNavigate } from 'react-router-dom';
import { timelines } from '../../components/app/BecomeAnAdvocate/data';
import PageTitle from '../../components/containers/PageTitle';
import Button from '../../components/lib/Button';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';

const BecomeAnAdvocate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="Advocacy Programme">
      <div
        className={`max mx-auto flex flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          JBM Advocacy Programme
        </Heading>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Text>
            The J Blood Match (JBM) Advocacy programme is a 3-month advocacy
            campaign engaged in by ADVOCATES who have undergone a training on
            how J Blood Match works and other related topics.
          </Text>

          <Text>
            For the duration of the campaign, advocates serve as volunteer
            ambassadors of JBM. Their role is to use the resources available to
            them to raise awareness on voluntary blood donation and spread the
            word amongst their network on how JBM bridges the gaps in blood
            donor access while registering new donors on the platform.{' '}
          </Text>

          <Text>
            Typically, advocates undergo a three-day virtual training and earn a
            certificate of completion after the training. Topics of training
            include but are not limited to advocacy, strategies for advocacy,
            the blood donation process, Nigeria’s blood needs and how J Blood
            Match works.
          </Text>

          <Text>So far, we have onboarded 21 JBM Advocates.</Text>

          <Text>
            Note: The JBM Advocacy programme is open to anyone around the globe,
            as long as they have strong ties in Nigeria, which they can tap from
            to onboard voluntary blood donors on JBM.
          </Text>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Eligibility Criteria</Heading>

          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>Must be 18 years old or above.</Text>
            </li>
            <li>
              <Text>
                Must have a genuine interest in blood donation advocacy
              </Text>
            </li>
            <li>
              <Text>
                Must be willing to commit to the 3-month programme and register
                willing donors on J Blood Match.
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Benefits of being a JBM Advocate</Heading>
          <ul className="ml-5 flex list-disc flex-col gap-2">
            <li>
              <Text>
                Access to a community of social change agents and opportunity to
                volunteer in a unique aspect of public health.
              </Text>
            </li>
            <li>
              <Text>
                Free training on advocacy, blood donation matters and other
                related aspects of public health.
              </Text>
            </li>
            <li>
              <Text>
                Showcase as a changemaker on JDI’s social media platforms and
                newsletters.
              </Text>
            </li>
            <li>
              <Text>
                Special badge as a certified J Blood Match advocate, valid for 3
                months
              </Text>
            </li>
            <li>
              <Text>
                Special feature of Advocate with highest number of registered
                donors on a radio show.
              </Text>
            </li>
            <li>
              <Text>
                Special award for the 3 most consistent high-performing
                Advocates.
              </Text>
            </li>
            <li>
              <Text>
                Small grant available to all advocates to carry out unique
                advocacy projects/campaigns, based on a fulfillment of key
                requirements.
              </Text>
            </li>
          </ul>
        </div>

        <div className="mb-5 flex w-full flex-col gap-5 lg:p-5">
          <Heading type="h3">Timelines</Heading>

          <Text>
            Each year, we receive two batches of advocates. Below is the 2023
            calendar.
          </Text>

          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-primary-main text-white">
                  <td align="center" className="min-w-[150px] py-3 lg:py-7">
                    <Heading type="h3" className="!text-[.65rem] md:!text-base">
                      Batch
                    </Heading>
                  </td>
                  <td
                    align="center"
                    className="min-w-[150px] border-x border-[#8B888780] py-3 md:border-none lg:py-7 "
                  >
                    <Heading type="h3" className="!text-[.65rem] md:!text-base">
                      Selection Period
                    </Heading>
                  </td>
                  <td align="center" className="min-w-[150px] py-3 lg:py-7">
                    <Heading type="h3" className="!text-[.65rem] md:!text-base">
                      Training Dates
                    </Heading>
                  </td>
                  <td align="center" className="min-w-[150px] py-3 lg:py-7">
                    <Heading type="h3" className="!text-[.65rem] md:!text-base">
                      Duration of Advocacy
                    </Heading>
                  </td>
                </tr>
              </thead>
              <tbody>
                {timelines.map((item, index) => (
                  <tr
                    key={index}
                    className={
                      index % 2 !== 0 ? 'bg-[#F2F1F64D]' : 'bg-[#FFC37126]'
                    }
                  >
                    <td align="center" className="min-w-[150px] py-3 lg:py-7">
                      <Text className="text-xs md:text-base">{item.batch}</Text>
                    </td>
                    <td align="center" className="min-w-[150px] py-3 lg:py-7">
                      <Text className="text-xs md:text-base">
                        {item.sleectionPeriod}
                      </Text>
                    </td>
                    <td align="center" className="min-w-[150px] py-3 lg:py-7">
                      <Text className="text-xs md:text-base">
                        {item.trainingDates}
                      </Text>
                    </td>
                    <td align="center" className="min-w-[150px] py-3 lg:py-7">
                      <Text className="text-xs md:text-base">
                        {item.duration}
                      </Text>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Text type="caption" className="-mt-5">
            Note: Interested persons may only apply for the period they are
            available for.
          </Text>
        </div>

        <div className="mb-5 flex w-full flex-col items-center gap-5 lg:p-5">
          <Button
            size="large"
            variant="gradient"
            onClick={() => navigate('/become-an-advocate/form')}
          >
            Apply Now
          </Button>
        </div>
      </div>
    </PageTitle>
  );
};

export default BecomeAnAdvocate;
