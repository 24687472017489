import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import RequestModal from '../../../components/auth/Recipient/Requests/RequestModal';
import RequestsList from '../../../components/auth/Recipient/Requests/RequestsList';
import PageTitle from '../../../components/containers/PageTitle';
import Card from '../../../components/lib/Card';
import Pagination from '../../../components/lib/Pagination';
import { UserContext } from '../../../contexts/user';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Request } from '../../../types/Request';
import { getRequest } from '../../../utils/api/calls';
import queryKeys from '../../../utils/api/queryKeys';
import { GET_RECIPIENT_REQUESTS } from '../../../utils/api/urls';

const Requests = () => {
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const smallScreen = useMediaQuery('(max-width: 1204px)');
  const [requests, setRequests] = useState<Request[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getRecipientRequests, page],
    () =>
      getRequest({
        url: GET_RECIPIENT_REQUESTS(
          `?page=${page}&limit=20&user_id=${user?.id}`
        ),
      }),
    {
      onSuccess(response) {
        setRequests(response?.data?.requests || []);
        setTotal(response?.data?.page_info?.total || 0);
        setCount(response?.data?.page_info?.total_pages || 0);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  const handleShowDetails = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  return (
    <PageTitle title="Your Requests">
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card title="Your Requests" mobile={smallScreen} count={total}>
          <Pagination {...{ page, setPage, count }} />
          <RequestsList
            {...{ setSelectedIndex: handleShowDetails, requests, isLoading }}
          />
        </Card>
      </div>

      <RequestModal
        {...{
          open,
          onClose: () => setOpen(false),
          selectedIndex,
          setSelectedIndex,
          requests,
        }}
      />
    </PageTitle>
  );
};

export default Requests;
