import { FC, useState } from 'react';

import Text from '../Text';
import AccordionItem from './AccordionItem';
import AccordionProps from './AccordionProps';

const Accordion: FC<AccordionProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<null | number>(null);

  const handleIndexChange = (index: number) => {
    setActiveIndex((prevState) => (prevState === index ? null : index));
  };

  return (
    <div className="flex w-full flex-col text-left">
      <AccordionItem
        isActive={activeIndex === 0}
        title="How does J Blood Match work?"
        onClick={() => handleIndexChange(0)}
        body={
          <>
            <Text>
              J Blood Match is an artificial intelligence that connects
              voluntary blood donors to recipients using their blood type and
              location. Therefore, it is a community where a registered blood
              donor can request for another blood donor to come to the aid of a
              recipient in the hospital.
            </Text>
            <Text>
              It requires you to register on the artificial intelligence on
              Telegram or Facebook Messenger by following the prompts.
              Afterwards, you will begin to receive requests, have the
              opportunity to accept them and donate blood at a registered
              hospital. You can also make a request for a blood donor should the
              need arise and learn more about blood donation as you navigate the
              navigation bar.
            </Text>
          </>
        }
      />
      <AccordionItem
        isActive={activeIndex === 1}
        title="What is the registration process? Are there special requirements for registering on J Blood Match?"
        onClick={() => handleIndexChange(1)}
        body={
          <Text>
            No, you do not need any special requirements to register on J Blood
            Match. It is open to everyone to register as a blood donor (at
            which, you can proceed to register as an advocate, if you are not
            eligible to make blood donations). You only have to follow this
            link:{' '}
            <a
              className="text-primary-main"
              href="http://www.jbloodmatch.org/app"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.jbloodmatch.org/app
            </a>{' '}
            and follow the prompts then you will be all set up in a 1-minute
            process!
          </Text>
        }
      />
      {data.map((item, id) => {
        const index = id + 2;

        return (
          <AccordionItem
            isActive={activeIndex === index}
            title={item.title}
            body={<Text>{item.description}</Text>}
            onClick={() => handleIndexChange(index)}
          />
        );
      })}
    </div>
  );
};

export default Accordion;
