import { FC, PropsWithChildren } from 'react';
import BackdropProps from './BackdropProps';

const Backdrop: FC<PropsWithChildren<BackdropProps>> = ({
  open,
  onClose,
  children,
}) => {
  return open ? (
    <div className="fixed top-0 left-0 z-[100] flex h-full w-full items-center justify-center bg-black/50">
      <div
        className="absolute top-0 left-0 -z-10 h-full w-full"
        onClick={() => {
          onClose && onClose();
        }}
      />
      {children}
    </div>
  ) : (
    <></>
  );
};

export default Backdrop;
