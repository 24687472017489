import Heading from '../../lib/Heading';
import Text from '../../lib/Text';
import { bloddChart } from './data';

const BloodChart = () => {
  return (
    <div className="mb-10 flex w-full flex-col">
      <div className="flex w-full items-center justify-center bg-primary-main p-3 lg:p-7">
        <Heading type="h3" className="text-center text-white">
          Compatible Blood Types
        </Heading>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <td align="center" className="w-[33.33%] py-3 lg:py-7">
              <Heading type="h3" className="!text-[.65rem] md:!text-base">
                Blood Type
              </Heading>
            </td>
            <td
              align="center"
              className="border-x border-[#8B888780] py-3 md:border-none lg:py-7"
            >
              <Heading type="h3" className="!text-[.65rem] md:!text-base">
                Donate Blood To
              </Heading>
            </td>
            <td align="center" className="w-[33.33%] py-3 lg:py-7">
              <Heading type="h3" className="!text-[.65rem] md:!text-base">
                Receive Blood From
              </Heading>
            </td>
          </tr>
        </thead>
        <tbody>
          {bloddChart.map((item, index) => (
            <tr
              key={index}
              className={index % 2 !== 0 ? 'bg-[#F2F1F64D]' : 'bg-[#FFC37126]'}
            >
              <td align="center" className="w-[33.33%] py-3 lg:py-7">
                <Text className="text-xs md:text-base">{item.type}</Text>
              </td>
              <td
                align="center"
                className="border-x border-[#8B888780] py-3 md:border-none lg:py-7"
              >
                <Text className="text-xs md:text-base">{item.donateTo}</Text>
              </td>
              <td align="center" className="w-[33.33%] py-3 lg:py-7">
                <Text className="text-xs md:text-base">{item.receiveFrom}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BloodChart;
