import { onMessage, getMessaging, getToken } from 'firebase/messaging';
import firebaseApp from '.';

const messaging = getMessaging(firebaseApp);

export const getNotificationToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_MESSAGING_KEY,
    });
    if (currentToken) {
      return currentToken;
    } else {
      return '';
    }
  } catch (err) {
    console.error('An error occurred while retrieving token. ', err);
    return '';
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
