import { useCallback, useState } from 'react';

const useTimer = (initialTime: number = 60) => {
  const [time, setTime] = useState(initialTime);

  const startTimerWrapper = useCallback(
    (func: (intervalfn: NodeJS.Timeout) => void) => {
      let timeInterval: NodeJS.Timer;
      return () => {
        if (timeInterval) {
          clearInterval(timeInterval);
        }
        setTime(60);
        timeInterval = setInterval(() => {
          func(timeInterval);
        }, 1000);
      };
    },
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timer = useCallback(
    startTimerWrapper((intervalfn: NodeJS.Timeout) => {
      setTime((val) => {
        if (val === 0) {
          clearInterval(intervalfn);
          return val;
        }
        return val - 1;
      });
    }),
    []
  );

  return { time, startTimer: timer };
};

export default useTimer;
