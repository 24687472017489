import React, { createContext, useEffect, useReducer } from 'react';

type User = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  image?: string;
  blood_group?: string;
  gender?: string;
  dob?: string;
  state_id?: number;
  longitude?: number;
  latitude?: number;
};

type UserObject = {
  user: User | null;
};

type LOGIN_ACTION = {
  type: 'LOGIN';
  userData: User;
};

type LOGOUT_ACTION = {
  type: 'LOGOUT';
};

type ActionType = LOGIN_ACTION | LOGOUT_ACTION;

const InitialState: UserObject = {
  user: null,
};

const UserContext: React.Context<{
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
}> = createContext({
  user: null as User | null,
  login: (userData) => {},
  logout: () => {},
});

const UserReducer = (state: UserObject, action: ActionType) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        user: action.userData,
      };

    case 'LOGOUT':
      return {
        user: null,
      };

    default:
      return state;
  }
};

const UserProvider = (props: any) => {
  const [state, dispatch] = useReducer(UserReducer, InitialState);

  const login = (userData: User) => {
    localStorage.setItem(
      'user',
      JSON.stringify({
        blood_group: userData?.blood_group,
        dob: userData?.dob,
        email: userData?.email,
        first_name: userData?.first_name,
        gender: userData?.gender,
        id: userData?.id,
        image: userData?.image,
        last_name: userData?.last_name,
        state_id: userData?.state_id,
        longitude: userData?.longitude,
        latitude: userData?.latitude,
      })
    );

    dispatch({
      type: 'LOGIN',
      userData,
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch({
      type: 'LOGOUT',
    });
  };

  useEffect(() => {
    const userData = localStorage.getItem('user');

    if (userData) {
      login(JSON.parse(userData));
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        login,
        logout,
      }}
      {...props}
    />
  );
};

export { UserContext, UserProvider };
