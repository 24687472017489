import { FC } from 'react';
import { DonationHistoryCardProps } from './DonationHistoryProps';
import Blood from '../../../../../assets/icons/Blood.svg';
import Text from '../../../../lib/Text';
import { ImArrowRight2 } from 'react-icons/im';
import moment from 'moment';
import { Link } from 'react-router-dom';

const DonationHistoryCard: FC<DonationHistoryCardProps> = ({
  date,
  variant,
}) => {
  return (
    <Link to="/app/history">
      <div className="relative flex items-center gap-5 overflow-hidden rounded-lg bg-[#FCF9F8] p-5 pl-7">
        <div
          className={`absolute top-0 left-0 h-full w-2 ${
            variant === 'dark' ? 'bg-primary-main' : 'bg-primary-light'
          }`}
        />

        <img src={Blood} alt="blood" className="h-5 w-3" />

        <div className="flex flex-1 flex-col gap-1">
          <Text className="!font-interSemibold capitalize">Blood Donation</Text>
          <Text className="text-jblood-gray" type="caption">
            {moment(date).format('DD MMMM YYYY, hh:mm A')}
          </Text>
        </div>

        <Text type="caption" className="flex cursor-pointer items-center gap-2">
          Details
          <ImArrowRight2
            size={15}
            className="cursor-pointer text-jblood-black"
          />
        </Text>
      </div>
    </Link>
  );
};

export default DonationHistoryCard;
