import { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParams';
import { patchRequest } from '../../utils/api/calls';
import { RESET_PASSWORD } from '../../utils/api/urls';
import FormContainer from '../../components/containers/FormContainer';
import Heading from '../../components/lib/Heading';
import Button from '../../components/lib/Button';
import useErrorHandler from '../../hooks/useErrorHandler';
import Input from '../../components/form/Input';
import { isEmpty, validateResetPasswordInputs } from '../../utils/validators';
import toast from 'react-hot-toast';
import Text from '../../components/lib/Text';

const initialState = {
  password: '',
  confirmPassword: '',
};

const PasswordReset = () => {
  const token = useQueryParam().get('token');
  const navigate = useNavigate();
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const handler = useErrorHandler();

  const { mutate, isLoading } = useMutation(patchRequest, {
    onSuccess() {
      toast.success('Password reset successfully.');
      navigate('/login');
    },
    onError(error: any) {
      handler(error);
    },
  });

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } =
      validateResetPasswordInputs(payload);

    if (valid) {
      mutate({
        url: RESET_PASSWORD,
        data: {
          token,
          password: payload.password,
        },
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <FormContainer showBackArrow={false}>
        <div className="flex min-h-[70vh] w-full max-w-[500px] flex-col justify-center gap-3 lg:min-h-[60vh] lg:max-w-[650px] lg:gap-5">
          <Heading
            underlined
            className="mx-auto w-[60vw] text-center text-2xl md:w-auto"
          >
            Reset Password
          </Heading>

          <Text className="max-w-[35ch] text-center lg:max-w-none">
            Reset your password by filling out the form below.
          </Text>

          <Input
            label="Password"
            type="password"
            placeholder="********"
            name="password"
            value={payload.password}
            onChange={handleChange}
            error={!isEmpty(errors.password)}
            helperText={errors.password}
          />

          <Input
            label="Confirm Password"
            type="password"
            placeholder="********"
            name="confirmPassword"
            value={payload.confirmPassword}
            onChange={handleChange}
            error={!isEmpty(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />

          <Button
            size="medium"
            variant="gradient"
            className="mx-auto mt-10 w-full max-w-[500px]"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSubmit}
          >
            Reset
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default PasswordReset;
