import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import toaster from 'react-hot-toast';
import FeedbackOptions from '../../../components/auth/Feedback/FeedbackOptions';
import PageTitle from '../../../components/containers/PageTitle';
import Button from '../../../components/lib/Button';
import Card from '../../../components/lib/Card';
import Text from '../../../components/lib/Text';
import { RequestContext } from '../../../contexts/request';
import { RoleContext } from '../../../contexts/role';
import { UserContext } from '../../../contexts/user';
import useErrorHandler from '../../../hooks/useErrorHandler';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { postRequest } from '../../../utils/api/calls';
import { GIVE_FEEDBACK } from '../../../utils/api/urls';
import { isEmpty } from '../../../utils/validators';
import Select from '../../../components/form/Select';
import {
  donorFeedbackOptions,
  recipientFeedbackOptions,
} from '../../../utils/constants';

const GiveFeedback = () => {
  const { user } = useContext(UserContext);
  const { request } = useContext(RequestContext);
  const { role } = useContext(RoleContext);
  const navigate = useNavigate();
  const smallScreen = useMediaQuery('(max-width: 1023px)');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const handler = useErrorHandler();

  useEffect(() => {
    if (!request) {
      navigate('/app/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      toaster.success('Feedback submitted successfully.');
      navigate(-1);
    },
    onError(error) {
      handler(error);
    },
  });

  const handleSubmit = () => {
    setError('');

    if (!isEmpty(description)) {
      mutate({
        url: GIVE_FEEDBACK,
        data: {
          type: role,
          request_id: String(request?.id),
          name: `${user?.first_name || ''} ${user?.last_name || ''}`,
          hospital: request?.hospital,
          description,
        },
      });
    } else {
      setError('Description cannot be empty');
    }
  };

  return (
    <PageTitle title="Give Feedback" leftComponent={<FeedbackOptions />}>
      <div className="w-full max-w-[900px] lg:w-[60vw]">
        <Card
          title="Give Feedback"
          titleComponent={<FeedbackOptions />}
          mobile={smallScreen}
        >
          <div className="flex w-full flex-col gap-5 lg:w-[600px]">
            <Text className="!font-interLight !leading-loose">
              <span>
                {role === 'donor'
                  ? 'Thank you for accepting a blood donation request. You have taken a huge step in saving a life!'
                  : 'Thank you for making a request on J Blood Match. We believe that this solution has helped in closing the blood donation gap! We hope that when you receive a request, you will also respond to it to save a life.'}
              </span>
              <br />
              <br />
              <span>
                Kindly look through the options below and pick the ones that
                apply to you the most.
              </span>
            </Text>

            <Select
              options={[
                {
                  key: 'Select Option',
                  value: '',
                },
                ...(role === 'donor'
                  ? donorFeedbackOptions
                  : recipientFeedbackOptions
                ).map((item) => ({
                  key: item,
                  value: item,
                })),
              ]}
              label="Describe your experience"
              placeholder="Enter description"
              name="description"
              value={description}
              onChange={(event) => setDescription(event?.currentTarget.value)}
              error={!isEmpty(error)}
              helperText={error}
            />

            <Button
              size="medium"
              className="mx-auto my-10"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Send
            </Button>
          </div>
        </Card>
      </div>
    </PageTitle>
  );
};

export default GiveFeedback;
