export const faqs = [
  {
    title: 'Is J Blood Match for Abuja residents only?',
    description:
      'No. Jela’s Development Initiatives, the organization behind J Blood Match is currently headquartered in Abuja but this solution is live across all 36 states of Nigeria, and the FCT. Anyone with a smart phone can access J Blood Match in Nigeria.',
  },
  {
    title: 'Do I get paid for donating blood on the platform?',
    description:
      'No. JBM encourages non-remunerated blood donation, according to World Health Organisation (WHO) standards because we believe that there is no price tag that can be placed on life. However, a blood donor may accept transportation reimbursement from the recipient if he/she so wishes or has a need for it.',
  },
  {
    title: 'How do I know if I can donate blood?',
    description:
      'J Blood Match’s role in the blood donation process is to connect the voluntary blood donor to the recipient. As such, you will have to go to the hospital first and undergo the procedural screening tests to confirm if he or she is medically fit for blood donation.',
  },
  {
    title:
      'Is there a way I can support the cause if I am medically unable to donate blood?',
    description:
      'We acknowledge that there are a number of medical reasons why you may not be able to donate blood. In such instances, JBM has provision for you to become a J Blood Match advocate, spreading the awesome word about J Blood Match and helping other blood donors sign up on the platform!',
  },
  {
    title: 'How often will I receive donation requests?',
    description:
      'Blood donation requests are sent to donors based on their proximity to the hospital where requests come from and the needed blood type. As such an individual who matches with a recipient will keep receiving requests until he or she donates and gives feedback, at which point an interval of 3-4 months will pass before another request may be sent.',
  },
  {
    title: 'How do I locate the recipient who has requested for blood?',
    description:
      'Once you accept a blood donation request, J Blood Match sends your phone number to the recipient who will call you and direct you the hospital where the patient is. All blood donation requests are required to come from a registered hospital. As such, J Blood Match will never match you to a request that is not from a hospital.',
  },
  {
    title:
      'What do I do if I receive a request but am unavailable to donate blood?',
    description:
      'We understand that you may be out of town or if you are female, you may be on your period and these will hinder you from donating blood. In that situation, we need you to respond to the request by rejecting it and stating why. This provides data to the artificial intelligence to work smarter, and space out when the next request will come your way.',
  },
  {
    title:
      'Why do donors have to give feedback on J Blood Match after donating blood?',
    description:
      'Donation feedbacks are important for donors because it enables JBM to have the relevant information for monitoring purposes. It also helps the artificial intelligence know not to send a request to donate blood to someone who has just recently donated. In the case of men, blood donation requests may be sent after 3 months and for women, it may be 4 months.',
  },
  {
    title:
      'Is there a limit to the number of times I can request for a blood donor?',
    description:
      'No, there is no limit to the number of times you can request a blood donor as we understand that certain medical conditions may require recurring blood transfusions. However, you can only request a maximum of 4 units of blood at a time after which you can make a fresh request.',
  },
  {
    title: 'Do I pay the donor?',
    description:
      'We follow a non-remunerated blood donation model, according to World Health Organisation (WHO) standards. This means that every blood donor registered on J Blood Match does so on a voluntary basis and is not expected to receive pay for donating blood. However, we encourage recipients to offer to pay the transport fare of the donor unless he or she declines.',
  },
  {
    title:
      'Why do blood donation recipients have to give feedback on J Blood Match after connection with a blood donor?',
    description:
      'Donation feedbacks are important for recipients because it enables the JBM to have the relevant information for monitoring purposes. It is a means of confirming the efficacy of our solution. So, we encourage you to always go back to give feedback. It improves our work!',
  },
  {
    title: 'Is my data safe on J Blood Match?',
    description:
      'We do not share your information with third parties. As such, all data collected are confidential. Also, the only data that J Blood Match collects are your location (not specific address), blood type, age and phone number. These data are all vital to helping the artificial intelligence make the match between donor and recipient.',
  },
  {
    title: 'How do I report foul usage of J Blood Match?',
    description:
      'On the navigation bar, we have a tab you can click on to ‘Report Abuse’. Once you click on it, you may state your complaint.',
  },
];
