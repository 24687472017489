import { FC, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import toaster from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { patchRequest } from '../../../../utils/api/calls';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import Form4 from './Form4';
import { FormState } from './FormsProps';
import { useNavigate } from 'react-router-dom';
import {
  UPDATE_NOTIFICATION_TOKEN,
  UPDATE_USER,
} from '../../../../utils/api/urls';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { isEmpty, notificationIsSupported } from '../../../../utils/validators';
import { UserContext } from '../../../../contexts/user';
import { RoleContext } from '../../../../contexts/role';
import { getNotificationToken } from '../../../../firebase/messaging';

const initialState = {
  gender: '',
  dob: '',
  blood_group: '',
  state_id: '',
  longitude: null,
  latitude: null,
  last_donation_date: '',
  address: '',
};

const Forms: FC<{
  activeStep: number;
  setActiveStep: (step: number) => void;
  handleNext: () => void;
}> = ({ activeStep, setActiveStep, handleNext }) => {
  const { login } = useContext(UserContext);
  const { setRole } = useContext(RoleContext);
  const [payload, setPayload] = useState<FormState>(initialState);
  const navigate = useNavigate();
  const handler = useErrorHandler();

  const { mutate: updateNotificationToken } = useMutation(patchRequest, {
    onError(error: any) {
      handler(error);
    },
  });

  const { mutate, isLoading } = useMutation(patchRequest, {
    onSuccess(response) {
      toaster.success('You have onboarded successfully!');
      setRole('donor');
      localStorage.setItem('role', 'donor');

      /////////// SUBSCRIBING TO PUSH NOTIFICATIONS START //////////////

      // Request notification permission from user and the send it to
      // the backend once the user has granted permission.
      notificationIsSupported() &&
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            // Because we're on the web, we cannot get get device ID for
            // firebase messaging. We will then generate a UUID, persist
            // it and then use that for the device ID instead
            let generatedDeviceId = '';

            if (localStorage.getItem('device_id')) {
              generatedDeviceId = localStorage.getItem('device_id') as string;
            } else {
              const newId = uuidv4();
              generatedDeviceId = newId;
              localStorage.setItem('device_id', newId);
            }

            getNotificationToken()
              .then((response) => {
                updateNotificationToken({
                  url: UPDATE_NOTIFICATION_TOKEN,
                  data: {
                    notification_token: response,
                    device_id: generatedDeviceId,
                  },
                });
              })
              .catch(() => {
                toaster.error('Could not get notification token.');
              });
          }
        });
      /////////// SUBSCRIBING TO PUSH NOTIFICATIONS END /////////////////

      login(response?.data?.user);
      navigate('/app/dashboard');
    },
    onError(error: any) {
      handler(error);
    },
  });

  const handleSubmitForm = () => {
    mutate({
      url: UPDATE_USER,
      data: {
        ...payload,
        last_donation_date: isEmpty(payload?.last_donation_date || '')
          ? null
          : payload.last_donation_date,
      },
    });
  };

  switch (activeStep) {
    case 1:
      return <Form1 />;

    case 2:
      return <Form2 {...{ handleNext, payload, setPayload }} />;

    case 3:
      return <Form3 {...{ handleNext, payload, setPayload }} />;

    case 4:
      return (
        <Form4
          {...{ handleNext, payload, setPayload, handleSubmitForm, isLoading }}
        />
      );

    default:
      return <></>;
  }
};

export default Forms;
