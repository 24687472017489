import Heading from '../../components/lib/Heading';
import PageTitle from '../../components/containers/PageTitle';
import BloodChart from '../../components/app/BloodMatchChart/BloodChart';
import { useLocation } from 'react-router-dom';

const BloodMatchChart = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="Chart">
      <div
        className={`max mx-auto flex w-full flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading underlined className="w-[60vw] text-center md:mb-10 md:w-auto">
          Blood Match Chart
        </Heading>

        <BloodChart />
      </div>
    </PageTitle>
  );
};

export default BloodMatchChart;
