import firebaseApp from '.';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

const storage = getStorage(firebaseApp);

export const uploadFile = ({
  file,
  onError,
  onSuccess,
}: {
  file: File;
  onError?: (error: any) => void;
  onSuccess?: (url: string) => void;
}) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(
      storage,
      `/files/${file.name}-${new Date().toISOString()}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    //Update progress bar
    uploadTask.on(
      'state_changed',
      (snapshot) => {},
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            console.log('storage/unauthorized');
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            console.log('storage/canceled');
            // User canceled the upload
            break;

          case 'storage/unknown':
            console.log('storage/unknown');
            // Unknown error occurred, inspect error.serverResponse
            break;

          default:
            break;
        }
        onError && onError(error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          onSuccess && onSuccess(url);
          resolve(url);
        });
      }
    );
  });
};
