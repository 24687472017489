import { FC, PropsWithChildren } from 'react';
import match from '../../../utils/match';
import TextProps from './TextProps';

const Text: FC<PropsWithChildren<TextProps>> = ({
  children,
  type = 'body1',
  className,
}) => {
  const textClassName = match(type, {
    body1: 'text-sm md:text-base font-interRegular ',
    body2: 'text-base md:text-lg font-interRegular',
    subheading: 'text-base md:text-xl font-interRegular',
    caption: 'text-sm md:base font-interRegular',
    default: '',
  });

  return (
    <p className={`${textClassName} ${className || ''} leading-relaxed`}>
      {children}
    </p>
  );
};

export default Text;
