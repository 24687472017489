import { Icon } from '@iconify/react';
import { FC, PropsWithChildren } from 'react';
import Text from '../../lib/Text';
import FileUploadProps from './FileUploadProps';

const FileUpload: FC<PropsWithChildren<FileUploadProps>> = ({
  id,
  label,
  helperLabel,
  error,
  type,
  helperText,
  file,
  ...rest
}) => {
  return (
    <div>
      <div className="">
        {label && (
          <label className="font-interSemibold text-xs md:text-sm">
            {label}{' '}
            <span className="font-interRegular text-xs text-jblood-gray">
              {helperLabel}
            </span>
          </label>
        )}
        <div
          className={`relative mt-1 flex w-full items-center border border-jblood-gray lg:mt-2 ${
            error ? 'border !border-red-500' : ''
          }`}
        >
          <label
            htmlFor={id}
            className="input min-w-80 flex h-[50.6px] flex-1 items-center justify-between border-none bg-transparent px-5 py-4 font-interRegular text-sm outline-primary-main focus:text-gray-900 lg:px-10"
          >
            <input type="file" className="hidden" id={id} {...rest} />
            <Text>{file ? file.name : 'Select File'}</Text>
            <div className="grid h-8 w-8 place-items-center rounded-full bg-gradient">
              <Icon
                icon="ic:round-file-upload"
                className="text-2xl text-white"
              />
            </div>
          </label>
        </div>
      </div>

      {helperText && (
        <span
          className={`text-xs ${error ? 'text-red-500' : 'text-jblood-black'}`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default FileUpload;
