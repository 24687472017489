import { useState } from 'react';
import { usePopper } from 'react-popper';
import { CgMoreO } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const FeedbackOptions = () => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow' }],
    placement: 'bottom-end',
  });

  return (
    <>
      <div
        className="cursor-pointer"
        ref={setReferenceElement}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <CgMoreO size={20} className="cursor-pointer" />
      </div>

      {open && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          className="z-40 flex min-w-[150px] flex-col gap-3 rounded-md border border-[#DEDEE6] bg-white p-3 px-5 shadow-sm  transition-none"
          onMouseLeave={() => setOpen(false)}
          {...attributes.popper}
        >
          <Link to="/app/report">
            <div
              className="cursor-pointer font-interSemibold text-sm hover:text-primary-dark"
              onClick={() => {
                setOpen(false);
              }}
            >
              Report Abuse
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default FeedbackOptions;
