import { FC } from 'react';
import Heading from '../../../../lib/Heading';
import ContainedLoader from '../../../../lib/Loader/ContainedLoader';
import Text from '../../../../lib/Text';
import ReminderListItem from './ReminderListItem';
import RemindersListProps from './RemindersListProps';

const RemindersList: FC<RemindersListProps> = ({ reminders, isLoading }) => {
  return isLoading ? (
    <ContainedLoader />
  ) : reminders.length === 0 ? (
    <div className="flex min-h-[200px] w-full flex-col items-center justify-center gap-1 text-center">
      <Heading type="h3">Nothing to see here!</Heading>
      <Text>You do not have any reminders at the moment.</Text>
    </div>
  ) : (
    <div className="flex flex-col">
      {reminders.map((reminder) => (
        <ReminderListItem key={reminder.id} {...reminder} />
      ))}
    </div>
  );
};

export default RemindersList;
