import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Request } from '../../../../../types/Request';
import { getRequest } from '../../../../../utils/api/calls';
import queryKeys from '../../../../../utils/api/queryKeys';
import { GET_REQUESTS_FOR_DONOR } from '../../../../../utils/api/urls';
import Card from '../../../../lib/Card';
import Text from '../../../../lib/Text';
import BloodDonationRequestsCard from './BloodDonationRequestsCard';
import useErrorHandler from '../../../../../hooks/useErrorHandler';
import ContainedLoader from '../../../../lib/Loader/ContainedLoader';
import NoContent from '../../../../lib/NoContent';

const BloodDonationRequests = () => {
  const [requests, setRequests] = useState<
    {
      request: Request;
    }[]
  >([]);
  const [total, setTotal] = useState(0);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getRequestsForDonor],
    () => getRequest({ url: GET_REQUESTS_FOR_DONOR('?limit=5') }),
    {
      onSuccess(response) {
        setRequests(response?.data?.donations || []);
        setTotal(response?.data?.page_info?.total || 0);
      },
      onError(error: any) {
        handler(error);
      },
    }
  );

  return (
    <Card
      title="Blood Donation Requests"
      count={total}
      className="break-inside mb-10 md:mb-5"
      titleComponent={
        <Link to="/app/requests">
          <Text className="font-interSemibold text-jblood-gray" type="caption">
            View all
          </Text>
        </Link>
      }
    >
      {isLoading ? (
        <ContainedLoader />
      ) : requests.length === 0 ? (
        <NoContent />
      ) : (
        <div className="flex flex-col gap-5">
          {requests.map((item, index) => (
            <BloodDonationRequestsCard key={index} {...item.request} />
          ))}
        </div>
      )}
    </Card>
  );
};

export default BloodDonationRequests;
