import React, { createContext, useReducer } from 'react';

export type Role = 'donor' | 'recipient';

type RoleState = {
  role: Role;
};

type ActionType = {
  type: 'SET';
  role: Role;
};

const InitialState: RoleState = {
  role: ['donor', 'recipient'].includes(localStorage.getItem('role') as string)
    ? (localStorage.getItem('role') as Role)
    : 'donor',
};

const RoleContext: React.Context<{
  role: Role;
  setRole: (role: Role) => void;
}> = createContext({
  role: 'donor' as Role,
  setRole: (role: Role) => {},
});

const RoleReducer = (state: RoleState, action: ActionType) => {
  switch (action.type) {
    case 'SET':
      return {
        role: action.role,
      };

    default:
      return state;
  }
};

const RoleProvider = (props: any) => {
  const [state, dispatch] = useReducer(RoleReducer, InitialState);

  const setRole = (role: Role) => {
    localStorage.setItem('role', role);

    dispatch({
      type: 'SET',
      role,
    });
  };

  return (
    <RoleContext.Provider
      value={{
        role: state.role,
        setRole,
      }}
      {...props}
    />
  );
};

export { RoleContext, RoleProvider };
