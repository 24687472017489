import { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import toaster from 'react-hot-toast';
import { postRequest } from '../../../../utils/api/calls';
import { isEmpty, validateRegisterInputs } from '../../../../utils/validators';
import Input from '../../../form/Input';
import Button from '../../../lib/Button';
import Heading from '../../../lib/Heading';
import Text from '../../../lib/Text';
import { LOGIN, REGISTER } from '../../../../utils/api/urls';
import useTimer from '../../../../hooks/useTimer';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import Checkbox from '../../../form/Checkbox';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  password: '',
};

const Form1 = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const { pathname } = useLocation();
  const { time, startTimer } = useTimer();
  const handler = useErrorHandler();

  const { mutate, isLoading } = useMutation(postRequest, {
    onSuccess() {
      setIsSubmitted(true);
      startTimer();
      localStorage.setItem(
        'user_name',
        `${payload.first_name || ''} ${payload?.last_name || ''}`
      );
    },
    onError(error: any) {
      handler(error);
    },
  });

  const { mutate: resendCode, isLoading: resendCodeIsLoading } = useMutation(
    postRequest,
    {
      onSuccess() {
        toaster.success('Verification code resent');
      },
      onError(error: any) {
        toaster.error(
          error?.response?.data?.message ||
            'Something went wrong, try again later.'
        );
      },
    }
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      [event.target.name]: event.target.value,
    });
  };

  const handleResendVerificationCode = () => {
    resendCode({
      url: LOGIN,
      data: {
        email: payload.email,
        password: payload.password,
        base_url: `${window.location.href.split(pathname)[0]}/`,
      },
    });
  };

  const handleSubmit = () => {
    setErrors(initialState);

    const { valid, errors: validationErrors } = validateRegisterInputs(payload);

    if (valid) {
      mutate({
        url: REGISTER,
        data: {
          ...payload,
          phone_number: payload.phone_number.split('+')[1],
          base_url: `${window.location.href.split(pathname)[0]}/`,
        },
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return isSubmitted ? (
    <>
      <Heading type="h3">Verify your email address</Heading>

      <Text className="mb-36">
        We have sent you an email with a verification link to{' '}
        <span className="font-interSemibold text-green-500">
          {payload.email}
        </span>
        . Please check your spam folder if you don’t find it in your inbox.
      </Text>

      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full max-w-[500px]"
        onClick={handleResendVerificationCode}
        loading={resendCodeIsLoading}
        disabled={resendCodeIsLoading || time !== 0}
      >
        {time !== 0 ? `Resend in ${time}` : 'Resend Verification Code'}
      </Button>

      <Text className="text-center" type="caption">
        Not your email address?{' '}
        <span
          className="cursor-pointer text-primary-main"
          onClick={() => setIsSubmitted(false)}
        >
          Go back
        </span>
      </Text>
    </>
  ) : (
    <>
      <Input
        label="First Name"
        placeholder="Enter your first name"
        name="first_name"
        value={payload.first_name}
        onChange={handleChange}
        error={!isEmpty(errors.first_name)}
        helperText={errors.first_name}
      />
      <Input
        label="Last Name"
        placeholder="Enter your last name"
        name="last_name"
        value={payload.last_name}
        onChange={handleChange}
        error={!isEmpty(errors.last_name)}
        helperText={errors.last_name}
      />
      <Input
        label="Email Address"
        type="email"
        placeholder="example@domain.com"
        name="email"
        value={payload.email}
        onChange={handleChange}
        error={!isEmpty(errors.email)}
        helperText={errors.email}
      />
      <Input
        label="Phone Number"
        helperLabel="(+234 format)"
        placeholder="+234XXXXXXXXXX"
        name="phone_number"
        value={payload.phone_number}
        onChange={handleChange}
        error={!isEmpty(errors.phone_number)}
        helperText={errors.phone_number}
      />
      <Input
        label="Create Password"
        type="password"
        placeholder="********"
        name="password"
        value={payload.password}
        onChange={handleChange}
        error={!isEmpty(errors.password)}
        helperText={errors.password}
      />
      <Checkbox
        checked={isChecked}
        onChange={() => setIsChecked((prevState) => !prevState)}
        label={
          <Text>
            I have read and understood the{' '}
            <Link to="/privacy-policy" className="text-primary-main underline">
              Data Protection and Privacy Policy document.
            </Link>
          </Text>
        }
      />

      <Button
        size="medium"
        variant="gradient"
        className="mx-auto mt-10 w-full max-w-[500px]"
        onClick={handleSubmit}
        loading={isLoading}
        disabled={isLoading || !isChecked}
      >
        Proceed
      </Button>

      <Text className="text-center" type="caption">
        Already have an account?{' '}
        <Link to="/login" className="text-primary-main">
          Log in
        </Link>
      </Text>
    </>
  );
};

export default Form1;
