import LinkIcon from '../../../assets/icons/Link.svg';
import Whatsapp from '../../../assets/icons/Whatsapp.svg';
import Facebook from '../../../assets/icons/Facebook.svg';
import Telegram from '../../../assets/icons/Telegram.svg';
import Instagram from '../../../assets/icons/Instagram.svg';
import { navLinks } from './links';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import { MdShare } from 'react-icons/md';

const NavLinks = () => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLLIElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow' }],
    placement: 'bottom-end',
  });

  return (
    <>
      <ul className="hidden items-center gap-5 lg:flex">
        {navLinks.map((item, index) => (
          <Link to={item.url} key={index}>
            <li className="cursor-pointer font-interSemibold">{item.title}</li>
          </Link>
        ))}

        <li
          className="cursor-pointer font-interSemibold"
          onClick={() => setOpen(true)}
          ref={setReferenceElement}
        >
          <MdShare className="text-2xl" />
        </li>
      </ul>

      {open && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          className="z-40 flex min-w-[255px] flex-col gap-3 rounded-md border border-[#DEDEE6] bg-white py-5 px-7 shadow-sm  transition-none"
          onMouseLeave={() => setOpen(false)}
          {...attributes.popper}
        >
          <span className="cursor-pointer font-interSemibold">Share Link</span>

          <div className="flex items-center gap-3">
            <img src={LinkIcon} alt="" />
            <img src={Whatsapp} alt="" />
            <img src={Facebook} alt="" />
            <img src={Telegram} alt="" />
            <img src={Instagram} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default NavLinks;
