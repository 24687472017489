import { FC } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import Heading from '../../Heading';
import AccordionItemProps from './AccordionItemProps';

const AccordionItem: FC<AccordionItemProps> = ({
  title,
  body,
  onClick,
  isActive,
}) => (
  <div className="">
    <div
      className="w-fulll flex cursor-pointer select-none items-center justify-between gap-3 border-b border-primary-dark py-5 md:py-7"
      onClick={onClick}
    >
      <Heading
        type="h3"
        className={`${isActive ? 'text-primary-dark' : 'text-jblood-black'}`}
      >
        {title}
      </Heading>
      <MdOutlineKeyboardArrowDown
        size={30}
        className={`min-w-[50px] duration-300 ${
          isActive
            ? '-rotate-90 text-primary-dark'
            : 'rotate-0 text-jblood-black'
        }`}
      />
    </div>
    <div
      className={`grid gap-5 ${
        isActive
          ? 'translate-y-0 py-5 opacity-100 lg:py-7'
          : '-translate-y-5 py-0 opacity-0 lg:py-0'
      }`}
      style={{
        visibility: isActive ? 'visible' : 'hidden',
        height: isActive ? 'auto' : '0px',
      }}
    >
      {body}
    </div>
  </div>
);

export default AccordionItem;
