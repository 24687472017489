import { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import ArticleCard from '../../components/app/Articles/ArticleCard';
import PageTitle from '../../components/containers/PageTitle';
import Heading from '../../components/lib/Heading';
import ContainedLoader from '../../components/lib/Loader/ContainedLoader';
import NoContent from '../../components/lib/NoContent';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Article } from '../../types/Article';
import { getRequest } from '../../utils/api/calls';
import queryKeys from '../../utils/api/queryKeys';
import { GET_ARTICLES } from '../../utils/api/urls';

const Articles = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');
  const [articles, setArticles] = useState<Article[]>([]);
  const handler = useErrorHandler();

  const { isLoading } = useQuery(
    [queryKeys.getArticles],
    () => getRequest({ url: GET_ARTICLES }),
    {
      onSuccess(response) {
        setArticles(response?.data?.blogs || []);
      },
      onError(error) {
        handler(error);
      },
    }
  );

  return (
    <PageTitle title="Articles">
      <div
        className={`max mx-auto flex flex-col items-center gap-10 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          Articles
        </Heading>

        {isLoading ? (
          <ContainedLoader />
        ) : articles.length === 0 ? (
          <NoContent label="There no articles to dispaly" />
        ) : (
          articles.map((article, index) => (
            <Fragment key={index}>
              <ArticleCard {...article} />

              {index !== articles.length - 1 && (
                <hr className="hidden w-full bg-[#8B888780] md:block" />
              )}
            </Fragment>
          ))
        )}
      </div>
    </PageTitle>
  );
};

export default Articles;
