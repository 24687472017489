import match from '../../../utils/match';
import HeadingProps from './HeadingProps';

const Heading = ({
  type = 'h2',
  children,
  className,
  underlined = false,
  ...rest
}: HeadingProps<'h1' | 'h2' | 'h3'>) => {
  const Component = type || 'h2';

  const headingClassName = match(type, {
    h1: 'text-3xl lg:text-5xl font-soraBold',
    h2: 'text-xl lg:text-4xl font-soraBold',
    h3: 'text-base  lg:text-xl font-soraSemibold',
    default: '',
  });

  return (
    <Component
      className={`${headingClassName} ${className || ''} ${
        underlined
          ? 'relative before:absolute before:left-0 before:bottom-0 before:-z-10 before:h-3 before:w-full before:bg-[#FFC3714D] lg:before:h-4'
          : ''
      }`}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Heading;
