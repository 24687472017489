import { FC } from 'react';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import Text from '../../../../lib/Text';
import { DonationHistoryDetailProps } from './DonationHistoryListProps';

const DonationHistoryDetail: FC<DonationHistoryDetailProps> = ({
  title,
  detail,
}) => {
  const smallScreen = useMediaQuery('(max-width: 1204px)');

  return (
    <div className="flex flex-wrap items-center gap-3">
      <div className="h-3 w-3 rounded-full bg-gradient" />
      <Text type={smallScreen ? 'caption' : 'body1'}>{title}:</Text>
      <Text
        type={smallScreen ? 'caption' : 'body1'}
        className="!font-interSemibold capitalize"
      >
        {detail}
      </Text>
    </div>
  );
};

export default DonationHistoryDetail;
