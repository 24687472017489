import { FC, PropsWithChildren, useState } from 'react';
import InputProps from './InputProps';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { BsCalendar2Event } from 'react-icons/bs';
import useMediaQuery from '../../../hooks/useMediaQuery';

const Input: FC<PropsWithChildren<InputProps>> = ({
  startIcon,
  endIcon,
  label,
  helperLabel,
  error,
  type,
  helperText,
  ...rest
}) => {
  const smallScreen = useMediaQuery('(max-width: 1200px)');
  const [visible, setVisible] = useState(type === 'password' ? false : true);

  return (
    <div>
      <div className="">
        {label && (
          <label className="font-interSemibold text-xs md:text-sm">
            {label}{' '}
            <span className="font-interRegular text-xs text-jblood-gray">
              {helperLabel}
            </span>
          </label>
        )}
        <div
          className={`relative mt-1 flex w-full items-center border border-jblood-gray focus-within:border-primary-main lg:mt-2 ${
            error ? 'border !border-red-500' : ''
          }`}
        >
          {startIcon && startIcon}
          <input
            className="input min-w-80 h-[50.6px] max-w-full flex-1 border-none bg-transparent px-5 py-4 font-interRegular text-sm outline-primary-main focus:text-gray-900 disabled:cursor-not-allowed disabled:bg-gray-200 lg:px-10"
            {...{
              type: visible
                ? type === 'password'
                  ? 'text'
                  : type
                : 'password',
              ...rest,
            }}
          />

          {(type === 'date' || type === 'datetime-local') && (
            <div className="date-icon">
              <BsCalendar2Event />
            </div>
          )}

          {endIcon && endIcon}

          {type === 'password' && (
            <div
              className="px-2"
              role="button"
              onClick={() => setVisible((prevState) => !prevState)}
            >
              {visible ? (
                <MdVisibilityOff
                  className="text-gray-500"
                  size={smallScreen ? 18 : 20}
                />
              ) : (
                <MdVisibility
                  className="text-gray-500"
                  size={smallScreen ? 18 : 20}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {helperText && (
        <span
          className={`text-xs ${error ? 'text-red-500' : 'text-jblood-black'}`}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export default Input;
