import ComingSoon from '../../components/app/LandingPage/ComingSoon';
import DonorTestimonial from '../../components/app/LandingPage/DonorTestimonial';
import Faqs from '../../components/app/LandingPage/Faqs';
import FeaturedRecipientStory from '../../components/app/LandingPage/FeaturedRecipientStory';
import Header from '../../components/app/LandingPage/Header';
import Numbers from '../../components/app/LandingPage/Numbers';
import Partner from '../../components/app/LandingPage/Partner';
import ProcessBanner from '../../components/app/LandingPage/ProcessBanner';

const LandingPage = () => {
  return (
    <>
      <Header />
      <ProcessBanner />
      <DonorTestimonial />
      <Numbers />
      <FeaturedRecipientStory />
      <Partner />
      <Faqs />
      <ComingSoon />
    </>
  );
};

export default LandingPage;
