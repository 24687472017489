const url = process.env.REACT_APP_BACKEND_URL;

// Auth
export const LOGIN = `${url}/auth/login`;
export const REGISTER = `${url}/auth/register`;
export const VERIFY_EMAIL = `${url}/auth/verify-email`;
export const FORGOT_PASSWORD = `${url}/auth/password-reset`;
export const RESET_PASSWORD = `${url}/auth/reset-password`;
export const UPDATE_USER = `${url}/user`;
export const GET_USER = `${url}/user`;
export const UPDATE_NOTIFICATION_TOKEN = `${url}/user/notification/token`;

// Utils
export const GET_STATES = `${url}/util/states`;
export const GET_EDUCATION_LEVELS = `${url}/util/education-levels`;

// Requests
export const GET_REQUESTS_FOR_DONOR = (query?: string) =>
  `${url}/requests/donations${query || ''}${query ? '&' : '?'}status=pending`;
export const GET_DONOR_REQUEST_HISTORY = (query?: string) =>
  `${url}/requests/donations${query || ''}${query ? '&' : '?'}status=confirmed`;
export const GET_RECIPIENT_REQUESTS = (query?: string) =>
  `${url}/requests${query || ''}${query ? '&' : '?'}`;
export const CONFIRM_DONATION = ({ id }: { id: number }) =>
  `${url}/requests/donor/confirm/${id}/donation`;
export const TOGGLE_REQUEST_STATUS = ({ id }: { id: number }) =>
  `${url}/requests/donor/${id}`;
export const CREATE_DONATION_REQUEST = `${url}/requests/donor`;
export const ACCEPT_OR_DECLINE_REQUEST = ({ id }: { id: number }) =>
  `${url}/requests/donor/${id}/match`;
export const GET_DONATATION_STATS = `${url}/requests/stats`;

// Reminders
export const GET_REMINDERS = (query?: string) =>
  `${url}/reminders${query || ''}`;
export const DELETE_REMINDER = (id: number) => `${url}/reminders/${id}`;

// Reasons
export const GET_DECLINE_REASONS = `${url}/util/reasons?type=decline`;
export const GET_REQUEST_REASONS = `${url}/util/reasons?type=request`;

// Feedback
export const GET_RECIPIENT_FEEDBACK = `${url}/feedbacks?type=Recipient`;
export const GET_DONOR_FEEDBACK = `${url}/feedbacks?type=Donor`;
export const GIVE_FEEDBACK = `${url}/feedbacks`;
export const GET_FFEDBACK_STATUS = ({ requestId }: { requestId: number }) =>
  `${url}/feedbacks/status?request_id=${requestId}`;
export const SHARE_TESTIMONIAL = `${url}/feedbacks/testimonials`;

// Report Abuse
export const REPORT_ABUSE = `${url}/feedbacks/report-abuse`;

// Payment
export const GET_PAYMENT_SERVICES = `${url}/util/services?type=donation`;

// Partnership
export const CREATE_DONATION = `${url}/partnerships/donations`;
export const CREATE_ADVOCATE = `${url}/partnerships/advocates`;
export const CREATE_PARTNERSHIP = `${url}/partnerships`;

// Articles
export const GET_ARTICLES = `${url}/blogs`;
export const GET_ARTICLE = (id: number) => `${url}/blogs/${id}`;
