import { FC } from 'react';
import GoogleMapReact from 'google-map-react';
import { MdKeyboardArrowLeft, MdLocationOn } from 'react-icons/md';
import Button from '../../../../lib/Button';
import Modal from '../../../../lib/Modal';
import AcceptRequestDetail from './AcceptRequestDetail';
import AcceptRequestModalProps from './AcceptRequestModalProps';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import Avatar from '../../../../lib/Avatar';
import Text from '../../../../lib/Text';

const AcceptRequestModal: FC<AcceptRequestModalProps> = ({
  open,
  onClose,
  request,
}) => {
  const smallScreen = useMediaQuery('(max-width: 768px)');
  const data = request;

  const position = {
    center: {
      lat: data?.latitude ? Number(data?.latitude) : 1,
      lng: data?.latitude ? Number(data?.longitude) : 1,
    },
    zoom: 15,
  };

  return (
    <Modal
      {...{ open, onClose }}
      className="fixed top-0 left-0 flex h-[100%] w-[100vw] lg:relative lg:max-h-[80vh] lg:max-w-[600px] lg:items-end lg:justify-center lg:rounded-lg lg:bg-[#FCF9F8]"
    >
      {!data ? (
        <div className="flex w-[90vw] items-center justify-center rounded-md p-10 text-center">
          <Text>No request has been selected</Text>
        </div>
      ) : (
        <>
          <figure className="absolute left-[50%] -top-[7%] z-20 hidden h-16 w-16 -translate-x-[50%] overflow-hidden rounded-full border-4 border-white md:h-20 md:w-20 md:border-8 lg:block">
            {data?.image ? (
              <img
                src={data?.image}
                alt="User Avatar"
                className="h-full w-full object-cover"
              />
            ) : (
              <Avatar
                className="!h-full !w-full"
                name={`${data?.user?.first_name} ${data?.user?.last_name}`}
              />
            )}
          </figure>

          <button
            className="absolute top-5 left-5 z-20 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white text-2xl lg:block lg:bg-transparent"
            onClick={onClose}
          >
            <MdKeyboardArrowLeft className="text-2xl" />
          </button>
          <div className="relative flex max-h-[75vh] w-[100vw] max-w-[600px] flex-col items-center gap-3 self-end overflow-y-auto bg-transparent px-10 py-10 lg:max-h-[95vh] lg:self-auto lg:py-10">
            <div className="flex w-full flex-col gap-5 rounded-lg py-3 lg:px-10">
              <AcceptRequestDetail
                icon="fontisto:blood-drop"
                title="Needs"
                detail={data?.reason}
              />
              <AcceptRequestDetail
                icon="carbon:location-filled"
                title="Location"
                detail={data?.hospital}
              />
              {/* <AcceptRequestDetail
                icon="ic:baseline-access-time-filled"
                title="Distance"
                detail="10km, 20 mins away from your location"
              /> */}
              {/* <AcceptRequestDetail
                icon="carbon:phone-filled"
                title="Phone Number"
                detail={
                  data?.user?.phone_number ? `+${data?.user?.phone_number}` : ''
                }
              /> */}

              <div className="fixed top-0 left-0 -z-10 h-full w-full bg-green-300/50 lg:static lg:z-0 lg:h-[180px] lg:overflow-hidden lg:rounded-lg">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY || '',
                  }}
                  defaultCenter={position.center}
                  defaultZoom={position.zoom}
                >
                  <MdLocationOn className="text-3xl text-primary-main" />
                </GoogleMapReact>
              </div>
            </div>

            <a
              href={`https://www.google.com/maps/search/?api=1&query=${data?.latitude},${data?.longitude}`}
              target="_blank"
              rel="noreferrer"
              className="mx-auto flex w-full justify-center"
            >
              <Button
                size={smallScreen ? 'large' : 'small'}
                className={smallScreen ? '!w-full' : ''}
              >
                Use Map
              </Button>
            </a>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AcceptRequestModal;
