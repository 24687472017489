import { useLocation } from 'react-router-dom';
import { faqs } from '../../components/app/FAQs/data';
import PageTitle from '../../components/containers/PageTitle';
import Accordion from '../../components/lib/Accordion';
import Heading from '../../components/lib/Heading';

const FAQs = () => {
  const location = useLocation();
  const isAuthScreen = location.pathname.startsWith('/app');

  return (
    <PageTitle title="FAQs">
      <div
        className={`max mx-auto flex flex-col items-center gap-5 lg:gap-7 ${
          isAuthScreen
            ? 'px-0 pb-5 pt-0 md:px-10 md:pb-10 md:pt-0'
            : 'p-5 py-10 md:p-10 lg:px-20 lg:py-16'
        }`}
      >
        <Heading
          underlined
          className={`w-[60vw] md:mb-10 md:w-auto ${
            isAuthScreen ? 'text-center lg:text-left' : 'text-center'
          }`}
        >
          Frequently Asked Questions
        </Heading>

        <div>
          <Accordion data={faqs} />
        </div>
      </div>
    </PageTitle>
  );
};

export default FAQs;
