import { Link } from 'react-router-dom';
import AppLogo from '../../../assets/images/logo-full.png';

const Logo = ({ url = '/' }: { url?: string }) => {
  return (
    <Link to={url}>
      <figure className="z-50 h-auto w-[40vw] max-w-[150px] md:w-[20vw]">
        <img src={AppLogo} alt="JBM Logo" />
      </figure>
    </Link>
  );
};

export default Logo;
