export const LENGTH_OF_THE_LONGEST_WORD_IN_THE_DICTIONARY = 45;

export const genders = [
  {
    key: 'Male',
    value: 'Male',
  },
  {
    key: 'Female',
    value: 'Female',
  },
];

export const bloodGroups = [
  {
    key: 'A+',
    value: 'A+',
  },
  {
    key: 'A-',
    value: 'A-',
  },
  {
    key: 'B+',
    value: 'B+',
  },
  {
    key: 'B-',
    value: 'B-',
  },
  {
    key: 'AB+',
    value: 'AB+',
  },
  {
    key: 'AB-',
    value: 'AB-',
  },
  {
    key: 'O+',
    value: 'O+',
  },
  {
    key: 'O-',
    value: 'O-',
  },
];

export const reasons = [
  {
    id: 1,
    name: 'Cancer',
  },
  {
    id: 2,
    name: 'Dialysis',
  },
  {
    id: 3,
    name: 'Sickle Cell',
  },
  {
    id: 4,
    name: 'HIV',
  },
  {
    id: 5,
    name: 'Anaemia',
  },
  {
    id: 6,
    name: 'Bleeding in pregnancy',
  },
  {
    id: 7,
    name: 'Pregnancy related surgery',
  },
  {
    id: 8,
    name: 'Postpartum haemorrhage',
  },
  {
    id: 9,
    name: 'Paediatrics (Medical & surgical related cases of children)',
  },
  {
    id: 10,
    name: 'Road traffic accident',
  },
  {
    id: 11,
    name: 'Gunshot',
  },
  {
    id: 12,
    name: 'Bomb Blast',
  },
  {
    id: 13,
    name: 'Severe Electric Shock',
  },
  {
    id: 14,
    name: 'Other Reasons',
  },
];

export const cardCurrencies = [
  {
    value: 'NGN',
    name: 'Naira',
  },
];

export const cryptoCurrencies = [
  {
    value: 'NGN',
    name: 'Nigerian Naira',
  },
  {
    value: 'USD',
    name: 'United States Dollars',
  },
  {
    value: 'AED',
    name: 'United Arab Emirates Dirham,',
  },
  {
    value: 'GBP',
    name: 'Great Britain Pounds',
  },
  {
    value: 'EUR',
    name: 'Euros',
  },
];

export const months = [
  {
    key: 'January',
    value: '01',
  },
  {
    key: 'February',
    value: '02',
  },
  {
    key: 'March',
    value: '03',
  },
  {
    key: 'April',
    value: '04',
  },
  {
    key: 'May',
    value: '05',
  },
  {
    key: 'June',
    value: '06',
  },
  {
    key: 'July',
    value: '07',
  },
  {
    key: 'August',
    value: '08',
  },
  {
    key: 'September',
    value: '09',
  },
  {
    key: 'October',
    value: '10',
  },
  {
    key: 'November',
    value: '11',
  },
  {
    key: 'December',
    value: '12',
  },
];

export const donorFeedbackOptions = [
  'I donated blood successfully, without any hitches',
  'I was not able to donate blood because I was medically unfit',
  'I was not able to donate blood because I recently finished my period',
  'I was not able to donate blood because I weigh less than 50kg',
  'I was not able to donate blood because my blood pressure was high',
  'I was not able to donate blood because my PCV was low',
  'I was not able to donate blood because I am taking medications that hinder blood donation',
  'I did not donate blood because a donor was no longer needed',
  'I was not able to donate blood because I had other pressing matters to attend to',
  'The blood donation process was strenuous but successful',
  'The recipient wanted to go to a location different from what appeared on J Blood Match',
  'The recipient made a request as a prank',
  'The recipient refused to pay a reasonable sum for transportation, even after request was made. ',
  ' The recipient wanted to go to a location different from what appeared on J Blood Match',
  'Blood donation was successful',
  'Blood donation was not successful',
];

export const recipientFeedbackOptions = [
  'The donor did not show up as agreed',
  'The donor donated blood without hitches',
  'The donor requested for payment (other than a reasonable sum for transport fare) before donation',
  'The donor requested for payment (other than a reasonable sum for transport fare) after donation',
  'The donor was unreachable after accepting the request and confirming availability to donate blood',
  'The donor took pictures of patient without consent',
  'The donor was unreachable after accepting the request and confirming availability to donate blood',
  'The donor took pictures of patient without consent',
];
