import moment from 'moment';

export const getAge = (date?: string) => {
  return date ? moment().diff(moment(date, 'YYYY'), 'years') : '';
};

export const greetUser = (name: string) => {
  const currentHour = Number(moment().format('HH'));

  if (currentHour >= 0 && currentHour < 12) {
    return `Good Morning ${name}`;
  } else if (currentHour >= 12 && currentHour < 15) {
    return `Good Afternoon ${name}`;
  } else if (currentHour >= 15 && currentHour < 23.9) {
    return `Good Evening ${name}`;
  } else {
    return `Hello ${name}`;
  }
};

export const trimString = (string: string, length?: number) => {
  const maxLength = length || 50;

  return string.length < maxLength
    ? string
    : `${string.substring(0, maxLength)}...`;
};
