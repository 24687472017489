import { FC } from 'react';
import { Icon } from '@iconify/react';
import { Link, useLocation } from 'react-router-dom';
import Text from '../../lib/Text';
import { SidebarLinkProps } from './SidebarProps';

const SidebarLink: FC<SidebarLinkProps> = ({ icon, label, url, onClick }) => {
  const location = useLocation();

  return (
    <Link
      to={url}
      className="group flex w-full items-center gap-3"
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="flex h-7 w-7 items-center justify-center">
        <Icon
          icon={icon}
          className={`text-xl text-jblood-gray duration-100 group-hover:text-primary-main ${
            location.pathname.startsWith(url) ? 'text-primary-main' : ''
          }`}
        />
      </div>
      <Text
        type="caption"
        className={`text-jblood-gray group-hover:bg-gradient group-hover:bg-clip-text group-hover:text-primary-main group-hover:text-transparent ${
          location.pathname.startsWith(url)
            ? 'bg-gradient bg-clip-text text-primary-main text-transparent'
            : ''
        }`}
      >
        {label}
      </Text>
    </Link>
  );
};

export default SidebarLink;
