import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import ScrollToTop from './components/containers/ScrollToTop';
import AppContext from './contexts';
import ApplicationNavigation from './routes';
import NotificationWrapper from './components/containers/NotificationWrapper';
import { toastOptions } from './utils/config';

const client = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider {...{ client }}>
      <AppContext>
        <NotificationWrapper>
          <ScrollToTop />
          <ApplicationNavigation />
          <Toaster position="top-right" toastOptions={toastOptions} />
        </NotificationWrapper>
      </AppContext>
    </QueryClientProvider>
  );
};

export default App;
