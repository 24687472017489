import DonationRequestsCard from './DonationRequestsCard';
import { FC } from 'react';
import { DonationRequestsListsProps } from './DonationRequestsProps';
import Heading from '../../../../lib/Heading';
import Text from '../../../../lib/Text';
import ContainedLoader from '../../../../lib/Loader/ContainedLoader';

const DonationRequestsList: FC<DonationRequestsListsProps> = ({
  setSelectedIndex,
  isLoading,
  requests,
}) => {
  return (
    <>
      {isLoading ? (
        <ContainedLoader />
      ) : requests.length === 0 ? (
        <div className="flex min-h-[200px] w-full flex-col items-center justify-center gap-1 text-center">
          <Heading type="h3">Nothing to see here!</Heading>
          <Text>
            You do not have any blood donation requests at the moment.
          </Text>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          {requests.map((item, index) => (
            <DonationRequestsCard
              key={index}
              {...item.request}
              onClick={() => setSelectedIndex(index)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default DonationRequestsList;
