import { useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParams';
import { postRequest } from '../../utils/api/calls';
import { VERIFY_EMAIL } from '../../utils/api/urls';
import FormContainer from '../../components/containers/FormContainer';
import Heading from '../../components/lib/Heading';
import Text from '../../components/lib/Text';
import Button from '../../components/lib/Button';
import Backdrop from '../../components/lib/Backdrop';
import Loader from '../../components/lib/Loader';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import useErrorHandler from '../../hooks/useErrorHandler';

const VerifyEmail = () => {
  const token = useQueryParam().get('token');
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  const handler = useErrorHandler();

  const { mutate } = useMutation(postRequest, {
    onSuccess() {
      localStorage.removeItem('token');
      setInitialLoading(false);
      navigate('/login');
    },
    onError(error: any) {
      setInitialLoading(false);
      setErrorOccured(true);
      handler(error);
    },
  });

  useEffectOnce(() => {
    if (token) {
      mutate({
        url: VERIFY_EMAIL,
        data: {
          token,
        },
      });
    } else {
      setInitialLoading(false);
    }
  });

  return (
    <>
      <FormContainer showBackArrow={false}>
        {!token && (
          <div className="flex min-h-[80vh] flex-col items-center justify-center gap-5 p-5 text-center lg:min-h-[60vh]">
            <Heading type="h1">Oops, Bad Link!</Heading>
            <Text className="max-w-[35ch]">
              The link you have entered seems to be broken. Check your email
              again or go back home if this was a mistake.
            </Text>
            <Link to="/">
              <Button>Go Home</Button>
            </Link>
          </div>
        )}

        {errorOccured && (
          <div className="flex min-h-[80vh] flex-col items-center justify-center gap-5 p-5 text-center lg:min-h-[60vh]">
            <Heading type="h1">Error!</Heading>
            <Text className="max-w-[35ch]">
              An error occured, try again later.
            </Text>
            <Link to="/">
              <Button>Go Home</Button>
            </Link>
          </div>
        )}
      </FormContainer>

      <Backdrop open={initialLoading}>
        <Loader color="#fff" />
      </Backdrop>
    </>
  );
};

export default VerifyEmail;
