import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Backdrop from '../Backdrop';
import ActionSheetProps from './ActionsheetProps';

const ActionSheet: FC<PropsWithChildren<ActionSheetProps>> = ({
  open,
  onClose,
  children,
}) => {
  const [backdropIsOpen, setBackdropIsOpen] = useState(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    // Prevent scolling
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setBackdropIsOpen(false);
      }, 300);
    } else {
      setBackdropIsOpen(true);
    }
  }, [open]);

  return (
    <Backdrop
      {...{
        open: backdropIsOpen,
        onClose,
      }}
    >
      <div
        className={`fixed bottom-0 left-0 right-0 flex w-full flex-col items-center justify-center gap-10 rounded-t-3xl bg-white p-5 ${
          open
            ? 'translate-y-[100%] animate-slide-up'
            : 'translate-y-0 animate-slide-down'
        }`}
      >
        <div className="h-1.5 w-[25%] min-w-[50px] max-w-[250px] rounded-full bg-gray-300" />
        <div className="w-full">{children}</div>
      </div>
    </Backdrop>
  );
};

export default ActionSheet;
